import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import MapComponent from '../MapComponent';
import { useEventDetail } from './hooks/useEventDetail';
import eventTranslations from './translations';

// Import sub-components
import BuyTickets from './components/BuyTickets';
import EventInfo from './components/EventInfo';

const EventDetail: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = eventTranslations[language as keyof typeof eventTranslations];

  const { event, venue, loading, error } = useEventDetail(eventId as string, currentToken || '');

  // New state for tab navigation in the left column.
  const [activeTab, setActiveTab] = useState<'info' | 'buy'>('info');

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900">
        <StageSpinner size={50} color="#86ff0dc4" />
      </div>
    );
  }

  if (error || !event || !venue) {
    return <div className="text-white text-center mt-10">Event or Venue not found</div>;
  }

  const handleBackClick = () => navigate(-1);

  // Handler for sharing the event
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: event.title,
        text: event.description,
        url: window.location.href,
      });
    } else {
      navigator.clipboard.writeText(`${event.title}\n${event.description}\n${window.location.href}`);
      Swal.fire('Link copied to clipboard', 'Share it with your friends!', 'success');
    }
  };

  return (
    <div className="w-full bg-gradient-to-b from-gray-900 via-black to-gray-900 rounded-lg shadow-lg overflow-y-auto">
      <button onClick={handleBackClick} className="text-white mb-4 mt-24 flex items-center px-3">
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>

      <div className="flex flex-col lg:flex-row gap-8 px-3">
        {/* Left Column: Tabbed Content */}
        <div className="lg:w-1/2 flex flex-col">
          {/* Tab Navigation Header with reduced padding and smaller font */}
          <div className="flex justify-center mb-4 border-b border-gray-700">
            <button
              onClick={() => setActiveTab('info')}
              className={`px-4 py-2 text-lg font-semibold transition-all ${
                activeTab === 'info'
                  ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white'
              }`}
            >
              {t.info || 'Event Info'}
            </button>
            <button
              onClick={() => setActiveTab('buy')}
              className={`px-4 py-2 text-lg font-semibold transition-all ${
                activeTab === 'buy'
                  ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                  : 'bg-transparent text-gray-400 hover:text-white'
              }`}
            >
              {t.buyTickets || 'Buy Tickets'}
            </button>
          </div>

          {/* Render Tab Content */}
          {activeTab === 'info' ? (
            <EventInfo event={event} venue={venue} t={t} handleShare={handleShare} />
          ) : (
            <BuyTickets eventId={eventId || ''} passes={event.passes || []} t={t} />
          )}
        </div>

        {/* Right Column: Sticky Map (Desktop only) */}
        <div className="hidden lg:block lg:w-1/2 h-[calc(100vh-160px)] overflow-y-auto sticky top-20">
          <MapComponent venues={[venue]} onMarkerClick={() => {}} selectedClub={event.clubId?._id} relativeView />
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
