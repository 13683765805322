import { useEffect, useState } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

export const useEvents = (token: string) => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchEvents = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${APIBase}/client/event`, {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Fetch error');
      }
      const data = await response.json();
      // Sort events by updatedAt (most recent first)
      const sorted = data.sort((a: any, b: any) => {
        const timeA = a.updatedAt ? new Date(a.updatedAt).getTime() : 0;
        const timeB = b.updatedAt ? new Date(b.updatedAt).getTime() : 0;
        return timeB - timeA;
      });
      setEvents(sorted);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Please try again later 😞',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      fetchEvents();
    }
  }, [token]);

  return { events, loading, refetch: fetchEvents };
};
