// src/hooks/usePlaylistInteraction.ts
import { APIBase } from 'api/hosts';
import { useMutation, useQueryClient } from 'react-query';

interface SendLikePayload {
  playlistId: string;
  recipientId: string;
  token: string;
}

const sendLike = async ({ playlistId, recipientId, token }: SendLikePayload) => {
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}/interactions`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ recipient: recipientId }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to send like.');
  }
  const data = await response.json();
  return data;
};

export const useSendLike = (playlistId: string, token: string) => {
  const queryClient = useQueryClient();
  return useMutation((recipientId: string) => sendLike({ playlistId, recipientId, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['playlist', playlistId]);
    },
  });
};
