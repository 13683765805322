// src/components/ViewEvent/EventSidebar.tsx
import React from 'react';
import { FaFacebook, FaGoogle, FaMapMarkerAlt, FaShareAlt } from 'react-icons/fa';
import QRCode from 'react-qr-code';

interface EventSidebarProps {
  eventId: string;
  venue: any;
  activities: any[];
  t: any;
}

const EventSidebar: React.FC<EventSidebarProps> = ({ eventId, venue, activities, t }) => {
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Event',
        text: 'Check out this event!',
        url: window.location.href,
      });
    } else {
      navigator.clipboard.writeText(`Event link: ${window.location.href}`);
      alert('Link copied to clipboard');
    }
  };

  return (
    <div className="lg:w-1/2 mt-6 lg:mt-0">
      <h3 className="text-xl text-white mb-4">{t.qrCodeTitle}</h3>
      <div className="flex justify-center bg-white p-5 rounded-lg shadow-lg mb-6">
        <QRCode value={`event-${eventId}`} size={128} />
      </div>
      <div className="flex flex-wrap gap-4 my-6 justify-center">
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${venue.lat},${venue.lng}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 p-4 rounded-lg flex items-center justify-center transition-all"
        >
          <FaGoogle className="text-2xl" />
        </a>
        <a
          href={`http://maps.apple.com/?daddr=${venue.lat},${venue.lng}`}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 p-4 rounded-lg flex items-center justify-center transition-all"
        >
          <FaMapMarkerAlt className="text-2xl" />
        </a>
        <a
          href={venue.facebookLink}
          target="_blank"
          rel="noopener noreferrer"
          className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 p-4 rounded-lg flex items-center justify-center transition-all"
        >
          <FaFacebook className="text-2xl" />
        </a>
        <button
          onClick={handleShare}
          className="text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-800 hover:to-blue-1000 p-4 rounded-lg flex items-center justify-center transition-all"
        >
          <FaShareAlt className="text-2xl" />
        </button>
      </div>
      {activities && activities.length > 0 && (
        <div className="mt-6">
          <h3 className="text-xl text-white mb-3">{t.activities}</h3>
          <ul className="list-disc pl-5 text-gray-300">
            {activities.map((activity: any) => (
              <li key={activity._id} className="mb-1">
                {activity.name} ({activity.coins} {t.activityCoins.toLowerCase()})
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EventSidebar;
