// src/hooks/useUpdateCurrentVideoIndex.ts
import { APIBase } from 'api/hosts';
import { useMutation, useQueryClient } from 'react-query';

interface UpdateCurrentVideoIndexPayload {
  playlistId: string;
  token: string;
  newIndex: number;
}

const updateCurrentVideoIndex = async ({ playlistId, token, newIndex }: UpdateCurrentVideoIndexPayload) => {
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}/updateCurrentVideoIndex`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ currentVideoIndex: newIndex }),
  });
  if (!response.ok) {
    throw new Error('Failed to update video index');
  }
  return response.json();
};

export const useUpdateCurrentVideoIndex = (playlistId: string, token: string) => {
  const queryClient = useQueryClient();
  return useMutation((newIndex: number) => updateCurrentVideoIndex({ playlistId, token, newIndex }), {
    onSuccess: () => {
      // Invalidate the playlist query to refresh data
      queryClient.invalidateQueries(['playlist', playlistId]);
    },
  });
};
