import React, { useState } from 'react';
import { FaEdit, FaTrashAlt, FaPlus } from 'react-icons/fa';
import Select from 'react-select';
import { currencyOptions } from '../config';
import Swal from 'sweetalert2';

// Updated Pass interface with new sold property.
export interface Pass {
  name: string;
  description: string;
  price: number;
  currency: string;
  maxQuantity: number;
  deadline?: Date; // Deadline property
  sold?: {         // New: breakdown of sold tickets by status
    pending: number;
    paid: number;
    used: number;
  };
}

export interface PassesManagerProps {
  passes: Pass[];
  passInput: Pass;
  onPassInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPassCurrencyChange: (value: string) => void;
  onAddPass: (newPass: Pass) => void;
  // This function now receives the entire updated pass object.
  onEditPass: (index: number, updatedPass: Pass) => void;
  onRemovePass: (index: number) => void;
  t: any;
}

const PassesManager: React.FC<PassesManagerProps> = ({
  passes,
  passInput,
  onPassInputChange,
  onPassCurrencyChange,
  onAddPass,
  onEditPass,
  onRemovePass,
  t,
}) => {
  // Local state for the "Add New Pass" modal and edit modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingPassIndex, setEditingPassIndex] = useState<number | null>(null);
  const [editingPass, setEditingPass] = useState<Pass | null>(null);

  console.log('passes> ', passes);

  // Open modal for adding a new pass
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  // Open the modal for editing an existing pass
  const openEditModal = (index: number) => {
    setEditingPassIndex(index);
    setEditingPass(passes[index]);
    setIsEditModalOpen(true);
  };

  // Handler for changes inside the edit modal
  const handleModalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingPass) {
      const { name, value } = e.target;
      // For numeric fields, convert to Number; for deadline, convert to Date.
      let newValue: any = value;
      if (name === 'price' || name === 'maxQuantity') {
        newValue = Number(value);
      } else if (name === 'deadline') {
        newValue = new Date(value);
      }
      setEditingPass({
        ...editingPass,
        [name]: newValue,
      });
    }
  };

  // Handler for currency select changes inside the modal
  const handleModalCurrencyChange = (selectedOption: any) => {
    if (editingPass) {
      setEditingPass({ ...editingPass, currency: selectedOption.value });
    }
  };

  const handleSaveEdit = () => {
    if (editingPassIndex !== null && editingPass) {
      // Pass the entire updated pass object.
      onEditPass(editingPassIndex, editingPass);
      setIsEditModalOpen(false);
      setEditingPassIndex(null);
      setEditingPass(null);
    }
  };

  // Handler for adding a new pass from the modal
  const handleSaveNewPass = () => {
    if (!passInput.name || !passInput.price || !passInput.currency || !passInput.maxQuantity) {
      Swal.fire({ title: t.error, text: t.passFillError || 'Please fill all pass fields.', icon: 'error' });
      return;
    }
    onAddPass({ ...passInput });
    setIsAddModalOpen(false);
  };

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between">
        <h3 className="text-xl text-white">{t.passes}</h3>
        {/* Show a plus button to open the Add Pass modal */}
        <button
          onClick={openAddModal}
          className="w-10 h-10 rounded-full flex items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 shadow-lg hover:from-green-600 hover:to-blue-600 transition-all duration-300"
          title={t.addPass}
        >
          <FaPlus className="text-white text-xl" />
        </button>
      </div>
      {/* If there are no passes, show a full-width button */}
      {passes.length === 0 && (
        <div className="mt-4">
          <button
            onClick={openAddModal}
            className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-green-500 to-blue-500 shadow-lg hover:from-green-600 hover:to-blue-600 transition-all duration-300 text-white"
          >
            {t.addFirstPass || 'Add your first tickets'}
          </button>
        </div>
      )}
      <div className="space-y-2 mt-4">
        {passes.map((pass, index) => (
          <div
            key={index}
            className="flex justify-between items-center bg-gray-800 p-3 rounded-lg shadow-md"
          >
            <div className="flex flex-col">
              <span className="font-bold text-white">{pass.name}</span>
              <span className="text-sm text-gray-300">
                {pass.price} {pass.currency.toUpperCase()}
              </span>
              <span className="text-xs text-gray-400">Max: {pass.maxQuantity}</span>
              <span className="text-xs text-gray-400">{pass.description}</span>
              {pass.deadline && (
                <span className="text-xs text-gray-400">
                  Deadline: {new Date(pass.deadline).toLocaleDateString()}
                </span>
              )}
              {/* Display sold breakdown */}
              <span className="text-xs text-gray-400">
                Sold: Pending: {pass.sold?.pending || 0}, Paid: {pass.sold?.paid || 0}, Used: {pass.sold?.used || 0}
              </span>
            </div>
            <div className="flex space-x-4">
              {/* Edit Button */}
              <button
                className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  openEditModal(index);
                }}
                title={t.editPass}
              >
                <FaEdit className="text-white text-xl" />
              </button>
              {/* Remove Button */}
              <button
                className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-r from-red-500 to-pink-500 shadow-lg hover:from-red-600 hover:to-pink-600 transition-all duration-300"
                onClick={(e) => {
                  e.preventDefault();
                  onRemovePass(index);
                }}
                title={t.deletePass}
              >
                <FaTrashAlt className="text-white text-xl" />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal for Adding a New Pass */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl mb-4 text-white">{t.addPass}</h3>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">{t.passName}</label>
              <input
                type="text"
                name="name"
                placeholder={t.passName}
                value={passInput.name}
                onChange={onPassInputChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">{t.passDescription}</label>
              <input
                type="text"
                name="description"
                placeholder={t.passDescription}
                value={passInput.description}
                onChange={onPassInputChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="mb-4 flex space-x-4">
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passPrice}</label>
                <input
                  type="number"
                  name="price"
                  placeholder={t.passPrice}
                  value={passInput.price}
                  onChange={onPassInputChange}
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passCurrency}</label>
                <Select
                  options={currencyOptions}
                  placeholder={t.passCurrency.toUpperCase()}
                  onChange={(selectedOption) =>
                    onPassCurrencyChange((selectedOption as any).value)
                  }
                  value={
                    currencyOptions.find(
                      (option: any) => option.value === passInput.currency
                    ) || null
                  }
                  className="text-black"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: '#c084fc',
                      primary: '#8b5cf6',
                    },
                  })}
                />
              </div>
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passMaxQuantity}</label>
                <input
                  type="number"
                  name="maxQuantity"
                  placeholder={t.passMaxQuantity}
                  value={passInput.maxQuantity}
                  onChange={onPassInputChange}
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">Deadline</label>
              <input
                type="date"
                name="deadline"
                value={
                  passInput.deadline
                    ? new Date(passInput.deadline).toISOString().substring(0, 10)
                    : ''
                }
                onChange={onPassInputChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsAddModalOpen(false);
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:from-red-600 hover:to-red-800 transition-all duration-300 text-white"
              >
                {t.cancel}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveNewPass();
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-green-500 to-blue-500 shadow-md hover:from-green-600 hover:to-blue-600 transition-all duration-300 text-white"
              >
                {t.savePass}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for Editing a Pass */}
      {isEditModalOpen && editingPass !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl mb-4 text-white">{t.editPass}</h3>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">{t.passName}</label>
              <input
                type="text"
                name="name"
                value={editingPass.name}
                onChange={handleModalChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">{t.passDescription}</label>
              <input
                type="text"
                name="description"
                value={editingPass.description}
                onChange={handleModalChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="mb-4 flex space-x-4">
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passPrice}</label>
                <input
                  type="number"
                  name="price"
                  value={editingPass.price}
                  onChange={handleModalChange}
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passCurrency}</label>
                <Select
                  options={currencyOptions}
                  placeholder={t.passCurrency.toUpperCase()}
                  onChange={handleModalCurrencyChange}
                  value={
                    currencyOptions.find(
                      (option: any) => option.value === editingPass.currency
                    ) || null
                  }
                  className="text-black"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                      ...theme.colors,
                      primary25: '#c084fc',
                      primary: '#8b5cf6',
                    },
                  })}
                />
              </div>
              <div className="w-1/3">
                <label className="block text-sm mb-2 text-gray-200">{t.passMaxQuantity}</label>
                <input
                  type="number"
                  name="maxQuantity"
                  value={editingPass.maxQuantity}
                  onChange={handleModalChange}
                  className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2 text-gray-200">Deadline</label>
              <input
                type="date"
                name="deadline"
                value={editingPass.deadline ? new Date(editingPass.deadline).toISOString().substring(0, 10) : ''}
                onChange={handleModalChange}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditModalOpen(false);
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-red-500 to-red-700 shadow-md hover:from-red-600 hover:to-red-800 transition-all duration-300 text-white"
              >
                {t.cancel}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveEdit();
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-green-500 to-blue-500 shadow-md hover:from-green-600 hover:to-blue-600 transition-all duration-300 text-white"
              >
                {t.savePass}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PassesManager;
