// src/components/EventEditor/EventCard.tsx
import React from 'react';
import { FaEllipsisV } from 'react-icons/fa';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

interface EventCardProps {
  event: any;
  dropdownOpen: string | null;
  setDropdownOpen: (id: string | null) => void;
  dropdownRef: React.RefObject<HTMLDivElement>;
  t: any;
}

const EventCard: React.FC<EventCardProps> = ({
  event,
  dropdownOpen,
  setDropdownOpen,
  dropdownRef,
  t,
}) => {
  const navigate = useNavigate();

  const handleMenuItemClick = (callback: () => void) => {
    callback();
    setDropdownOpen(null);
  };

  const confirmDelete = (eventId: string) => {
    // Implement confirmation logic (e.g., with Swal.fire)
  };

  return (
    <div
      className="relative bg-gradient-to-br from-gray-900 to-gray-700 p-6 rounded-xl shadow-2xl border border-gray-600 hover:border-transparent transition-all duration-300 cursor-pointer"
      onClick={() => navigate(`/app/admin/view-event/${event._id}`)}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          setDropdownOpen(dropdownOpen === event._id ? null : event._id);
        }}
        className="absolute top-3 right-3 p-3 rounded-full bg-black bg-opacity-60 hover:bg-opacity-80 transition-colors duration-300 text-white border border-gray-500 shadow-md"
      >
        <FaEllipsisV className="text-xl" />
      </button>
      {dropdownOpen === event._id && (
        <div
          className="absolute right-0 mt-2 w-48 bg-black bg-opacity-90 text-white rounded-lg shadow-xl z-20"
          ref={dropdownRef}
        >
          <button
            onClick={(e) => {e.stopPropagation(); navigate(`/app/admin/edit-event/${event._id}`)}}
            className="block px-4 py-2 w-full text-left hover:bg-gray-800 transition-colors duration-200"
          >
            {t.edit}
          </button>
          <button
            onClick={(e) => {e.stopPropagation(); navigate(`/app/admin/view-event/${event._id}`)}}
            className="block px-4 py-2 w-full text-left hover:bg-gray-800 transition-colors duration-200"
          >
            {t.view}
          </button>
          <button
            onClick={(e) => {e.stopPropagation(); confirmDelete(event._id)}}
            className="block px-4 py-2 w-full text-left hover:bg-gray-800 transition-colors duration-200"
          >
            {t.delete}
          </button>
        </div>
      )}
      <div className="text-xl font-bold text-white mb-3 tracking-wide pr-10">{event.title}</div>
      {event.imageURL && (
        <img
          src={event.imageURL}
          alt={event.title}
          className="w-full h-40 object-cover rounded-lg mb-3 shadow-md"
        />
      )}
      <p className="text-sm text-gray-300 mb-1">
        {moment(event.date).format('MMMM Do, YYYY')}
        {event.startHour && event.endHour
          ? ` | ${moment(event.startHour, 'HH:mm').format('h:mm A')} - ${moment(event.endHour, 'HH:mm').format('h:mm A')}`
          : ''}
      </p>
      <p className="text-sm text-gray-400">
        {event.description.length > 100 ? `${event.description.substring(0, 100)}...` : event.description}
      </p>
    </div>
  );
};

export default EventCard;
