// src/hooks/useUpdateVideoMetadata.ts
import { useMutation, useQueryClient } from 'react-query';
import { APIBase } from 'api/hosts';

interface UpdateVideoMetadataPayload {
  playlistId: string;
  videoId: string;
  token: string;
  title?: string;
  duration?: number;
  videoUrl?: string; // new URL to update (if needed)
}

const updateVideoMetadata = async (payload: UpdateVideoMetadataPayload) => {
  const { playlistId, videoId, token, title, duration, videoUrl } = payload;
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}/updateVideoMetadata/${videoId}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ title, duration, videoUrl }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Failed to update video metadata.');
  }
  return response.json();
};

export const useUpdateVideoMetadata = (playlistId: string, token: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (payload: { videoId: string; title?: string; duration?: number; videoUrl?: string }) =>
      updateVideoMetadata({ playlistId, token, ...payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['playlist', playlistId]);
      },
    }
  );
};
