import { APIBase } from 'api/hosts';
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';

type UserData = {
  _id: string;
  totalCoins: number;
  // Add other user fields as necessary
};

type UserContextType = {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  fetchUserData: () => Promise<void>;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

type UserProviderProps = {
  children: ReactNode;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const { currentToken } = useAuth(); // Access currentToken from AuthContext
  const [userData, setUserData] = useState<UserData | null>(null);

  const fetchUserData = async () => {
    if (!currentToken) return; // Do nothing if there's no token

    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/user`, data);
      if (response.ok) {
        const responseJson = await response.json();
        setUserData(responseJson);
      } else {
        throw new Error('Failed to fetch user data');
      }
    } catch (error) {
      console.error(error);
      // You can also set an error state to show in the UI if needed
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [currentToken]);

  return <UserContext.Provider value={{ userData, setUserData, fetchUserData }}>{children}</UserContext.Provider>;
};
