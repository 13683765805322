import {
  faMusic,
  faBuilding,
  faCity,
  faGlobe,
  faUtensils,
  faQrcode, // Import QR code icon
  faHome, // Import Home icon
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from 'Contexts/LanguageContext';

const translations = {
  english: {
    events: 'Events',
    venues: 'Venues',
    cities: 'Cities',
    countries: 'Countries',
    menuItems: 'Menu Items',
    scanDiscounts: 'Scan',
    home: 'Home',
  },
  spanish: {
    events: 'Eventos',
    venues: 'Lugares',
    cities: 'Ciudades',
    countries: 'Países',
    menuItems: 'Elementos del Menú',
    scanDiscounts: 'Escanear',
    home: 'Inicio',
  },
  polish: {
    events: 'Wydarzenia',
    venues: 'Miejsca',
    cities: 'Miasta',
    countries: 'Kraje',
    menuItems: 'Pozycje Menu',
    scanDiscounts: 'Skanuj',
    home: 'Strona główna',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const PartyTicketLowerBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();

  const t = translations[language as Language];

  const getButtonClass = (path: string) => {
    return location.pathname === path
      ? 'text-neon-blue border-b-2 border-neon-blue'
      : 'text-white';
  };

  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-black via-gray-900 to-black text-white flex justify-around items-center px-4 py-3 shadow-lg z-50 h-20"
      style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)' }}
    >
      {/* Events Button */}
      <button
        onClick={() => navigate('/app/admin/events')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/events')}`}
      >
        <FontAwesomeIcon icon={faMusic} className="text-2xl" />
        <span className="text-xs mt-1">{t.events}</span>
      </button>

      {/* Venues Button */}
      <button
        onClick={() => navigate('/app/admin/venues')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/venues')}`}
      >
        <FontAwesomeIcon icon={faBuilding} className="text-2xl" />
        <span className="text-xs mt-1">{t.venues}</span>
      </button>

      {/* Scan Discounts Button */}
      <button
        onClick={() => navigate('/app/admin/scan-qrs')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/scan-discount')}`}
      >
        <FontAwesomeIcon icon={faQrcode} className="text-2xl" />
        <span className="text-xs mt-1">{t.scanDiscounts}</span>
      </button>


      {/* Center Home Button */}
      <button
        onClick={() => navigate('/app/admin/home')}
        className="flex flex-col items-center justify-center w-16 h-16 bg-gradient-to-r p-3 rounded-full shadow-lg border border-neon-blue transition-all duration-300 ease-in-out"
      >
        <FontAwesomeIcon icon={faHome} className="text-3xl text-neon-blue" />
      </button>


      {/* Menu Items Button */}
      <button
        onClick={() => navigate('/app/admin/menu-items')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/menu-items')}`}
      >
        <FontAwesomeIcon icon={faUtensils} className="text-2xl" />
        <span className="text-xs mt-1">{t.menuItems}</span>
      </button>

      {/* Cities Button */}
      <button
        onClick={() => navigate('/app/admin/cities')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/cities')}`}
      >
        <FontAwesomeIcon icon={faCity} className="text-2xl" />
        <span className="text-xs mt-1">{t.cities}</span>
      </button>

      {/* Countries Button */}
      <button
        onClick={() => navigate('/app/admin/countries')}
        className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass('/app/admin/countries')}`}
      >
        <FontAwesomeIcon icon={faGlobe} className="text-2xl" />
        <span className="text-xs mt-1">{t.countries}</span>
      </button>
    </div>
  );
};

export default PartyTicketLowerBar;
