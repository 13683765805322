// src/components/EventEditor/GenreAndDJSection.tsx
import React, { KeyboardEvent } from 'react';
import { FaTimes } from 'react-icons/fa';
import { genreOptions } from '../config';

interface GenreAndDJProps {
  eventData: any;
  genreInput: string;
  djInput: string;
  setGenreInput: (value: string) => void;
  setDjInput: (value: string) => void;
  handleGenreAdd: () => void;
  handleGenreRemove: (genre: string) => void;
  handleDjAdd: () => void;
  handleDjRemove: (dj: string) => void;
  t: any;
}

const GenreAndDJSection: React.FC<GenreAndDJProps> = ({
  eventData,
  genreInput,
  djInput,
  setGenreInput,
  setDjInput,
  handleGenreAdd,
  handleGenreRemove,
  handleDjAdd,
  handleDjRemove,
  t,
}) => {
  const onGenreKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleGenreAdd();
    }
  };

  const onDjKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleDjAdd();
    }
  };

  return (
    <>
      {/* Genres Section */}
      <div className="mb-6">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="genres">
          {t.genres}
        </label>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            id="genres"
            name="genres"
            value={genreInput}
            onChange={(e) => setGenreInput(e.target.value)}
            onKeyDown={onGenreKeyDown}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
            list="genreOptions"
          />
          <datalist id="genreOptions">
            {genreOptions.map((genre) => (
              <option key={genre} value={genre} />
            ))}
          </datalist>
          <button
            type="button"
            onClick={handleGenreAdd}
            className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-500 to-teal-500 text-white shadow-md hover:from-blue-600 hover:to-teal-600 transition-all duration-300"
          >
            {t.add}
          </button>
        </div>
        <div className="flex flex-wrap mt-4">
          {eventData?.genres.map((genre: string) => (
            <div
              key={genre}
              className="flex items-center bg-gradient-to-r from-gray-700 to-gray-800 text-white rounded-full px-4 py-1 m-1 shadow-sm"
            >
              <span>{genre}</span>
              <FaTimes
                className="ml-2 cursor-pointer hover:text-red-400 transition-colors duration-300"
                onClick={() => handleGenreRemove(genre)}
              />
            </div>
          ))}
        </div>
      </div>

      {/* DJs Section */}
      <div className="mb-6">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="djs">
          {t.djs}
        </label>
        <div className="flex items-center space-x-2">
          <input
            type="text"
            id="djs"
            name="djs"
            value={djInput}
            onChange={(e) => setDjInput(e.target.value)}
            onKeyDown={onDjKeyDown}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
          />
          <button
            type="button"
            onClick={handleDjAdd}
            className="px-4 py-2 rounded-full bg-gradient-to-r from-blue-500 to-teal-500 text-white shadow-md hover:from-blue-600 hover:to-teal-600 transition-all duration-300"
          >
            {t.add}
          </button>
        </div>
        <div className="flex flex-wrap mt-4">
          {eventData?.djs.map((dj: string) => (
            <div
              key={dj}
              className="flex items-center bg-gradient-to-r from-gray-700 to-gray-800 text-white rounded-full px-4 py-1 m-1 shadow-sm"
            >
              <span>{dj}</span>
              <FaTimes
                className="ml-2 cursor-pointer hover:text-red-400 transition-colors duration-300"
                onClick={() => handleDjRemove(dj)}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default GenreAndDJSection;
