// hooks/useFetchPlaylistDetails.ts
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const useFetchPlaylistDetails = (currentToken: string, playlistId: string) => {
  const [playlistDetails, setPlaylistDetails] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (playlistId) {
      const fetchPlaylistDetails = async () => {
        try {
          const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${currentToken}` },
          });

          if (response.ok) {
            const data = await response.json();
            setPlaylistDetails(data.playlist);
          } else {
            throw new Error('Failed to fetch playlist details');
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Could not fetch playlist details',
            icon: 'error',
          });
        } finally {
          setLoading(false);
        }
      };

      fetchPlaylistDetails();
    }
  }, [currentToken, playlistId]);

  return { playlistDetails, loading };
};

export default useFetchPlaylistDetails;
