// src/components/EventList.tsx
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useRef, useState } from 'react';
import { FaFilter, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { StageSpinner } from 'react-spinners-kit';

import LowerBar from '../LowerBar';
import EventCard from './components/EventCard';
import FilterSection from './components/FilterSection';
import SearchBar from './components/SearchBar';
import { useEvents } from './hooks/useEvents';
import translations from './translations';

const EventList: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];

  // Fetch events using our custom hook
  const { events, loading } = useEvents(currentToken || '');
  const [searchQuery, setSearchQuery] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Advanced filter state
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  // Helper: Reset a date's time to midnight.
  const resetTimeToMidnight = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };

  // Filter events based on date range and search query.
  // Changed sort order to ascending (oldest first)
  const filteredEvents = events
    .filter((event: any) => {
      const eventDate = new Date(event.date);
      const isWithinDateRange =
        eventDate >= resetTimeToMidnight(startDate) && eventDate <= new Date(endDate.setHours(23, 59, 59, 999));
      const matchesSearch =
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.description.toLowerCase().includes(searchQuery.toLowerCase());
      // Additional filter conditions (e.g. selected genres, venue types) can be added here.
      return isWithinDateRange && matchesSearch;
    })
    .sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Handle click outside dropdown (for event cards, if needed)
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Clear all filters
  const clearFilters = () => {
    setSearchQuery('');

    // Advanced filter state
    setStartDate(new Date());
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 7)));
  };

  return (
    <div className="w-screen h-screen flex flex-col bg-black">
      {/* Fixed Top Search and Filter Bar */}
      <div className="fixed top-0 w-full bg-black bg-opacity-70 backdrop-blur-md z-10 p-3 pt-24 flex items-center gap-3">
        <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} placeholder={t.searchPlaceholder} />
        <button
          onClick={() => setIsFilterModalOpen(true)}
          className="p-3 rounded-full bg-gradient-to-r from-blue-500 to-teal-500 text-white shadow-lg hover:from-blue-600 hover:to-teal-600 transition-colors duration-300"
          title="Open Filters"
        >
          <FaFilter className="text-xl" />
        </button>
        <button
          onClick={clearFilters}
          className="p-3 rounded-full bg-gradient-to-r from-red-500 to-pink-500 text-white shadow-lg hover:from-red-600 hover:to-pink-600 transition-colors duration-300"
          title="Clear Filters"
        >
          <FaTimes className="text-xl" />
        </button>
      </div>

      <Modal
        isOpen={isFilterModalOpen}
        onRequestClose={() => setIsFilterModalOpen(false)}
        contentLabel="Select Date Range"
        className="fixed inset-0 flex items-center justify-center z-50 p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <FilterSection
          startDate={startDate}
          endDate={endDate}
          clearFilters={clearFilters}
          closeFilters={() => setIsFilterModalOpen(false)}
          onDateRangeChange={(newStart, newEnd) => {
            setStartDate(newStart);
            setEndDate(newEnd);
          }}
        />
      </Modal>

      {/* Main Content */}
      <div className="py-40 px-4 flex-1 overflow-y-auto">
        {loading ? (
          <div className="flex items-center justify-center h-64">
            <StageSpinner size={50} color="#ffffff" />
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
            {filteredEvents.map((event: any) => (
              <EventCard
                key={event._id}
                event={event}
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                dropdownRef={dropdownRef}
                t={t}
              />
            ))}
          </div>
        )}
      </div>

      <LowerBar />
    </div>
  );
};

export default EventList;
