import { useState } from 'react';
import ScanActivity from './components/ScanActivity';
import ScanDiscount from './components/ScanDiscount';
import ScanTicket from './components/ScanTicket';

const ScanPage = () => {
  const [activeTab, setActiveTab] = useState('discount');

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center p-6 pt-28">
      <h1 className="text-3xl font-extrabold tracking-wide text-center mb-8">
        Scan QR Codes
      </h1>

      {/* Tabs */}
      <div className="flex space-x-4 mb-8 bg-gray-800 bg-opacity-40 rounded-full px-4 py-2 shadow-lg">
        {[
          { id: 'discount', label: 'Discount', gradient: 'from-green-500 to-teal-500' },
          { id: 'activity', label: 'Activity', gradient: 'from-blue-500 to-indigo-500' },
          { id: 'ticket', label: 'Ticket', gradient: 'from-yellow-500 to-orange-500' }
        ].map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`px-6 py-3 rounded-full text-lg font-semibold tracking-wide transition-all duration-300 
              ${activeTab === tab.id 
                ? `bg-gradient-to-r ${tab.gradient} text-white shadow-xl transform scale-105` 
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white'}`}
          >
            {tab.label}
          </button>
        ))}
      </div>

      {/* Render Components Based on Selected Tab */}
      <div className="w-full max-w-3xl">
        {activeTab === 'discount' && <ScanDiscount />}
        {activeTab === 'activity' && <ScanActivity />}
        {activeTab === 'ticket' && <ScanTicket />}
      </div>
    </div>
  );
};

export default ScanPage;
