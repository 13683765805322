// src/hooks/useEventEditorActions.ts
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

export const useEventEditorActions = (
  token: string,
  eventId: string,
  refetchActivities: (id: string) => Promise<void>,
) => {
  // Update (edit) an existing activity
  const updateActivity = async (activity: any): Promise<void> => {
    const data: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(activity),
    };
    try {
      const response = await fetch(`${APIBase}/client/activity/${activity._id}`, data);
      if (!response.ok) {
        throw new Error('Failed to update activity');
      }
      await refetchActivities(eventId);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to update activity. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  // Delete an activity
  const deleteActivity = async (activityId: string): Promise<void> => {
    const data: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/activity/${activityId}`, data);
      if (!response.ok) {
        throw new Error('Failed to delete activity');
      }
      await refetchActivities(eventId);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to delete activity. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  // Create a new activity
  const createActivity = async (activity: any): Promise<void> => {
    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...activity, eventId }),
    };
    try {
      const response = await fetch(`${APIBase}/client/activity`, data);
      if (!response.ok) {
        throw new Error('Failed to create activity');
      }
      await refetchActivities(eventId);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to create activity. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  // Update the event itself (e.g. with updated fields and imageURL)
  const updateEvent = async (eventData: any, uploadedImageUrl: string): Promise<boolean> => {
    const data: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ _id: eventId, ...eventData, imageURL: uploadedImageUrl }),
    };
    try {
      const response = await fetch(`${APIBase}/client/event`, data);
      if (response.ok) {
        return true;
      } else {
        throw new Error('Failed to update event');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to update event. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
      return false;
    }
  };

  return { updateActivity, deleteActivity, createActivity, updateEvent };
};
