export const translations = {
    english: {
      videoPlaceholder: 'Enter YouTube Video URL',
      selectRoom: 'Select Room',
      selectPlaylist: 'Select Playlist',
      loading: 'Loading...',
      noRooms: 'No rooms available.',
      noPlaylists: 'No playlists available.',
      leaderboard: 'Leaderboard',
      noParticipants: 'No participants yet.',
      settings: 'Settings',
      addVideo: 'Add Video',
      save: 'Save',
      close: 'Close',
      contestTitle: 'Karaoke Contest!',
      roomMessage: 'Contest is inside the ',
      selectBattle: 'Select Battle',
      noBattles: 'No battles available.',
      battleData: 'Battle Data',
      videosTab: 'Videos Settings',
      karaokeTab: 'Karaoke Leaderboard',
      djBattleTab: 'DJ Battle',
      selectVenue: 'Select Venue',
      noVenues: 'No venues available.',
      venueQR: 'Venue QR Code',
      venuePromotions: 'Venue Promotions',
      noPromotions: 'No promotions available.',
      totalCoinsTab: 'Total Coins Leaderboard',
      totalLeaderboard: 'Total Leaderboard',
      promotions: 'Promotions',
      noMessages: 'No messages available.',
      noMessagesForTab: 'No messages found for this tab.',
      now: 'Happening Now',  // Added key for now
      approved: 'Approved', // Added key for approved
      notApproved: 'Not Approved', // Added key for notApproved
    },
    polish: {
      videoPlaceholder: 'Wprowadź URL filmu z YouTube',
      selectRoom: 'Wybierz pokój',
      selectPlaylist: 'Wybierz listę odtwarzania',
      loading: 'Ładowanie...',
      noRooms: 'Brak dostępnych pokoi.',
      noPlaylists: 'Brak dostępnych playlist.',
      leaderboard: 'Tabela wyników',
      noParticipants: 'Brak uczestników.',
      settings: 'Ustawienia',
      addVideo: 'Dodaj film',
      save: 'Zapisz',
      close: 'Zamknij',
      contestTitle: 'Konkurs Karaoke!',
      roomMessage: 'Konkurs odbywa się w ',
      selectBattle: 'Wybierz bitwę',
      noBattles: 'Brak dostępnych bitw.',
      battleData: 'Dane bitwy',
      videosTab: 'Ustawienia filmów',
      karaokeTab: 'Tabela wyników Karaoke',
      djBattleTab: 'Bitwa DJ-ów',
      selectVenue: 'Wybierz lokal',
      noVenues: 'Brak dostępnych lokali.',
      venueQR: 'Kod QR lokalu',
      venuePromotions: 'Promocje w lokalu',
      noPromotions: 'Brak dostępnych promocji.',
      totalCoinsTab: 'Tabela punktów ogólnych',
      totalLeaderboard: 'Ogólna tabela wyników',
      promotions: 'Promocje',
      noMessages: 'Brak wiadomości.',
      noMessagesForTab: 'Brak wiadomości w tej zakładce.',
      now: 'W trakcie', // Added key for now
      approved: 'Zatwierdzono', // Added key for approved
      notApproved: 'Nie Zatwierdzono', // Added key for notApproved
    },
    spanish: {
      videoPlaceholder: 'Ingresa la URL del video de YouTube',
      selectRoom: 'Selecciona la sala',
      selectPlaylist: 'Selecciona la lista de reproducción',
      loading: 'Cargando...',
      noRooms: 'No hay salas disponibles.',
      noPlaylists: 'No hay listas de reproducción disponibles.',
      leaderboard: 'Tabla de clasificación',
      noParticipants: 'No hay participantes aún.',
      settings: 'Configuraciones',
      addVideo: 'Agregar video',
      save: 'Guardar',
      close: 'Cerrar',
      contestTitle: '¡Concurso de Karaoke!',
      roomMessage: 'El concurso está dentro de la ',
      selectBattle: 'Selecciona la batalla',
      noBattles: 'No hay batallas disponibles.',
      battleData: 'Datos de la batalla',
      videosTab: 'Configuraciones de videos',
      karaokeTab: 'Tabla de clasificación de Karaoke',
      djBattleTab: 'Batalla de DJs',
      selectVenue: 'Selecciona el local',
      noVenues: 'No hay locales disponibles.',
      venueQR: 'Código QR del local',
      venuePromotions: 'Promociones del local',
      noPromotions: 'No hay promociones disponibles.',
      totalCoinsTab: 'Tabla de puntos totales',
      totalLeaderboard: 'Tabla general de clasificación',
      promotions: 'Promociones',
      noMessages: 'No hay mensajes disponibles.',
      noMessagesForTab: 'No se encontraron mensajes en esta pestaña.',
      now: 'En Curso', // Added key for now
      approved: 'Aprobada', // Added key for approved
      notApproved: 'No Aprobada', // Added key for notApproved
    },
  };
  