import { useAuth } from 'Contexts/AuthContext';
import { APIBase } from 'api/hosts';
import { useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

interface ScannedDiscountData {
  venueId: string;
  menuItemId: string;
  discountId: string;
  coinsRequired: number;
  priceWithDiscount: number;
  userId: string;
}

const ScanDiscount = () => {
  const [scannedData, setScannedData] = useState<ScannedDiscountData | null>(null);
  const [loading, setLoading] = useState(false);
  const { currentToken } = useAuth();

  const processScan = async () => {
    if (!scannedData) {
      Swal.fire('Error', 'No valid QR code detected.', 'error');
      return;
    }

    setLoading(true);
    try {
      const { venueId, menuItemId, discountId, coinsRequired, priceWithDiscount, userId } = scannedData;
      const response = await fetch(`${APIBase}/client/discount/redeem`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ venueId, menuItemId, discountId, coinsRequired, priceWithDiscount, userId }),
      });

      const result = await response.json();
      Swal.fire({
        title: response.ok ? '✅ Success!' : '❌ Error',
        text: response.ok ? `Discount applied! User got discounted ${coinsRequired} coins.` : result.message,
        icon: response.ok ? 'success' : 'error',
      });

      if (response.ok) {
        setScannedData(null); // Reset after successful redemption
      }
    } catch (error) {
      Swal.fire('Error', 'Failed to scan QR code. Try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gradient-to-b from-black via-gray-900 to-black px-6 pt-4">
      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <StageSpinner size={60} color="#86ff0d" />
          <p className="text-white mt-4 text-lg font-semibold">Processing...</p>
        </div>
      ) : !scannedData ? (
        <>
          <h2 className="text-3xl text-white mb-6 font-extrabold text-center tracking-wide">Scan Discount QR Code</h2>
          <div className="relative w-72 h-72 rounded-xl shadow-2xl overflow-hidden border-2 border-teal-500 backdrop-blur-xl bg-opacity-10">
            <QrReader
              constraints={{ facingMode: 'environment' }}
              onResult={(result) => {
                if (result) {
                  try {
                    const data: ScannedDiscountData = JSON.parse(result.getText());
                    setScannedData(data);
                  } catch (error) {
                    Swal.fire('Error', 'Invalid QR code format.', 'error');
                  }
                }
              }}
              containerStyle={{ width: '100%', height: '100%' }}
              videoContainerStyle={{ borderRadius: '12px' }}
            />
          </div>
        </>
      ) : (
        <div className="text-white bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-xl shadow-2xl w-full max-w-md backdrop-blur-xl bg-opacity-50">
          <h3 className="text-2xl font-extrabold mb-6 text-center tracking-wide">🎟 Discount Details</h3>

          <div className="bg-gradient-to-r from-gray-700 to-gray-900 p-5 rounded-xl shadow-md">
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">🏢 Venue ID:</strong> {scannedData.venueId}
            </p>
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">🍽️ Menu Item:</strong> {scannedData.menuItemId}
            </p>
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">🎟 Discount ID:</strong> {scannedData.discountId}
            </p>
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">🪙 Coins Required:</strong> {scannedData.coinsRequired}
            </p>
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">💰 Price with Discount:</strong> {scannedData.priceWithDiscount}
            </p>
            <p className="text-lg text-gray-300">
              <strong className="text-teal-400">👤 User ID:</strong> {scannedData.userId}
            </p>
          </div>

          <div className="mt-6 flex justify-between space-x-4">
            <button
              className="w-1/2 py-3 text-lg font-semibold text-white rounded-lg shadow-lg bg-gradient-to-r from-green-500 to-teal-500 hover:from-green-600 hover:to-teal-600 transition-all"
              onClick={processScan}
            >
              Redeem Discount
            </button>
            <button
              className="w-1/2 py-3 text-lg font-semibold text-white rounded-lg shadow-lg bg-gradient-to-r from-red-500 to-orange-500 hover:from-red-600 hover:to-orange-600 transition-all"
              onClick={() => setScannedData(null)}
            >
              Re-Scan
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ScanDiscount;
