// src/components/EventEditor/ActivitiesManager.tsx
import React, { useState, KeyboardEvent } from 'react';
import { FaEdit, FaTrashAlt, FaPlus } from 'react-icons/fa';

interface ActivitiesManagerProps {
  activities: any[];
  newActivity: { name: string; coins: number };
  setNewActivity: (activity: { name: string; coins: number }) => void;
  handleActivitySubmit: (e: React.FormEvent) => Promise<void>;
  handleActivityDelete: (activityId: string) => Promise<void>;
  openEditModal: (activity: any) => void;
  t: any;
}

const ActivitiesManager: React.FC<ActivitiesManagerProps> = ({
  activities,
  newActivity,
  setNewActivity,
  handleActivitySubmit,
  handleActivityDelete,
  openEditModal,
  t,
}) => {
  // Local state to control the Add Activity modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Handlers for the new activity modal
  const openAddModal = () => {
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
    // Optionally reset the new activity state here if needed:
    setNewActivity({ name: '', coins: 0 });
  };

  const onNewActivityKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      // If Enter is pressed, submit the new activity
      handleActivitySubmit(e);
      closeAddModal();
    }
  };

  return (
    <>
      <div className="mb-6">
        <div className="flex items-center justify-between mb-3">
          <h3 className="text-xl text-white">{t.activities}</h3>
          <button
            onClick={openAddModal}
            className="w-10 h-10 rounded-full flex items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 shadow-lg hover:from-green-600 hover:to-blue-600 transition-all duration-300"
            title={t.addActivity}
          >
            <FaPlus className="text-white text-xl" />
          </button>
        </div>
        {activities.length === 0 ? (
          <div className="mt-4">
            <button
              onClick={openAddModal}
              className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-green-500 to-blue-500 shadow-lg hover:from-green-600 hover:to-blue-600 transition-all duration-300 text-white"
            >
              {t.addFirstActivity || 'Add your first activity'}
            </button>
          </div>
        ) : (
          <div className="space-y-3">
            {activities.map((activity: any) => (
              <div
                key={activity._id}
                className="flex justify-between items-center bg-gray-800 p-3 rounded-lg shadow-md"
              >
                <span className="text-sm text-white">
                  {activity.name} ({activity.coins} {t.activityCoins.toLowerCase()})
                </span>
                <div className="flex space-x-3">
                  <button
                    className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-r from-red-500 to-pink-500 shadow-lg hover:from-red-600 hover:to-pink-600 transition-all duration-300"
                    onClick={(e) => {
                      e.preventDefault();
                      handleActivityDelete(activity._id);
                    }}
                    title={t.deleteActivity}
                  >
                    <FaTrashAlt className="text-white text-xl" />
                  </button>
                  <button
                    className="w-12 h-12 rounded-full flex items-center justify-center bg-gradient-to-r from-blue-500 to-purple-500 shadow-lg hover:from-blue-600 hover:to-purple-600 transition-all duration-300"
                    onClick={(e) => {
                      e.preventDefault();
                      openEditModal(activity);
                    }}
                    title={t.editActivity}
                  >
                    <FaEdit className="text-white text-xl" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Modal for Adding a New Activity */}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl mb-4 text-white">{t.addActivity}</h3>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="newActivityName">
                {t.activityName}
              </label>
              <input
                type="text"
                id="newActivityName"
                name="name"
                value={newActivity.name}
                onChange={(e) =>
                  setNewActivity({ ...newActivity, name: e.target.value })
                }
                onKeyDown={onNewActivityKeyDown}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder={t.activityName}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="newActivityCoins">
                {t.activityCoins}
              </label>
              <input
                type="number"
                id="newActivityCoins"
                name="coins"
                value={newActivity.coins}
                onChange={(e) =>
                  setNewActivity({ ...newActivity, coins: +e.target.value })
                }
                onKeyDown={onNewActivityKeyDown}
                className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-purple-500"
                placeholder={t.activityCoins}
              />
            </div>
            <div className="flex justify-end space-x-4">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  closeAddModal();
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-red-500 to-red-700 shadow-lg hover:from-red-600 hover:to-red-800 transition-all duration-300 text-white"
              >
                {t.cancel}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleActivitySubmit(e).then(() => closeAddModal());
                }}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-green-500 to-blue-500 shadow-lg hover:from-green-600 hover:to-blue-600 transition-all duration-300 text-white"
              >
                {t.saveActivity}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActivitiesManager;
