import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaPlus, FaSpinner, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DJBattles: React.FC = () => {
  const { venueId } = useParams<{ venueId: string }>();
  const { currentToken } = useAuth();
  const [userData, setUserData] = useState<any>(null);
  const [battles, setBattles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [newBattle, setNewBattle] = useState<{ name: string; participants: string[] }>({
    name: '',
    participants: [],
  });
  const navigate = useNavigate();

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`${APIBase}/client/user`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${currentToken}`, 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      Swal.fire({ title: 'Error', text: 'Failed to fetch user profile.', icon: 'error' });
    }
  };

  const isAdmin = userData?.role === 'admin';

  const translations = {
    english: {
      title: 'DJ Battles',
      createBattle: 'Create Battle',
      edit: 'Edit',
      delete: 'Delete',
      noBattles: 'No battles available.',
      name: 'Name',
      participants: 'Participants (comma-separated IDs)',
      add: 'Add',
      cancel: 'Cancel',
      loading: 'Loading battles...',
      success: 'Success!',
      battleCreated: 'Battle created successfully!',
      battleDeleted: 'Battle deleted successfully!',
      error: 'Error!',
      deleteConfirm: 'Are you sure you want to delete this battle?',
    },
  };

  const t = translations.english;

  useEffect(() => {
    fetchUserProfile();
    fetchBattles();
  }, []);

  const fetchBattles = async () => {
    try {
      const response = await fetch(`${APIBase}/client/battle?venueId=${venueId}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      });
      const data = await response.json();
      setBattles(data.data || []);
    } catch (error) {
      console.error('Error fetching battles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateBattle = async () => {
    try {
      const response = await fetch(`${APIBase}/client/battle`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...newBattle, venueId }),
      });

      if (response.ok) {
        Swal.fire(t.success, t.battleCreated, 'success');
        setNewBattle({ name: '', participants: [] });
        setModalOpen(false);
        fetchBattles();
      } else {
        Swal.fire(t.error, 'Failed to create battle.', 'error');
      }
    } catch (error) {
      console.error('Error creating battle:', error);
      Swal.fire(t.error, 'Failed to create battle.', 'error');
    }
  };

  const handleDeleteBattle = async (battleId: string) => {
    const confirm = await Swal.fire({
      title: t.deleteConfirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t.delete,
      cancelButtonText: t.cancel,
    });

    if (confirm.isConfirmed) {
      try {
        const response = await fetch(`${APIBase}/client/battle/${battleId}`, {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${currentToken}` },
        });

        if (response.ok) {
          Swal.fire(t.success, t.battleDeleted, 'success');
          fetchBattles();
        } else {
          Swal.fire(t.error, 'Failed to delete battle.', 'error');
        }
      } catch (error) {
        console.error('Error deleting battle:', error);
        Swal.fire(t.error, 'Failed to delete battle.', 'error');
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white p-6 pt-24">
      <div className="flex items-center justify-between mb-6">
        <button onClick={() => navigate(-1)} className="text-gray-300 hover:text-white flex items-center">
          <FaArrowLeft className="mr-2" />
          Back
        </button>
        {isAdmin && (
          <button
            onClick={() => setModalOpen(true)}
            className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white p-2 rounded-full shadow-lg flex items-center"
          >
            <FaPlus className="text-lg" />
          </button>
        )}
      </div>
      <h1 className="text-4xl font-extrabold tracking-tight mb-8 text-center">{t.title}</h1>

      {loading ? (
        <div className="flex items-center justify-center">
          <FaSpinner className="animate-spin text-4xl text-gray-500" />
          <p className="ml-2">{t.loading}</p>
        </div>
      ) : battles.length > 0 ? (
        <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {battles.map((battle) => (
            <li
              key={battle._id}
              className="p-6 bg-gray-800 rounded-lg shadow-md hover:shadow-xl transition-shadow flex flex-col justify-between"
            >
              <div onClick={() => navigate(`/app/partyticket/dj-battle/${battle._id}`)}>
                <h2 className="text-2xl font-bold mb-2">{battle.name}</h2>
                <p className="text-sm text-gray-400">Participants: {battle.participants.length}</p>
              </div>
              <div className="mt-4 flex space-x-4">
                {isAdmin && (
                  <button
                    onClick={() => handleDeleteBattle(battle._id)}
                    className="text-red-400 hover:text-red-500 p-2 rounded-lg bg-gray-700 hover:bg-red-700 shadow-md"
                    title={t.delete}
                  >
                    <FaTrashAlt className="text-lg" />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-400">{t.noBattles}</p>
      )}

      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-2xl font-bold mb-4">{t.createBattle}</h3>
            <div className="space-y-4">
              <input
                type="text"
                placeholder={t.name}
                value={newBattle.name}
                onChange={(e) => setNewBattle({ ...newBattle, name: e.target.value })}
                className="w-full p-3 rounded-md bg-gray-700 text-white placeholder-gray-400"
              />
              <textarea
                placeholder={t.participants}
                value={newBattle.participants.join(', ')}
                onChange={(e) =>
                  setNewBattle({ ...newBattle, participants: e.target.value.split(',').map((id) => id.trim()) })
                }
                className="w-full p-3 rounded-md bg-gray-700 text-white placeholder-gray-400"
              />
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setModalOpen(false)}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-md"
                >
                  {t.cancel}
                </button>
                <button
                  onClick={handleCreateBattle}
                  className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white px-4 py-2 rounded-md shadow-md"
                >
                  {t.add}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DJBattles;
