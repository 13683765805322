import React from 'react';
import PassesSection from './PassesSection';

interface BuyTicketsProps {
  eventId: string;
  passes: any[]; // Adjust type as needed.
  t: any;
}

const BuyTickets: React.FC<BuyTicketsProps> = ({ eventId, passes, t }) => {
  return (
    <div className="flex flex-col space-y-6 md:h-[calc(100vh-180px)] overflow-y-scroll pt-6 pb-24 min-h-[calc(100vh-180px)]">
      <PassesSection passes={passes} t={t} eventId={eventId} />
    </div>
  );
};

export default BuyTickets;
