import { useAuth } from 'Contexts/AuthContext';
import { APIBase } from 'api/hosts';
import React, { useEffect, useState } from 'react';
import { FaArrowLeft, FaExternalLinkAlt, FaQrcode } from 'react-icons/fa';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

interface Ticket {
  _id: string;
  event: {
    _id: string;
    title: string;
    date: string;
    imageURL?: string;
  };
  pass: {
    name: string;
    price: number;
    currency: string;
  };
  quantity: number;
  status: string; // "paid", "pending", or "used"
}

const MyTickets: React.FC = () => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentToken } = useAuth();
  const navigate = useNavigate();
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [activeTab, setActiveTab] = useState<'paid' | 'pending' | 'used'>('paid');

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(`${APIBase}/client/ticket/my-tickets`, {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        });
        const data = await response.json();
        if (response.ok) {
          setTickets(data);
        } else {
          Swal.fire('Error', 'Failed to load tickets', 'error');
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
        Swal.fire('Error', 'An error occurred while fetching tickets', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchTickets();
  }, [currentToken]);

  const openQrModal = (ticket: Ticket) => {
    setSelectedTicket(ticket);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedTicket(null);
  };

  const filteredTickets = tickets.filter((ticket) => ticket.status === activeTab);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black">
        <StageSpinner size={50} color="#ffffff" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white py-24">
      {/* Return Button */}
      <button
        className="absolute top-6 left-6 bg-gradient-to-r from-gray-800 to-gray-900 text-white px-4 py-2 rounded-full shadow-lg hover:from-gray-700 hover:to-gray-800 flex items-center transition-all"
        onClick={() => navigate(-1)}
      >
        <FaArrowLeft className="mr-2" /> Return
      </button>

      <h2 className="text-3xl font-bold text-center mb-8">My Tickets</h2>

      {/* Tabs Navigation */}
      <div className="flex justify-center mb-6 space-x-4">
        {['paid', 'pending', 'used'].map((tab) => (
          <button
            key={tab}
            className={`px-6 py-3 text-lg font-semibold rounded-full shadow-md transition-all ${
              activeTab === tab
                ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
                : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
            }`}
            onClick={() => setActiveTab(tab as 'paid' | 'pending' | 'used')}
          >
            {tab === 'paid' && 'Paid'}
            {tab === 'pending' && 'Pending'}
            {tab === 'used' && 'Used'}
          </button>
        ))}
      </div>

      {/* No Tickets Message */}
      {filteredTickets.length === 0 ? (
        <p className="text-center text-gray-400">No {activeTab} tickets found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredTickets.map((ticket) => (
            <div
              key={ticket._id}
              className="relative bg-gradient-to-r from-gray-700 to-gray-900 p-5 rounded-lg shadow-lg hover:border-neon-blue transition-all overflow-hidden"
            >
              {/* Ticket Content */}
              <div className="relative bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 bg-opacity-80 p-4 rounded-lg shadow-lg">
                <img
                  src={ticket.event.imageURL}
                  alt={ticket.event.title}
                  className="w-full h-40 object-cover rounded-md mb-3"
                />
                <h3 className="text-lg font-semibold text-white">{ticket.event.title}</h3>
                <p className="text-sm text-gray-400">{new Date(ticket.event.date).toDateString()}</p>
                <p className="text-md font-bold text-teal-400">
                  {ticket.pass.name} - {ticket.pass.price} {ticket.pass.currency}
                </p>

                {/* Action Buttons: Only show if ticket status is 'paid' */}
                {ticket.status === 'paid' && (
                  <div className="flex justify-between mt-4 gap-3">
                    <button
                      onClick={() => openQrModal(ticket)}
                      className="flex items-center justify-center bg-gradient-to-r from-blue-500 to-teal-500 text-white py-2 px-4 rounded-lg shadow-md hover:from-blue-600 hover:to-teal-600 transition-all"
                    >
                      <FaQrcode className="mr-2" /> QR Code
                    </button>
                    <button
                      onClick={() => navigate(`/app/partyticket/event/${ticket.event._id}`)}
                      className="flex items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-4 rounded-lg shadow-md hover:from-purple-600 hover:to-pink-600 transition-all"
                    >
                      <FaExternalLinkAlt className="mr-2" /> View Event
                    </button>
                  </div>
                )}
              </div>

              {/* Quantity Badge */}
              <div className="absolute top-3 right-3 bg-gradient-to-r from-teal-500 to-blue-600 text-white px-3 py-1 rounded-full text-xs font-bold shadow-lg">
                x{ticket.quantity}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* QR Code Modal */}
      {selectedTicket && (
        <Modal
          isOpen={qrModalOpen}
          onRequestClose={closeQrModal}
          className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-90"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-sm w-full text-white">
            <h2 className="text-2xl font-bold mb-4 text-center">QR Code</h2>
            <div className="flex justify-center bg-white p-5 mt-4 rounded-lg">
              <QRCode
                value={JSON.stringify({
                  ticketId: selectedTicket._id,
                  passName: selectedTicket.pass.name,
                  quantity: selectedTicket.quantity,
                })}
                size={200}
              />
            </div>
            <div className="mt-6 flex flex-col space-y-4">
              <button
                onClick={closeQrModal}
                className="w-full bg-gradient-to-r from-pink-600 to-purple-600 text-white py-2 rounded-lg shadow-md hover:from-pink-700 hover:to-purple-700 transition-all duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MyTickets;
