// hooks/useFetchRooms.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchRooms = (currentToken: string) => {
  const [rooms, setRooms] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const response = await fetch(`${APIBase}/client/room`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${currentToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          setRooms(data.rooms);
        } else {
          throw new Error('Failed to fetch rooms');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch rooms.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, [currentToken]);

  return { rooms, loading };
};

export default useFetchRooms;
