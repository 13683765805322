// src/components/ViewEvent/EventDetails.tsx
import moment from 'moment';
import React from 'react';
import { FaBuilding, FaHeadphones, FaMusic, FaTicketAlt, FaUserLock } from 'react-icons/fa';

interface EventDetailsProps {
  event: any;
  venue: any;
  t: any;
}

const EventDetails: React.FC<EventDetailsProps> = ({ event, venue, t }) => {
  return (
    <div className="lg:w-1/2">
      <img
        src={event.imageURL}
        alt={event.title}
        className="w-full h-64 md:h-80 object-cover rounded-lg shadow-2xl mb-6"
      />
      {/* Preserve whitespace and line breaks */}
      <p className="text-gray-300 leading-relaxed whitespace-pre-line mb-6">{event.description}</p>
      <div className="space-y-2">
        <p className="text-gray-300">
          <strong>Date:</strong> {moment(event.date).format('MMMM Do, YYYY')}
        </p>
        <p className="text-gray-300">
          <strong>Time:</strong> {event.time}
        </p>
        <p className="text-gray-300">
          <strong>Location:</strong> {venue.name}
        </p>
        <p className="text-gray-300">
          <strong>Address:</strong> {venue.address}
        </p>
      </div>

      {/* Genres */}
      {event.genres && event.genres.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-4">
          <p className="text-gray-300 flex items-center">
            <FaMusic className="mr-2" /> Genres:
          </p>
          {event.genres.map((genre: string, index: number) => (
            <span
              key={index}
              className="bg-gradient-to-r from-teal-400 to-blue-600 text-white px-3 py-1 rounded-full shadow-md"
            >
              {genre}
            </span>
          ))}
        </div>
      )}

      {/* Venue Types */}
      {venue.venueTypes && venue.venueTypes.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-4">
          <p className="text-gray-300 flex items-center">
            <FaBuilding className="mr-2" /> Venue Type:
          </p>
          {venue.venueTypes.map((venueType: string, index: number) => (
            <span
              key={index}
              className="bg-gradient-to-r from-purple-500 to-pink-500 text-white px-3 py-1 rounded-full shadow-md"
            >
              {venueType}
            </span>
          ))}
        </div>
      )}

      {/* Optional Details */}
      {event.dressCode && (
        <p className="flex items-center text-gray-300 mt-4">
          <FaUserLock className="mr-2" /> Dress Code: {event.dressCode}
        </p>
      )}
      {event.ageRestriction && (
        <p className="flex items-center text-gray-300 mt-2">
          <FaUserLock className="mr-2" /> Age Restriction: {event.ageRestriction}
        </p>
      )}
      {event.djs && event.djs.length > 0 && (
        <p className="flex items-center text-gray-300 mt-2">
          <FaHeadphones className="mr-2" /> DJs: {event.djs.join(', ')}
        </p>
      )}
      {event.verified && (
        <div className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-yellow-100 px-2 py-1 rounded-full border border-yellow-400 inline-flex items-center mt-4">
          <FaUserLock className="mr-1" /> Verified
        </div>
      )}
      {event.ticketLink && (
        <div className="mt-6">
          <a
            href={event.ticketLink}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white px-4 py-3 rounded-lg flex items-center justify-center transition-all"
          >
            <FaTicketAlt className="mr-2" /> {t.buyTickets}
          </a>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
