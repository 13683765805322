// hooks/useFetchAllUsers.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchAllUsers = (currentToken: string) => {
  const [users, setUsers] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${APIBase}/client/user/allUsers`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${currentToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          throw new Error('Failed to fetch users');
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch total coins leaderboard',
          icon: 'error',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentToken]);

  return { users, loading };
};

export default useFetchAllUsers;
