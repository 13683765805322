import {
  faArrowLeft,
  faCalendarAlt,
  faClipboardList,
  faGift,
  faPlusCircle,
  faTrophy,
  faVideo,
  faForward, // For skip-video
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

const translations = {
  english: {
    coinsTitle: 'Your Coins History',
    totalCoins: 'Total Coins',
    date: 'Date',
    activity: 'Activity',
    coins: 'Coins',
    noCoins: 'No coins history available.',
    error: 'Error fetching coins. Please try again later.',
    back: 'Back to Profile',
    today: 'Today',
    yesterday: 'Yesterday',
    "skip-video": "Skipped a video. 2 Taki Coins deducted.",
  },
  spanish: {
    coinsTitle: 'Historial de Puntos',
    totalCoins: 'Puntos Totales',
    date: 'Fecha',
    activity: 'Actividad',
    coins: 'Puntos',
    noCoins: 'No hay historial de puntos disponible.',
    error: 'Error al obtener los puntos. Por favor, inténtelo más tarde.',
    back: 'Volver al Perfil',
    today: 'Hoy',
    yesterday: 'Ayer',
    "skip-video": "Video saltado. Se han deducido 2 Taki Coins.",
  },
  polish: {
    coinsTitle: 'Historia Punktów',
    totalCoins: 'Suma Punktów',
    date: 'Data',
    activity: 'Aktywność',
    coins: 'Punkty',
    noCoins: 'Brak historii punktów.',
    error: 'Błąd pobierania punktów. Spróbuj ponownie później.',
    back: 'Powrót do Profilu',
    today: 'Dziś',
    yesterday: 'Wczoraj',
    "skip-video": "Wideo pominięte. Odjęto 2 Taki Coins.",
  },
};

const CoinsList: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];
  const navigate = useNavigate();
  const [coinsData, setCoinsData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchCoins();
  }, []);

  const fetchCoins = async () => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/coin`, data);
      const result = await response.json();
      if (response.ok) {
        setCoinsData(result);
      } else {
        Swal.fire({
          title: t.error,
          text: result.message || t.error,
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.error,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const getActivityIcon = (activity: string) => {
    switch (activity) {
      case 'check-in':
        return faCalendarAlt;
      case 'event':
        return faClipboardList;
      case 'discount-redemption':
        return faGift;
      case 'activity':
        return faArrowLeft;
      case 'add-video':
        return faVideo;
      case 'contest_winner':
        return faTrophy;
      case 'message-submission':
        return faPlusCircle;
      case 'buy-coins':
        return faGift;
      case 'skip-video':
        return faForward;
      default:
        return faArrowLeft;
    }
  };

  const getActivityMessage = (activity: string) => {
    switch (activity) {
      case 'check-in':
        return 'Checked in at a venue.';
      case 'event':
        return 'Attended an event.';
      case 'discount-redemption':
        return 'Redeemed a discount.';
      case 'activity':
        return 'Completed an activity.';
      case 'add-video':
        return 'Added a video.';
      case 'contest_winner':
        return 'Won a contest!';
      case 'message-submission':
        return 'Message Dedication submitted.';
      case 'buy-coins':
        return 'Bought additional coins.';
      case 'skip-video':
        return t["skip-video"];
      default:
        return 'Activity recorded.';
    }
  };

  const getRowStyle = (coinsEarned: number) => {
    if (coinsEarned > 0) {
      return 'bg-gradient-to-r from-green-600 to-teal-600 text-white';
    } else if (coinsEarned < 0) {
      return 'bg-gradient-to-r from-red-600 to-yellow-600 text-white';
    } else {
      return 'bg-gradient-to-r from-gray-600 to-gray-800 text-gray-300';
    }
  };

  const formatDate = (date: string) => {
    const now = new Date();
    const entryDate = new Date(date);

    if (entryDate.toDateString() === now.toDateString()) {
      return t.today;
    }

    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);

    if (entryDate.toDateString() === yesterday.toDateString()) {
      return t.yesterday;
    }

    return entryDate.toLocaleDateString();
  };

  // Use reverse calculation: starting with current total coins, for each transaction (assumed sorted descending),
  // display current balance, then update it by "rolling back" the transaction.
  let balance = coinsData?.totalCoins;

  return (
    <div className="min-h-screen p-4 text-white bg-gray-900">
      {/* Return Button */}
      <button
        onClick={() => navigate('/app/profile')}
        className="fixed top-4 left-4 p-3 bg-purple-600 text-white rounded-full shadow-lg hover:bg-purple-700 transition-all duration-300 ease-in-out mt-20 h-10 w-10 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="text-xl" />
      </button>

      {loading ? (
        <div className="flex justify-center items-center mt-16">
          <StageSpinner color="#ffffff" size={50} loading={loading} />
        </div>
      ) : coinsData ? (
        <div>
          <h1 className="text-2xl font-bold mb-4 mt-32">{t.coinsTitle}</h1>
          <div className="mb-4 text-lg">
            <strong>{t.totalCoins}: </strong>
            {coinsData?.totalCoins}
          </div>
          {coinsData?.coinsLog.length > 0 ? (
            <div className="p-4 rounded-lg shadow-md bg-gray-800 overflow-x-auto">
            <table className="min-w-[600px] divide-y divide-gray-700">
              <thead className="bg-gray-900">
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300 uppercase tracking-wider">
                    {t.date}
                  </th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300 uppercase tracking-wider">
                    {t.activity}
                  </th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-300 uppercase tracking-wider">
                    {t.coins}
                  </th>
                  <th className="px-4 py-2 text-right text-sm font-medium text-gray-300 uppercase tracking-wider">
                    Final Coins
                  </th>
                </tr>
              </thead>
              <tbody className="bg-gray-800 divide-y divide-gray-700">
                {coinsData?.coinsLog.map((entry: any) => {
                  const rowBalance = balance;
                  balance = balance - entry.coinsEarned;
                  const activityMessage = getActivityMessage(entry.activity);
                  const activityIcon = getActivityIcon(entry.activity);
                  return (
                    <tr key={entry._id} className="hover:bg-gray-700 transition-colors">
                      <td className="px-4 py-2 text-sm text-gray-200">{formatDate(entry.date)}</td>
                      <td className="px-4 py-2 flex items-center space-x-2 text-sm text-gray-200">
                        <FontAwesomeIcon icon={activityIcon} className="text-lg" />
                        <span>{activityMessage}</span>
                      </td>
                      <td className={`px-4 py-2 text-sm text-right ${entry.coinsEarned < 0 ? 'text-red-400' : 'text-green-400'}`}>
                        {entry.coinsEarned}
                      </td>
                      <td className="px-4 py-2 text-sm text-right text-gray-300">{rowBalance}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          
          
          ) : (
            <p className="text-gray-300">{t.noCoins}</p>
          )}
        </div>
      ) : (
        <p className="text-gray-300">{t.noCoins}</p>
      )}
    </div>
  );
};

export default CoinsList;
