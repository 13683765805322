import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'animate.css';
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import { useLocationContext } from 'Contexts/LocationContext';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CitySelectionModal from '../../PrivateApp/CitySelectionModal';
import Modal from 'react-modal';
import { FaCoins, FaGift, FaListAlt, FaMapMarkerAlt, FaTimes } from 'react-icons/fa';

Modal.setAppElement('#root');

const translations = {
  english: {
    selectCity: 'Select City',
    gps: 'GPS',
    coins: 'Coins',
    coinsInfo: 'Use your coins to get discounts on amazing deals! Click below to explore discounts.',
    buyCoins: 'Buy Coins',
    earnCoins: 'Earn Coins',
    claimFreeDrinks: 'Claim Free Drinks',
    selectLanguage: 'Select Language',
    errorFetchingCities: 'Failed to fetch cities. Please try again later.',
    errorFetchingUser: 'Failed to fetch user data. Please try again later.',
  },
  spanish: {
    selectCity: 'Seleccionar Ciudad',
    gps: 'GPS',
    coins: 'Puntos',
    coinsInfo:
      '¡Usa tus puntos para obtener descuentos en ofertas increíbles! Haz clic abajo para explorar descuentos.',
    buyCoins: 'Comprar Puntos',
    earnCoins: 'Ganar Puntos',
    claimFreeDrinks: 'Reclamar Bebidas Gratis',
    selectLanguage: 'Seleccionar Idioma',
    errorFetchingCities: 'No se pudieron obtener las ciudades. Por favor, inténtalo de nuevo más tarde.',
    errorFetchingUser: 'No se pudieron obtener los datos del usuario. Por favor, inténtalo de nuevo más tarde.',
  },
  polish: {
    selectCity: 'Wybierz Miasto',
    gps: 'GPS',
    coins: 'Punkty',
    coinsInfo:
      'Użyj swoich punktów, aby uzyskać zniżki na niesamowite oferty! Kliknij poniżej, aby je zobaczyć.',
    buyCoins: 'Kup Punkty',
    earnCoins: 'Zdobywaj Punkty',
    claimFreeDrinks: 'Odbierz Darmowe Napoje',
    selectLanguage: 'Wybierz Język',
    errorFetchingCities: 'Nie udało się pobrać miast. Spróbuj ponownie później.',
    errorFetchingUser: 'Nie udało się pobrać danych użytkownika. Spróbuj ponownie później.',
  },
};

const NavElements = () => {
  const { language, setLanguage, options } = useLanguage();
  const { currentToken, expiration } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isCoinsDropdownOpen, setIsCoinsDropdownOpen] = useState(false);
  const { selectedCity, isUsingGPS, setSelectedCity, fetchUserCity } = useLocationContext();
  const [cities, setCities] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  const isAuthenticated = currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  useEffect(() => {
    if (isAuthenticated) {
      fetchCities();
      fetchUserData();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    fetchUserCity();
  }, []);

  const handleButtonClick = (action: string) => {
    switch (action) {
      case 'buy-coins':
        navigate('/app/partyticket/buycoins');
        break;
      case 'explore-activities':
        navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616');
        break;
      case 'view-discounts':
        navigate('/app/partyticket/discounts/66cc50ad5d4ada8c64b24616');
        break;
      default:
        break;
    }
    setIsCoinsDropdownOpen(false);
  };

  const fetchCities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/cities`, data);
      if (response.ok) {
        const data = await response.json();
        setCities(data);
      } else {
        throw new Error(t.errorFetchingCities);
      }
    } catch {
      Swal.fire({
        title: 'Error',
        text: t.errorFetchingCities,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/user`, data);
      if (response.ok) {
        const result = await response.json();
        setUserData(result);
      } else {
        throw new Error(t.errorFetchingUser);
      }
    } catch {
      Swal.fire({
        title: 'Error',
        text: t.errorFetchingUser,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    setIsModalOpen(false);
  };

  const handleLocationClick = () => {
    setIsCityModalOpen(true);
  };

  const handleCitySelection = async (cityId: string) => {
    localStorage.setItem('selectedCity', cityId);
    setSelectedCity(cityId);
    setIsCityModalOpen(false);
  };

  // Dropdown ref and outside click handling
  const coinsDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (coinsDropdownRef.current && !coinsDropdownRef.current.contains(event.target as Node)) {
        setIsCoinsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div
        className="fixed left-0 top-0 w-full flex items-center justify-center h-16 py-4 bg-gradient-to-r from-gray-900 to-gray-800 shadow-lg"
        style={{ zIndex: 1000 }}
      >
        <div className="absolute right-2 flex items-center space-x-3">
          {currentToken && cities?.length && (
            <button
              onClick={handleLocationClick}
              className="text-white text-xs flex items-center justify-center p-1 px-3 rounded-lg h-8 bg-gray-800 hover:bg-gray-700 transition-all duration-200"
            >
              <FaMapMarkerAlt className="text-lg mr-1" />
              {selectedCity ? (
                isUsingGPS ? (
                  <>
                    <span>{t.gps}</span>
                    <span className="w-2 h-2 bg-green-500 rounded-full ml-1"></span>
                  </>
                ) : (
                  <span>{cities.find((city) => city._id === selectedCity)?.name}</span>
                )
              ) : (
                <span>{t.selectCity}</span>
              )}
            </button>
          )}
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-gray-800 text-white text-xs p-1 rounded-lg px-3 h-8 hover:bg-gray-700 transition-all duration-200"
          >
            {options.find((option) => option.value === language)?.flag || t.selectLanguage}
          </button>
          {userData && (
            <div className="relative flex items-center space-x-2">
              <div
                onClick={() => setIsCoinsDropdownOpen(!isCoinsDropdownOpen)}
                className="flex items-center space-x-1 cursor-pointer"
              >
                <FaCoins className="text-yellow-500" />
                <span className="text-white text-sm">{userData?.totalCoins || 0}</span>
              </div>
              {isCoinsDropdownOpen && (
                <div
                  ref={coinsDropdownRef}
                  className="absolute top-full right-0 mt-2 w-64 bg-black bg-opacity-90 border border-gray-700 rounded-lg shadow-lg p-4 z-50"
                >
                  <div className="mb-4 text-center">
                    <span className="text-lg font-semibold text-yellow-500">
                      Your Coins: {userData?.totalCoins || 0}
                    </span>
                  </div>
                  <div className="space-y-4">
                    <button
                      onClick={() => handleButtonClick('buy-coins')}
                      className="w-full bg-gradient-to-r from-green-500 to-blue-500 text-white py-2 rounded-lg shadow-md hover:bg-gradient-to-l hover:from-teal-500 hover:to-blue-400 transition-all duration-300 flex items-center justify-center"
                    >
                      <FaCoins className="mr-2" />
                      {t.buyCoins}
                    </button>
                    <button
                      onClick={() => handleButtonClick('explore-activities')}
                      className="w-full bg-gradient-to-r from-blue-500 to-teal-500 text-white py-2 rounded-lg shadow-md hover:bg-gradient-to-l hover:from-teal-500 hover:to-blue-400 transition-all duration-300 flex items-center justify-center"
                    >
                      <FaListAlt className="mr-2" />
                      {t.earnCoins}
                    </button>
                    <button
                      onClick={() => handleButtonClick('view-discounts')}
                      className="w-full bg-gradient-to-r from-teal-500 to-green-500 text-white py-2 rounded-lg shadow-md hover:bg-gradient-to-l hover:from-teal-500 hover:to-blue-400 transition-all duration-300 flex items-center justify-center"
                    >
                      <FaGift className="mr-2" />
                      {t.claimFreeDrinks}
                    </button>
                  </div>
                </div>
              )}
              <img
                onClick={() => navigate('/app/profile')}
                src={userData?.profilePicture || userData?.media?.[0] || '/default-avatar.png'}
                alt="Profile"
                className="w-8 h-8 rounded-full cursor-pointer"
              />
            </div>
          )}
        </div>
      </div>

      {isCityModalOpen && (
        <CitySelectionModal onCitySelect={handleCitySelection} onClose={() => setIsCityModalOpen(false)} />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={t.selectLanguage}
        className="fixed inset-0 flex items-center justify-center z-50 p-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            maxWidth: '95%',
            maxHeight: '95%',
            margin: 'auto',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl mb-4">{t.selectLanguage}</h2>
          <ul className="flex flex-col space-y-2 w-full">
            {options.map((option) => (
              <li key={option.value} className="w-full">
                <button
                  onClick={() => handleLanguageChange(option.value)}
                  className="bg-gray-800 hover:bg-gray-600 text-white py-2 px-4 rounded-lg w-full text-left"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default NavElements;
