import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { APIBase } from 'api/hosts';
import { stripePublicKey } from 'constants/stripePublicKey';
import { useAuth } from 'Contexts/AuthContext';
import { useState } from 'react';
import { FaCoins } from 'react-icons/fa'; // Add icons
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// Load the Stripe object (Public key is dynamically determined based on environment)
const stripePromise = loadStripe(stripePublicKey);

// const coinOptions = [
//   { price: 10, coins: 1000 },
//   { price: 20, coins: 2100 },
//   { price: 30, coins: 3200 },
//   { price: 40, coins: 4300 },
//   { price: 50, coins: 5400 },
//   { price: 60, coins: 6500 },
//   { price: 70, coins: 7600 },
//   { price: 80, coins: 8700 },
//   { price: 90, coins: 9800 },
//   { price: 100, coins: 10900 },
// ];

const coinOptions = [
  // { price: 2, coins: 200, icon: <FaCoins /> },
  // { price: 3, coins: 300, icon: <FaCoins /> },
  { price: 10, coins: 1000, icon: <FaCoins /> },
  { price: 30, coins: 3200, icon: <FaCoins /> },
  { price: 50, coins: 5400, icon: <FaCoins /> },
  { price: 100, coins: 10900, icon: <FaCoins /> },
];

const BuyCoins = () => {
  const [amount, setAmount] = useState<number>(0); // Track the selected amount
  const [selectedPrice, setSelectedPrice] = useState<number | null>(null); // Track the selected price option
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
  const { currentToken } = useAuth();

  const handleAmountSelection = (price: number, coins: number) => {
    setAmount(price); // Set the amount based on the selected option
    setSelectedPrice(price); // Set the selected price to highlight the button
  };

  const handleBuyCoins = async () => {
    if (amount <= 0) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a valid amount of coins to purchase.',
        icon: 'error',
        timer: 3000,
      });
      return;
    }

    if (!stripe || !elements) {
      Swal.fire({
        title: 'Error',
        text: 'Stripe is not loaded properly, please try again.',
        icon: 'error',
        timer: 3000,
      });
      return;
    }

    try {
      const session = await fetch(`${APIBase}/client/coin/create-checkout-session`, {
        method: 'POST',
        body: JSON.stringify({ amount: amount }), // Amount in PLN (10 ZL = 1000 coins)
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => res.json());

      const { error } = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          timer: 3000,
        });
      } else {
        Swal.fire({
          title: 'Success!',
          text: `You have successfully purchased ${amount} coins!`,
          icon: 'success',
          timer: 3000,
        });

        navigate('/app/partyticket');
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: 'There was an issue with the payment process.',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-blue-900 to-teal-900 text-white p-4 sm:p-4 md:p-6">
      <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-center mb-4">Buy Coins</h2>

      <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 gap-3 mb-6 w-full max-w-md">
        {coinOptions.map((option) => (
          <button
            key={option.price}
            onClick={() => handleAmountSelection(option.price, option.coins)}
            className={`flex flex-col items-center justify-center py-2 px-4 sm:py-2 sm:px-5 rounded-lg transition-all duration-300 focus:outline-none ${
              selectedPrice === option.price
                ? 'bg-teal-600 text-white border-2 border-teal-800'
                : 'bg-gray-800 text-white hover:bg-teal-600'
            }`}
          >
            <div className="text-2xl sm:text-3xl mb-1">{option.icon}</div>
            <span className="text-lg sm:text-xl font-semibold">{option.coins} Coins</span>
            <span className="text-xs sm:text-sm">{option.price} PLN</span>
          </button>
        ))}
      </div>

      <button
        onClick={handleBuyCoins}
        className="w-full sm:w-auto bg-gradient-to-r from-green-500 to-blue-500 text-white py-2 sm:py-3 px-6 sm:px-8 rounded-lg hover:bg-gradient-to-l hover:from-teal-500 hover:to-blue-400 focus:outline-none transition-all duration-300"
      >
        Purchase Coins
      </button>
    </div>
  );
};

// Wrap your component with Elements provider to pass Stripe context
const BuyCoinsPage = () => (
  <Elements stripe={stripePromise}>
    <BuyCoins />
  </Elements>
);

export default BuyCoinsPage;
