// src/config/eventEditorConfig.ts

export const typeOptions = [
  { value: 'party', label: 'Party' },
  { value: 'karaoke', label: 'Karaoke' },
];

export const currencyOptions = [
  { value: 'pln', label: 'PLN' },
  { value: 'eur', label: 'EUR' },
  { value: 'usd', label: 'USD' },
  { value: 'gbp', label: 'GBP' },
];

export const dressCodes = [
  { value: '', label: 'Select Dress Code' },
  { value: 'No Dress Code', label: 'No Dress Code' },
  { value: 'Casual', label: 'Casual' },
  { value: 'Smart Casual', label: 'Smart Casual' },
  { value: 'Business Attire', label: 'Business Attire' },
  { value: 'Formal', label: 'Formal' },
  { value: 'Black Tie', label: 'Black Tie' },
  { value: 'Cocktail Attire', label: 'Cocktail Attire' },
  { value: 'Costume', label: 'Costume' },
  { value: 'Beachwear', label: 'Beachwear' },
  { value: 'Festival', label: 'Festival' },
];

export const genreOptions = [
  'Reggaeton',
  'Twerk',
  'Salsa',
  'Bachata',
  'Kizomba',
  'Merengue',
  'Cumbia',
  'Trap',
  'Rap',
  'House',
  'EDM',
  'Pop',
  'Rock',
  'Hip-hop',
  'Jazz',
  'Classical',
  'Country',
  'Reggae',
  'Blues',
  'Soul',
  'Funk',
  'Disco',
  'Techno',
  'Dubstep',
  'Trance',
  'Drum & Bass',
  'Garage',
  'Latin',
  'Ska',
  'Metal',
  'Punk',
  'R&B',
  'Folk',
  'Gospel',
  'Opera',
  'World',
  'K-Pop',
  'J-Pop',
];
