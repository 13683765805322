const eventTranslations = {
    english: {
      events: 'Events',
      newEvent: 'New Event',
      edit: 'Edit',
      view: 'View',
      delete: 'Delete',
      deleted: 'Deleted!',
      deletedMessage: 'Event has been deleted.',
      error: 'Error',
      deleteError: 'Failed to delete event. Please try again later.',
      fetchError: 'Error fetching events',
      retryLater: 'Please try again later 😞',
      confirmDelete: 'Are you sure?',
      confirmDeleteText: "You won't be able to revert this!",
      confirmDeleteYes: 'Yes, delete it!',
      searchPlaceholder: 'Search events...',
      // ... any additional keys
    },
    spanish: {
      events: 'Eventos',
      newEvent: 'Nuevo Evento',
      edit: 'Editar',
      view: 'Ver',
      delete: 'Eliminar',
      deleted: '¡Eliminado!',
      deletedMessage: 'El evento ha sido eliminado.',
      error: 'Error',
      deleteError: 'Error al eliminar el evento. Por favor, inténtelo de nuevo más tarde.',
      fetchError: 'Error al obtener eventos',
      retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
      confirmDelete: '¿Estás seguro?',
      confirmDeleteText: '¡No podrás revertir esto!',
      confirmDeleteYes: 'Sí, eliminarlo!',
      searchPlaceholder: 'Buscar eventos...',
      // ... etc.
    },
    polish: {
      events: 'Wydarzenia',
      newEvent: 'Nowe Wydarzenie',
      edit: 'Edytuj',
      view: 'Zobacz',
      delete: 'Usuń',
      deleted: 'Usunięto!',
      deletedMessage: 'Wydarzenie zostało usunięte.',
      error: 'Błąd',
      deleteError: 'Nie udało się usunąć wydarzenia. Spróbuj ponownie później.',
      fetchError: 'Błąd pobierania wydarzeń',
      retryLater: 'Spróbuj ponownie później 😞',
      confirmDelete: 'Jesteś pewien?',
      confirmDeleteText: 'Nie będziesz mógł tego cofnąć!',
      confirmDeleteYes: 'Tak, usuń to!',
      searchPlaceholder: 'Szukaj wydarzeń...',
      // ... etc.
    },
  };
  
  export default eventTranslations;
  