// src/hooks/useEventDetail.ts
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Event, Venue } from 'types';

export const useEventDetail = (eventId: string, token: string) => {
  const [event, setEvent] = useState<Event | null>(null);
  const [venue, setVenue] = useState<Venue | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEventById = async (id: string, token: string): Promise<Event> => {
    const response = await fetch(`${APIBase}/client/event/byEventId/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch event');
    }
    return response.json();
  };

  const fetchVenueById = async (id: string, token: string): Promise<Venue> => {
    const response = await fetch(`${APIBase}/client/venue/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error('Failed to fetch venue');
    }
    return response.json();
  };

  useEffect(() => {
    if (eventId && token) {
      const fetchData = async () => {
        try {
          const eventData = await fetchEventById(eventId, token);
          const venueData = await fetchVenueById(eventData.clubId?._id, token);
          setEvent(eventData);
          setVenue(venueData);
          setLoading(false);
        } catch (err) {
          setError((err as Error).message);
          setLoading(false);
          Swal.fire({
            title: 'Error',
            text: (err as Error).message,
            icon: 'error',
            timer: 3000,
          });
        }
      };
      fetchData();
    }
  }, [eventId, token]);

  return { event, venue, loading, error };
};
