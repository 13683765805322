import { useAuth } from 'Contexts/AuthContext';
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import translations from './translations';
import { useLanguage } from 'Contexts/LanguageContext';

interface ScannedTicketData {
  ticketId: string;
  userId: string;
}

interface TicketDetails {
  _id: string;
  event: {
    _id: string;
    title: string;
    date: string;
    imageURL?: string;
    passes: {
      _id: string;
      name: string;
      description: string;
      price: number;
      currency: string;
      maxQuantity: number;
    }[];
  };
  passId: string;
  quantity: number;
  status: string;
}

const ScanTicket = () => {
  const [scannedData, setScannedData] = useState<ScannedTicketData | null>(null);
  const [ticketDetails, setTicketDetails] = useState<TicketDetails | null>(null);
  const [loading, setLoading] = useState(false);
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (scannedData && !ticketDetails) {
      fetchTicketDetails(scannedData.ticketId);
    }
  }, [scannedData?.ticketId]);

  const fetchTicketDetails = async (ticketId: string) => {
    setLoading(true);
    try {
      const response = await fetch(`${APIBase}/client/ticket/${ticketId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      const result = await response.json();
      if (response.ok) {
        setTicketDetails(result);
      } else {
        Swal.fire(t.error, result.message || 'Failed to fetch ticket details.', 'error');
      }
    } catch (error) {
      Swal.fire(t.error, 'An error occurred while fetching ticket details.', 'error');
    } finally {
      setLoading(false);
    }
  };

  const validateTicket = async () => {
    if (!scannedData) {
      Swal.fire(t.error, t.invalidQR, 'error');
      return;
    }

    setLoading(true);
    try {
      const { ticketId, userId } = scannedData;
      const response = await fetch(`${APIBase}/client/ticket/validate`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ticketId, userId }),
      });

      const result = await response.json();
      Swal.fire({
        title: response.ok ? t.successTitle : t.error,
        text: response.ok ? t.successMessage : result.message,
        icon: response.ok ? 'success' : 'error',
      });

      if (response.ok) {
        setTicketDetails({ ...ticketDetails!, status: 'used' });
      }
    } catch (error) {
      Swal.fire(t.error, 'Failed to validate ticket. Try again.', 'error');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-black">
        <StageSpinner size={50} color="#86ff0d" />
        <p className="text-white mt-4 text-lg font-semibold">{t.processing}</p>
      </div>
    );
  }

  if (!ticketDetails) {
    return (
      <div className="flex flex-col items-center justify-start h-screen bg-gradient-to-b from-black via-gray-900 to-black px-4 pt-4">
        <h2 className="text-3xl text-white mb-6 font-bold">{t.scanTitle}</h2>
        <div className="relative w-72 h-72 rounded-xl shadow-2xl overflow-hidden border-2 border-teal-500">
          <QrReader
            constraints={{ facingMode: 'environment' }}
            onResult={(result) => {
              if (result) {
                try {
                  const data: ScannedTicketData = JSON.parse(result.getText());
                  setScannedData(data);
                } catch (error) {
                  Swal.fire(t.error, t.invalidQR, 'error');
                }
              }
            }}
            containerStyle={{ width: '100%', height: '100%' }}
            videoContainerStyle={{ borderRadius: '12px' }}
          />
        </div>
      </div>
    );
  }

  const passDetails = ticketDetails.event.passes.find((p) => p._id === ticketDetails.passId);

  return (
    <div className="text-white bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-xl shadow-2xl w-full max-w-md">
      <h3 className="text-2xl font-bold mb-4 text-center">{t.ticketDetails}</h3>

      <div className="bg-gradient-to-r from-gray-700 to-gray-900 p-5 rounded-xl shadow-md">
        <h4 className="text-lg font-semibold text-white">{ticketDetails.event.title}</h4>
        <p className="text-md text-gray-400">
          <strong>{t.eventDate}:</strong> {new Date(ticketDetails.event.date).toDateString()}
        </p>
        <p className="text-md text-gray-400">
          <strong>{t.ticketType}:</strong> {passDetails?.name || 'N/A'}
        </p>
        <p className="text-md text-gray-400">
          <strong>{t.price}:</strong> {passDetails?.price} {passDetails?.currency}
        </p>
        <p className="text-md text-gray-400">
          <strong>{t.quantity}:</strong> {ticketDetails.quantity}
        </p>
        <p className={`text-md font-semibold mt-2 ${
          ticketDetails.status === 'pending' ? 'text-yellow-400' : ticketDetails.status === 'paid' ? 'text-blue-400' : 'text-green-400'
        }`}>
          {t.status}: {ticketDetails.status === 'pending' ? t.pending : ticketDetails.status === 'paid' ? t.paid : t.validated}
        </p>
      </div>

      {/* Show different UI based on status */}
      <div className="mt-6 flex flex-col items-center space-y-4">
        {ticketDetails.status === 'paid' && (
          <button className="w-full py-3 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg shadow-lg transition-all" onClick={validateTicket}>
            {t.validateTicket}
          </button>
        )}
        {ticketDetails.status === 'pending' && (
          <button className="w-full py-3 bg-gradient-to-r from-yellow-500 to-orange-500 text-white rounded-lg shadow-lg transition-all">
            ⏳ {t.pendingPayment}
          </button>
        )}
        {ticketDetails.status === 'used' && (
          <button className="w-full py-3 bg-gradient-to-r from-gray-500 to-gray-700 text-white rounded-lg shadow-lg transition-all cursor-not-allowed">
            ✅ {t.alreadyUsed}
          </button>
        )}
        <button className="w-full py-3 bg-gradient-to-r from-red-500 to-pink-500 text-white rounded-lg shadow-lg transition-all" onClick={() => setScannedData(null)}>
          {t.rescan}
        </button>
      </div>
    </div>
  );
};

export default ScanTicket;
