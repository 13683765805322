import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { APIBase } from 'api/hosts';
import { stripePublicKey } from 'constants/stripePublicKey';
import { useAuth } from 'Contexts/AuthContext';
import React, { useState } from 'react';
import { FaMinus, FaPlus, FaCoins } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const stripePromise = loadStripe(stripePublicKey);

interface Pass {
  _id: string;
  name: string;
  description: string;
  price: number;
  currency: string;
  maxQuantity: number;
  deadline: string; // ISO date string
  // sold object has keys: pending, paid, used
  sold: {
    pending: number;
    paid: number;
    used: number;
  };
}

interface PassesSectionProps {
  passes: Pass[];
  eventId: string;
  t: any;
}

const PassesSection: React.FC<PassesSectionProps> = ({ passes, eventId, t }) => {
  const [selectedPasses, setSelectedPasses] = useState<{ [key: string]: number }>({});
  const { currentToken } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  // Handle pass selection (increment or decrement)
  const handleSelectPass = (passId: string, increment: number) => {
    setSelectedPasses((prev) => ({
      ...prev,
      [passId]: Math.max(0, (prev[passId] || 0) + increment),
    }));
  };

  // Handle checkout
  const handleCheckout = async () => {
    try {
      const selectedItems = Object.entries(selectedPasses).filter(([_, quantity]) => quantity > 0);
      if (selectedItems.length === 0) {
        Swal.fire({
          title: t.selectPassesFirst,
          text: t.noPassesSelected,
          icon: 'warning',
          timer: 3000,
        });
        return;
      }

      if (!stripe || !elements) {
        Swal.fire({
          title: 'Error',
          text: t.stripeError,
          icon: 'error',
          timer: 3000,
        });
        return;
      }

      const response = await fetch(`${APIBase}/client/ticket/create-checkout-session`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventId,
          passes: selectedItems.map(([passId, quantity]) => ({ passId, quantity })),
        }),
      });

      const data = await response.json();

      if (!data.sessionId) {
        if (data.code === 'DEADLINE_PASSED') {
          Swal.fire({
            title: 'Purchase Unavailable',
            text: "The purchase deadline for one of the selected passes has passed.",
            icon: 'error',
            timer: 3000,
          });
        } else if (data.code === 'LIMIT_EXCEEDED') {
          Swal.fire({
            title: 'Purchase Unavailable',
            text: "Not enough tickets available for one of the selected passes.",
            icon: 'error',
            timer: 3000,
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: data.message || t.checkoutError,
            icon: 'error',
            timer: 3000,
          });
        }
        return;
      }

      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });
      if (error) {
        Swal.fire({
          title: 'Error',
          text: error.message || t.checkoutError,
          icon: 'error',
          timer: 3000,
        });
      }
    } catch (error) {
      console.error('Checkout Error:', error);
      Swal.fire({
        title: 'Error',
        text: t.checkoutError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  if (!passes || passes.length === 0) {
    return (
      <div className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-lg shadow-lg">
        <h3 className="text-xl font-semibold text-white mb-4">{t.passes}</h3>
        <p className="text-center text-gray-400">{t.noPassesAvailable || 'No passes available yet.'}</p>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-b from-gray-800 to-gray-900 p-6 rounded-lg shadow-lg">
      <h3 className="text-xl font-semibold text-white mb-4">{t.passes}</h3>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {passes.map((pass) => {
          // Compute current time, deadline, and sold-out status
          const currentTime = new Date();
          const deadlineDate = new Date(pass.deadline);
          const deadlinePassed = currentTime > deadlineDate;
          const soldOut = pass.sold && pass.sold.paid >= pass.maxQuantity;
          const disabled = deadlinePassed || soldOut;

          return (
            <div
              key={pass._id}
              className="relative bg-gradient-to-r from-gray-700 to-gray-900 border border-gray-600 shadow-lg transition-all rounded-lg p-6 overflow-hidden"
            >
              {/* SVG Ticket Shape for a ticket feel */}
              <svg
                className="absolute bottom-0 left-0 w-full h-12"
                viewBox="0 0 400 80"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="
                    M0,20 
                    Q30,0 60,20 
                    V60 
                    Q30,80 0,60 
                    Z 
                    M400,20 
                    Q370,0 340,20 
                    V60 
                    Q370,80 400,60 
                    Z
                  "
                  fill="rgba(255, 255, 255, 0.1)"
                  stroke="rgba(255, 255, 255, 0.15)"
                  strokeWidth="2"
                />
                <line
                  x1="60"
                  y1="40"
                  x2="340"
                  y2="40"
                  stroke="rgba(255, 255, 255, 0.2)"
                  strokeWidth="1"
                  strokeDasharray="8 4"
                />
              </svg>

              {/* Pass Content */}
              <div className="relative bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800 bg-opacity-80 p-4 rounded-lg shadow-lg">
                <h4 className="text-lg font-bold text-white">{pass.name}</h4>
                <p className="text-sm text-gray-400">{pass.description}</p>
                <p className="text-md font-semibold text-teal-400">
                  {pass.price} {pass.currency}
                </p>
                <p className="text-xs text-gray-300">
                  Deadline: {deadlineDate.toLocaleDateString()}
                </p>

                {disabled ? (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <p className="text-lg font-bold text-gradient bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent animate-pulse">
                      {deadlinePassed ? 'Sale Ended' : soldOut ? 'Sold Out' : ''}
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center mt-3 space-x-4">
                    <button
                      onClick={() => handleSelectPass(pass._id, -1)}
                      className="p-2 rounded-full bg-gradient-to-r from-red-500 to-pink-500 shadow-lg hover:from-red-600 hover:to-pink-600 transition-all"
                      disabled={!selectedPasses[pass._id]}
                    >
                      <FaMinus className="text-white text-lg" />
                    </button>
                    <span className="text-lg font-semibold text-white">
                      {selectedPasses[pass._id] || 0}
                    </span>
                    <button
                      onClick={() => handleSelectPass(pass._id, 1)}
                      className="p-2 rounded-full bg-gradient-to-r from-green-500 to-teal-500 shadow-lg hover:from-green-600 hover:to-teal-600 transition-all"
                    >
                      <FaPlus className="text-white text-lg" />
                    </button>
                  </div>
                )}
              </div>

              {disabled && (
                <div className="absolute inset-0 flex flex-col items-center justify-center backdrop-blur-sm bg-black bg-opacity-60 rounded-lg">
                  <p className="text-lg font-bold text-gradient bg-gradient-to-r from-pink-500 via-purple-500 to-blue-500 bg-clip-text text-transparent animate-pulse">
                    {deadlinePassed ? 'Sale Ended' : 'Sold Out'}
                  </p>
                  <button
                    onClick={() =>
                      navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')
                    }
                    className="mt-4 w-3/4 text-center py-2 px-4 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 shadow-md hover:from-pink-700 hover:to-purple-700 transition-all duration-300"
                  >
                    {t.earnCoins}
                  </button>
                </div>
              )}
            </div>
          );
        })}
      </div>

      {/* Checkout Button */}
      <button
        onClick={handleCheckout}
        className="mt-6 w-full bg-gradient-to-r from-blue-500 to-purple-500 text-white py-3 rounded-lg shadow-md hover:from-blue-600 hover:to-purple-600 transition-all"
      >
        {t.buyTickets}
      </button>
    </div>
  );
};

// Wrap with Stripe Elements for context
const PassesCheckout = (props: PassesSectionProps) => (
  <Elements stripe={stripePromise}>
    <PassesSection {...props} />
  </Elements>
);

export default PassesCheckout;
