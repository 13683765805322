import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaPlay, FaSpinner, FaStop, FaHeart, FaTrophy } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const DJBattle: React.FC = () => {
  const { battleId } = useParams<{ battleId: string }>();
  const { currentToken } = useAuth();
  const [battle, setBattle] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<any>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const translations = {
    english: {
      back: 'Back',
      startBattle: 'Start Battle',
      finishBattle: 'Finish Battle',
      vote: 'Vote',
      votes: 'Votes',
      participants: 'Participants',
      notStarted: 'Not started yet.',
      status: 'Status',
      loading: 'Loading battle details...',
      notFound: 'Battle not found.',
      successVote: 'Vote cast successfully!',
      successStart: 'Battle started successfully!',
      successFinish: 'Battle finished successfully!',
      error: 'Error',
      fetchError: 'Failed to fetch battle details.',
      voteError: 'Failed to cast vote.',
      startError: 'Failed to start battle.',
      finishError: 'Failed to finish battle.',
      winner: 'Winner',
    },
  };

  const t = translations.english;

  const fetchBattleDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/battle/${battleId}`, {
        headers: { Authorization: `Bearer ${currentToken}` },
      });

      if (response.ok) {
        const data = await response.json();
        setBattle(data.battle);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      console.error(t.fetchError, error);
      Swal.fire(t.error, t.fetchError, 'error');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`${APIBase}/client/user`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${currentToken}`, 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire(t.error, t.fetchError, 'error');
    }
  };

  const handleVote = async (participantId: string) => {
    try {
      const response = await fetch(`${APIBase}/client/battle/${battleId}/vote`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ participant: participantId }),
      });

      if (response.ok) {
        Swal.fire('Success', t.successVote, 'success');
        fetchBattleDetails();
      } else {
        Swal.fire(t.error, t.voteError, 'error');
      }
    } catch (error) {
      console.error(t.voteError, error);
      Swal.fire(t.error, t.voteError, 'error');
    }
  };

  const handleStartBattle = async () => {
    try {
      const response = await fetch(`${APIBase}/client/battle/${battleId}/start`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${currentToken}` },
      });

      if (response.ok) {
        Swal.fire('Success', t.successStart, 'success');
        fetchBattleDetails();
      } else {
        Swal.fire(t.error, t.startError, 'error');
      }
    } catch (error) {
      console.error(t.startError, error);
      Swal.fire(t.error, t.startError, 'error');
    }
  };

  const handleFinishBattle = async () => {
    setSubmitting(true);
    try {
      const response = await fetch(`${APIBase}/client/battle/${battleId}/finish`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${currentToken}` },
      });
  
      if (response.ok) {
        const data = await response.json();
        const winnerId = data.battle.winner; // This is the winner's ID
  
        // Look up the winner's name from the participants list
        const winner = battle?.participants.find((participant: any) => participant._id === winnerId);
  
        if (winner) {
          Swal.fire(t.successFinish, `${t.winner}: ${winner.name}`, 'success');
        } else {
          Swal.fire(t.successFinish, `${t.winner}: Unknown`, 'success');
        }
  
        fetchBattleDetails();
      } else {
        Swal.fire(t.error, t.finishError, 'error');
      }
    } catch (error) {
      console.error(t.finishError, error);
      Swal.fire(t.error, t.finishError, 'error');
    } finally {
      setSubmitting(false);
    }
  };
  

  useEffect(() => {
    fetchBattleDetails();
    fetchUserProfile();
  }, [battleId]);

  const calculateVotes = (participantId: string) => {
    return battle?.votes.filter((vote: any) => vote.participant === participantId).length || 0;
  };

  const isAdmin = userData?.role === 'admin';
  const winnerId = battle?.winner;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <FaSpinner className="animate-spin text-4xl text-gray-400" />
        <p className="ml-4">{t.loading}</p>
      </div>
    );
  }

  if (!battle) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-900 text-white">
        <p>{t.notFound}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white p-6 pt-24 pb-32">
      <div className="flex items-center justify-between mb-6">
        <button onClick={() => navigate(-1)} className="text-gray-300 hover:text-white flex items-center">
          <FaArrowLeft className="mr-2" />
        </button>
        {isAdmin && (
          <div className="flex space-x-4">
            {battle.status === 'pending' && (
              <button
                onClick={handleStartBattle}
                className="bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 hover:to-teal-600 text-white px-4 py-2 rounded-full shadow-md flex items-center"
              >
                <FaPlay className="mr-2" /> {t.startBattle}
              </button>
            )}
            {battle.status === 'active' && (
              <button
                onClick={handleFinishBattle}
                disabled={submitting}
                className={`${
                  submitting ? 'bg-gray-500 cursor-not-allowed' : 'bg-gradient-to-r from-red-500 to-pink-500'
                } hover:from-red-600 hover:to-pink-600 text-white px-4 py-2 rounded-full shadow-md flex items-center`}
              >
                {submitting ? (
                  <FaSpinner className="animate-spin mr-2" />
                ) : (
                  <FaStop className="mr-2" />
                )}
                {t.finishBattle}
              </button>
            )}
          </div>
        )}
      </div>
      <h1 className="text-4xl font-extrabold tracking-tight mb-6 text-center">{battle.name}</h1>
      <p className="text-xl text-center mb-4">
        {t.status}: <span className="font-bold">{battle.status === 'pending' ? t.notStarted : battle.status}</span>
      </p>
      <h2 className="text-2xl font-semibold mb-4">{t.participants}</h2>
      <ul className="space-y-4">
        {battle.participants.map((participant: any) => (
          <li
            key={participant._id}
            className={`p-6 rounded-lg shadow-md flex justify-between items-center ${
                (participant._id === winnerId && battle.status === 'finished')
                ? 'bg-gradient-to-r from-yellow-300 via-yellow-400 to-yellow-500 text-black'
                : 'bg-gray-800'
            }`}
          >
            <div className="relative">
              <img
                src={participant.media?.[0] || 'https://via.placeholder.com/50'}
                alt={participant.name}
                className="w-12 h-12 rounded-full object-cover"
              />
              {(participant._id === winnerId && battle.status === 'finished') && (
                <div className="absolute top-0 right-0 -mt-2 -mr-2 bg-yellow-500 p-1 rounded-full">
                  <FaTrophy className="text-white text-sm" />
                </div>
              )}
            </div>
            <div className="flex-grow pl-4">
              <p className="text-lg font-bold">{participant.name}</p>
            </div>
            <div
              className={`relative flex items-center justify-center w-12 h-12 ${
                (participant._id === winnerId && battle.status === 'finished')
                  ? 'bg-gradient-to-r from-yellow-400 to-yellow-500'
                  : 'bg-gradient-to-r from-pink-500 to-red-500'
              } hover:from-pink-600 hover:to-red-600 rounded-full shadow-md`}
            >
              <FaHeart className="text-white text-xl" />
              <span className="absolute inset-0 flex items-center justify-center text-xs font-bold text-black">
                {calculateVotes(participant._id)}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DJBattle;
