// hooks/useFetchPromotions.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchPromotions = (currentToken: string, venueId: string) => {
  const [promotions, setPromotions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (venueId) {
      const fetchPromotions = async () => {
        try {
          const response = await fetch(`${APIBase}/client/menu-item?venue=${venueId}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${currentToken}` },
          });

          if (response.ok) {
            const data = await response.json();
            const discountedItems = data.filter((item: any) => item.discounts?.length > 0);
            setPromotions(discountedItems);
          } else {
            throw new Error('Failed to fetch promotions');
          }
        } catch (error) {
          Swal.fire('Error', 'Failed to fetch promotions.', 'error');
        } finally {
          setLoading(false);
        }
      };

      fetchPromotions();
    }
  }, [currentToken, venueId]);

  return { promotions, loading };
};

export default useFetchPromotions;
