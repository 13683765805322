// src/hooks/useAddVideo.ts
import { useMutation, useQueryClient } from 'react-query';
import { APIBase } from 'api/hosts';

interface AddVideoPayload {
  videoId: string;
  playlistId: string;
  token: string;
}

const addVideo = async ({ videoId, playlistId, token }: AddVideoPayload) => {
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}/addVideo`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ videoId, playlistId }),
  });
  if (!response.ok) {
    const errorData = await response.json(); // parse the JSON error response
    throw errorData; // throw the parsed error
  }
  const data = await response.json();
  return data;
};


export const useAddVideo = (playlistId: string, token: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ videoId }: { videoId: string }) => addVideo({ videoId, playlistId, token }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['playlist', playlistId]);
      },
    }
  );
};
