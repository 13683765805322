// src/components/EventEditor/translations.ts
const translations = {
    english: {
      back: 'Back',
      editEvent: 'Edit Event',
      title: 'Title',
      description: 'Description',
      date: 'Date',
      time: 'Time',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      imageURL: 'Image URL',
      uploadImage: 'Upload Image',
      facebookLink: 'Facebook Link',
      ticketLink: 'Ticket Link',
      genres: 'Genres',
      add: 'Add',
      dressCode: 'Dress Code',
      selectDressCode: 'Select Dress Code',
      noDressCode: 'No Dress Code',
      casual: 'Casual',
      smartCasual: 'Smart Casual',
      business: 'Business Attire',
      formal: 'Formal',
      blackTie: 'Black Tie',
      cocktail: 'Cocktail Attire',
      costume: 'Costume',
      beach: 'Beachwear',
      festival: 'Festival',
      themed: 'Themed',
      ageRestriction: 'Age Restriction',
      allAges: 'All Ages',
      verified: 'Verified',
      network: 'Network',
      updateEvent: 'Update Event',
      activities: 'Activities',
      activityName: 'Activity Name',
      activityCoins: 'Activity Coins',
      addActivity: 'Add Activity',
      editActivity: 'Edit Activity',
      deleteActivity: 'Delete Activity',
      saveActivity: 'Save Activity',
      passes: 'Ticket Passes',
      passName: 'Pass Name',
      passDescription: 'Pass Description',
      passPrice: 'Price',
      passCurrency: 'Currency',
      passMaxQuantity: 'Max Quantity',
      addPass: 'Add Pass',
      editPass: 'Edit Pass',
      deletePass: 'Delete Pass',
      savePass: 'Save Pass',
      updateSuccess: 'Event updated successfully! 🎉',
      activitySuccess: 'Activity updated successfully!',
      deleteSuccess: 'Activity deleted successfully!',
      success: 'Success',
      error: 'Error',
      fetchError: 'Failed to fetch event. Please try again later.',
      updateError: 'Failed to update event. Please try again later.',
      uploadError: 'Image upload failed.',
      party: 'Party',
      karaoke: 'Karaoke',
      selectClub: 'Select a club...',
      selectType: 'Select event type...',
      djs: 'DJs',
      type: 'Type',
      cancel: 'Cancel',
      passFillError: 'Please fill all pass fields.',
      addFirstActivity: 'Add your first activity',
    },
    // Add Spanish and Polish translations as needed.
  };
  
  export default translations;
  