const translations = {
    english: {
      back: 'Back',
      edit: 'Edit Event',
      error: 'Error',
      fetchError: 'Error fetching event',
      retryLater: 'Please try again later 😞',
      loading: 'Loading...',
      buyTickets: 'Buy Tickets',
      qrCodeTitle: 'Event QR Code',
      activities: 'Activities',
      activityCoins: 'Coins',
    },
    spanish: {
      back: 'Atrás',
      edit: 'Editar Evento',
      error: 'Error',
      fetchError: 'Error al obtener el evento',
      retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
      loading: 'Cargando...',
      buyTickets: 'Comprar Entradas',
      qrCodeTitle: 'Código QR del Evento',
      activities: 'Actividades',
      activityCoins: 'Puntos',
    },
    polish: {
      back: 'Powrót',
      edit: 'Edytuj Wydarzenie',
      error: 'Błąd',
      fetchError: 'Błąd pobierania wydarzenia',
      retryLater: 'Spróbuj ponownie później 😞',
      loading: 'Ładowanie...',
      buyTickets: 'Kup Bilety',
      qrCodeTitle: 'Kod QR wydarzenia',
      activities: 'Zajęcia',
      activityCoins: 'Punkty',
    },
  };
  
  export default translations;