// src/components/PlaylistDetail/AddVideoForm.tsx
import React from 'react';
import { FaClipboard, FaPlus, FaSpinner } from 'react-icons/fa';

interface AddVideoFormProps {
  newVideo: { videoId: string };
  setNewVideo: (value: { videoId: string }) => void;
  handleAddVideo: () => void;
  addVideoLoading: boolean;
  handlePasteClipboard: () => void;
  t: any;
}

const AddVideoForm: React.FC<AddVideoFormProps> = ({
  newVideo,
  setNewVideo,
  handleAddVideo,
  addVideoLoading,
  handlePasteClipboard,
  t,
}) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-md space-y-4 hidden lg:flex items-start justify-start flex-col">
      <h3 className="text-xl font-semibold">{t.addVideo}</h3>
      <div className="w-full flex flex-col space-y-2">
        <div className="relative w-full">
          <input
            type="text"
            placeholder="Video URL"
            value={newVideo.videoId}
            onChange={(e) => setNewVideo({ videoId: e.target.value })}
            className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 pr-12"
          />
          <button
            onClick={handlePasteClipboard}
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
            title="Paste YouTube Link"
          >
            <FaClipboard size={20} />
          </button>
        </div>

        <button
          onClick={handleAddVideo}
          className={`w-full p-2 rounded-md flex items-center justify-center ${
            addVideoLoading ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-500'
          } text-white transition`}
          disabled={addVideoLoading}
        >
          {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
          {addVideoLoading ? t.adding : t.add}
        </button>
      </div>
    </div>
  );
};

export default AddVideoForm;
