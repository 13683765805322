import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import React from 'react';
import { FaFacebook, FaGoogle, FaShareAlt, FaTicketAlt } from 'react-icons/fa';

interface EventInfoProps {
  event: any;
  venue: any;
  t: any;
  handleShare: () => void;
}

const EventInfo: React.FC<EventInfoProps> = ({ event, venue, t, handleShare }) => {
  return (
    <div className="flex flex-col space-y-6 md:h-[calc(100vh-220px)] overflow-y-scroll pt-6 pb-32">
      {/* Reduced the title size */}
      <h2 className="text-2xl md:text-3xl font-semibold text-white">{event.title}</h2>
      <img src={event.imageURL} alt={event.title} className="w-full h-64 md:h-80 object-cover rounded-lg shadow-2xl" />

      {/* Genre & Venue Type Tags with micro interactions */}
      <div className="flex flex-wrap gap-2">
        {event.genres.map((genre: string, index: number) => (
          <Chip
            key={index}
            label={genre}
            className="bg-gradient-to-r from-teal-500 to-blue-600 text-white px-2 py-1 rounded-full shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
            style={{ color: 'white' }}
          />
        ))}
        {venue?.venueTypes?.map((type: string, index: number) => (
          <Chip
            key={index}
            label={type}
            className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-2 py-1 rounded-full shadow-md transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
            style={{ color: 'white' }}
          />
        ))}
      </div>

      {/* Description with preserved whitespace */}
      <p className="text-gray-300 whitespace-pre-wrap leading-relaxed">{event.description}</p>

      {/* Event Details */}
      <p className="text-gray-300">
        <strong>{t.date}:</strong> {moment(event.date).format('MMMM Do, YYYY')}
      </p>
      <p className="text-gray-300">
        <strong>{t.time}:</strong> {event.time}
      </p>
      <p className="text-gray-300">
        <strong>{t.location}:</strong> {venue.name}
      </p>
      <p className="text-gray-300">
        <strong>{t.address}:</strong> {venue.address}
      </p>

      {/* Ticket Link */}
      {event.ticketLink && (
        <a
          href={event.ticketLink}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-4 w-full text-center text-white bg-gradient-to-r from-green-500 to-green-600 px-4 py-2 rounded-lg flex items-center justify-center shadow-xl hover:from-green-600 hover:to-green-700 transition-all"
        >
          <FaTicketAlt className="mr-2" /> {t.buyTickets}
        </a>
      )}

      {/* Share Links */}
      <div className="flex gap-4">
        <a
          href={`https://www.google.com/maps/dir/?api=1&destination=${venue.lat},${venue.lng}`}
          target="_blank"
          className="text-white bg-gradient-to-r from-red-500 to-red-700 px-4 py-4 rounded-lg shadow-lg hover:from-red-600 hover:to-red-800 transition-all"
        >
          <FaGoogle className="text-2xl" />
        </a>
        <a
          href={event.facebookLink}
          target="_blank"
          className="text-white bg-gradient-to-r from-blue-500 to-blue-700 px-4 py-4 rounded-lg shadow-lg hover:from-blue-600 hover:to-blue-800 transition-all"
        >
          <FaFacebook className="text-2xl" />
        </a>
        <div
          onClick={handleShare}
          className="text-white bg-gradient-to-r from-blue-700 to-blue-900 px-4 py-4 rounded-lg shadow-lg hover:from-blue-800 hover:to-blue-1000 transition-all cursor-pointer"
        >
          <FaShareAlt className="text-2xl" />
        </div>
      </div>
    </div>
  );
};

export default EventInfo;
