import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import { useAuth } from 'Contexts/AuthContext';
import React, { useContext, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    back: 'Back',
    createCountry: 'Create Country',
    countryName: 'Country Name',
    countryCode: 'Country Code',
    success: 'Success',
    countryCreated: 'Country created successfully! 🎉',
    error: 'Error',
    submitError: 'Please try again later 😞',
  },
  spanish: {
    back: 'Regresar',
    createCountry: 'Crear País',
    countryName: 'Nombre del País',
    countryCode: 'Código del País',
    success: 'Éxito',
    countryCreated: '¡País creado con éxito! 🎉',
    error: 'Error',
    submitError: 'Inténtalo de nuevo más tarde 😞',
  },
  polish: {
    back: 'Powrót',
    createCountry: 'Utwórz Kraj',
    countryName: 'Nazwa Kraju',
    countryCode: 'Kod Kraju',
    success: 'Sukces',
    countryCreated: 'Kraj utworzony pomyślnie! 🎉',
    error: 'Błąd',
    submitError: 'Spróbuj ponownie później 😞',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CreateCountry: React.FC = () => {
  const [countryData, setCountryData] = useState({
    name: '',
    code: '',
  });
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCountryData({ ...countryData, [name]: value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(countryData),
    };

    await fetch(`${APIBase}/client/country/country`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.countryCreated,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/countries');
        } else {
          Swal.fire({
            title: t.error,
            text: t.submitError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.submitError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.createCountry}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.countryName}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={countryData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="code">
            {t.countryCode}
          </label>
          <input
            type="text"
            id="code"
            name="code"
            value={countryData.code}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.createCountry}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateCountry;
