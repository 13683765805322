// src/hooks/useEvents.ts
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

export const useEvents = (currentToken: string) => {
  const [events, setEvents] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchEvents = async () => {
    setLoading(true);
    const requestOptions: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/event`, requestOptions);
      if (!response.ok) {
        throw new Error('Error fetching events');
      }
      const responseJson = await response.json();
      setEvents(responseJson);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch events. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (currentToken) {
      fetchEvents();
    }
  }, [currentToken]);

  return { events, loading, refetch: fetchEvents };
};
