import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { StageSpinner } from 'react-spinners-kit';
import { Ticket } from '../hooks/useEventTickets';

interface TicketsListProps {
  tickets: Ticket[];
  loading: boolean;
  error: string | null;
  onMarkAsUsed: (ticketId: string) => void;
}

const TicketsList: React.FC<TicketsListProps> = ({ tickets, loading, error, onMarkAsUsed }) => {
  if (loading) {
    return <StageSpinner size={30} color="#ffffff" />;
  }

  if (error) {
    return <p className="text-gray-400">{error}</p>;
  }

  if (tickets.length === 0) {
    return <p className="text-gray-400">No tickets sold yet.</p>;
  }

  return (
    <ul className="divide-y divide-gray-700">
      {tickets.map((ticket) => (
        <li
          key={ticket._id}
          className={`py-4 flex flex-col bg-gradient-to-r from-gray-700 to-gray-900 p-4 rounded-lg shadow-lg mb-2 relative ${
            ticket.status === 'used' ? 'opacity-50' : ''
          }`}
        >
          <div className="flex justify-between items-center">
            <div>
              <h4 className="text-lg font-bold text-white">{ticket.pass?.name || 'Unknown Pass'}</h4>
              <p className="text-sm text-gray-300">
                {ticket.quantity} {ticket.quantity > 1 ? 'tickets' : 'ticket'} – {ticket.status}
              </p>
              <p className="text-xs text-gray-400 mt-1">{new Date(ticket.event.date).toLocaleDateString()}</p>
            </div>
            <div className="bg-gradient-to-r from-teal-500 to-blue-600 text-white px-2 py-1 rounded-full text-xs font-bold">
              x{ticket.quantity}
            </div>
          </div>
          <div className="mt-2">
            <p className="text-sm text-gray-300">
              Purchased by: {ticket.user.name} ({ticket.user.username})
            </p>
            <p className="text-sm text-gray-300">Email: {ticket.user.email}</p>
          </div>
          {ticket.status === 'paid' && (
            <button
              onClick={() => onMarkAsUsed(ticket._id)}
              className="mt-4 self-end bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white px-3 py-1 rounded-full flex items-center transition-all"
            >
              <FaCheck className="mr-2" /> Mark as Used
            </button>
          )}
          {ticket.status === 'pending' && <div className="mt-4 self-end text-sm text-yellow-400">Payment Pending</div>}
          {ticket.status === 'used' && (
            <div className="absolute inset-0 flex justify-center items-center bg-black bg-opacity-60">
              <span className="text-2xl text-white font-bold">USED</span>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};

export default TicketsList;
