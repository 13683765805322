import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useState } from 'react';
import { FaChevronLeft, FaEdit } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import useEventTickets from './hooks/useEventTickets';
import useViewEvent from './hooks/useViewEvent';

// Import subcomponents
import EventDetails from './components/EventDetails';
import EventHeader from './components/EventHeader';
import EventSidebar from './components/EventSidebar';
import TicketsList from './components/TicketsList';
import translations from './translations';
import { APIBase } from 'api/hosts';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

const ViewEvent: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const navigate = useNavigate();
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as keyof typeof translations];

  const { event, venue, activities, loading, error } = useViewEvent(
    eventId as string,
    currentToken || '',
    t
  );

  const { tickets, loading: ticketsLoading, error: ticketsError } = useEventTickets(
    eventId as string,
    currentToken || ''
  );

  const [activeTab, setActiveTab] = useState<'details' | 'tickets'>('details');

  // State for the confirmation modal
  const [ticketToMarkUsed, setTicketToMarkUsed] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleEditClick = () => {
    navigate(`/app/admin/edit-event/${event._id}`);
  };

  // Open the confirmation modal and set the ticket ID to mark as used
  const openMarkUsedModal = (ticketId: string) => {
    setTicketToMarkUsed(ticketId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setTicketToMarkUsed(null);
    setIsModalOpen(false);
  };

  // Function to mark the ticket as used by calling the validation endpoint
  const markTicketAsUsed = async () => {
    if (!ticketToMarkUsed) return;
    try {
      const response = await fetch(`${APIBase}/client/ticket/validate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentToken}`,
        },
        body: JSON.stringify({ ticketId: ticketToMarkUsed }),
      });
      const data = await response.json();
      if (response.ok) {
        Swal.fire('Success', data.message, 'success');
        // Refresh the page or update the tickets state to reflect the change
        window.location.reload();
      } else {
        Swal.fire('Error', data.message, 'error');
      }
    } catch (error) {
      console.error('Error marking ticket as used:', error);
      Swal.fire('Error', 'Failed to mark ticket as used', 'error');
    } finally {
      closeModal();
    }
  };

  // Pass the modal callback to TicketsList
  const handleMarkAsUsed = (ticketId: string) => {
    openMarkUsedModal(ticketId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-gray-900 via-black to-gray-900">
        <StageSpinner size={50} color="#86ff0dc4" />
      </div>
    );
  }

  if (error) {
    return <div className="text-white text-center mt-10">{t.fetchError}</div>;
  }

  if (!event || !venue) {
    return <div className="text-white text-center mt-10">Event or Venue not found</div>;
  }

  return (
    <div className="w-full max-w-4xl mx-auto px-4 py-6 bg-gradient-to-b from-gray-900 via-black to-gray-900 rounded-lg shadow-xl overflow-y-auto pb-12">
      {/* Header: Back and Edit Buttons */}
      <div className="flex justify-between items-center mb-4 mt-24">
        <button
          onClick={handleBackClick}
          className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white p-3 rounded-full shadow-md transition-all"
        >
          <FaChevronLeft className="text-xl" />
        </button>
        <button
          onClick={handleEditClick}
          className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white p-3 rounded-full shadow-md transition-all"
        >
          <FaEdit className="text-xl" />
        </button>
      </div>

      {/* Header: Title */}
      <EventHeader event={event} t={t} />

      {/* Tab Navigation */}
      <div className="flex justify-center mb-6 space-x-4">
        <button
          onClick={() => setActiveTab('details')}
          className={`px-6 py-3 rounded-t-lg text-xl font-semibold transition-all ${
            activeTab === 'details'
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
              : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
          }`}
        >
          Details
        </button>
        <button
          onClick={() => setActiveTab('tickets')}
          className={`px-6 py-3 rounded-t-lg text-xl font-semibold transition-all ${
            activeTab === 'tickets'
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white'
              : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
          }`}
        >
          Tickets Sold
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === 'details' ? (
        <div className="bg-black bg-opacity-80 p-6 rounded-b-lg shadow-2xl pb-20 mb-8">
          <div className="lg:flex lg:space-x-6">
            <EventDetails event={event} venue={venue} t={t} />
            <EventSidebar eventId={eventId as string} venue={venue} activities={activities} t={t} />
          </div>
        </div>
      ) : (
        <div className="bg-black bg-opacity-80 p-6 rounded-b-lg shadow-2xl min-h-screen">
          <TicketsList
            tickets={tickets}
            loading={ticketsLoading}
            error={ticketsError}
            onMarkAsUsed={handleMarkAsUsed}
          />
        </div>
      )}

      {/* Confirmation Modal for Marking Ticket as Used */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="max-w-md mx-auto bg-gray-900 p-6 rounded-lg shadow-xl outline-none"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center"
      >
        <h2 className="text-2xl font-bold text-white mb-4">Mark Ticket as Used</h2>
        <p className="text-gray-300 mb-6">Are you sure you want to mark this ticket as used?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-500 transition-all"
          >
            Cancel
          </button>
          <button
            onClick={markTicketAsUsed}
            className="px-4 py-2 bg-gradient-to-r from-green-500 to-green-600 text-white rounded hover:from-green-600 hover:to-green-700 transition-all"
          >
            Confirm
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ViewEvent;
