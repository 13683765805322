// src/components/PlaylistDetail/ParticipantsModal.tsx
import React from 'react';
import { FaHeart, FaTrophy } from 'react-icons/fa';

interface ParticipantsModalProps {
  updatedParticipants: any[];
  isAdmin: boolean;
  freezeVotes: () => void;
  sendLikeInteraction: (recipientId: string) => void;
  t: any;
  closeModal: () => void;
}

const ParticipantsModal: React.FC<ParticipantsModalProps> = ({
  updatedParticipants,
  isAdmin,
  freezeVotes,
  sendLikeInteraction,
  t,
  closeModal,
}) => {
  return (
    <div className="fixed inset-0 bg-gradient-to-bl from-gray-900 via-gray-800 to-black bg-opacity-90 flex items-center justify-center z-50">
      <div className="bg-gray-900/70 rounded-xl shadow-2xl max-w-md w-90vw max-h-[70vh] overflow-auto relative backdrop-blur-md">
        {/* Header */}
        <div className="flex justify-between items-center p-6 border-b border-gray-700">
          <h3 className="text-lg font-bold text-gray-200 uppercase tracking-wide">Leaderboard</h3>
          <button
            onClick={closeModal}
            className="w-8 h-8 flex items-center justify-center rounded-full text-gray-300 hover:bg-gray-600 transition"
          >
            ✕
          </button>
        </div>
        {/* Contest Description */}
        <div className="mt-6 p-4 bg-gray-700 rounded-lg shadow-md text-white text-sm">{t.contestDescription}</div>
        <div className="p-6 space-y-4">
          {isAdmin && updatedParticipants.length > 0 && updatedParticipants[0] && (
            <div className="text-center mt-6">
              <button
                className="flex items-center justify-center px-8 py-3 bg-gradient-to-r from-red-600 via-purple-600 to-blue-600 text-white font-semibold rounded-xl shadow-md hover:from-red-500 hover:via-purple-500 hover:to-blue-500 transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
                onClick={freezeVotes}
              >
                <FaTrophy className="mr-3 text-yellow-300 text-2xl animate-pulse" />
                <span className="text-lg">Freeze Votes and Select Winner</span>
              </button>
            </div>
          )}

          {updatedParticipants
            .sort((a: any, b: any) => (b.likes?.length || 0) - (a.likes?.length || 0))
            .map((participant: any, index: number) => {
              const isTopThree = index < 3;
              return (
                <div
                  key={participant._id}
                  className={`flex items-center space-x-4 p-4 rounded-lg shadow-lg transition-all duration-300 ${
                    isTopThree
                      ? index === 0
                        ? 'bg-gradient-to-r from-purple-400 via-blue-400 to-teal-300 text-gray-900'
                        : index === 1
                        ? 'bg-gradient-to-r from-blue-300 via-teal-200 to-gray-300 text-gray-900'
                        : 'bg-gradient-to-r from-teal-200 via-gray-300 to-gold-300 text-gray-900'
                      : 'bg-gray-800/60 text-gray-300'
                  } hover:scale-105`}
                  style={isTopThree ? { boxShadow: '0 0 15px rgba(255, 255, 255, 0.2)' } : {}}
                >
                  <div
                    className={`w-12 h-12 shrink-0 flex items-center justify-center rounded-full text-xl font-bold ${
                      isTopThree ? 'bg-white text-gray-900 shadow-lg' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div className="text-sm font-medium truncate w-full">{participant.username || t.unknown}</div>
                  <div
                    className={`relative flex items-center justify-center ml-auto ${
                      participant.canVote ? '' : 'cursor-not-allowed opacity-50'
                    }`}
                    onClick={() => participant.canVote && sendLikeInteraction(participant._id)}
                    title={participant.canVote ? 'Like' : 'Voting disabled (winner selected)'}
                  >
                    <FaHeart
                      className={`text-2xl w-10 h-10 ${participant.hasLiked ? 'text-pink-400' : 'text-gray-400'}`}
                    />
                    <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold">
                      {participant.likes?.length || 0}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ParticipantsModal;
