// hooks/useFetchPlaylists.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchPlaylists = (currentToken: string, roomId: string) => {
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (roomId) {
      const fetchPlaylists = async () => {
        try {
          const response = await fetch(`${APIBase}/client/playlist/byRoomId/${roomId}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${currentToken}` },
          });

          if (response.ok) {
            const data = await response.json();
            setPlaylists(data.playlists);
          } else {
            throw new Error('Failed to fetch playlists');
          }
        } catch (error) {
          Swal.fire('Error', 'Failed to fetch playlists.', 'error');
        } finally {
          setLoading(false);
        }
      };

      fetchPlaylists();
    }
  }, [currentToken, roomId]);

  return { playlists, loading };
};

export default useFetchPlaylists;
