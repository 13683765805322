import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    details: 'Menu Item Details',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    currency: 'Currency',
    back: 'Back to Menu',
    error: 'Error',
    fetchError: 'Failed to load menu item details. Please try again later.',
  },
  spanish: {
    details: 'Detalles del Elemento del Menú',
    name: 'Nombre',
    description: 'Descripción',
    price: 'Precio',
    currency: 'Moneda',
    back: 'Volver al Menú',
    error: 'Error',
    fetchError: 'No se pudieron cargar los detalles del elemento del menú. Inténtelo de nuevo más tarde.',
  },
  polish: {
    details: 'Szczegóły Pozycji Menu',
    name: 'Nazwa',
    description: 'Opis',
    price: 'Cena',
    currency: 'Waluta',
    back: 'Wróć do Menu',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować szczegółów pozycji menu. Spróbuj ponownie później.',
  },
};

const MenuItemDetail: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const { menuItemId } = useParams<{ menuItemId: string }>();
  const navigate = useNavigate();
  const [menuItem, setMenuItem] = useState<any | null>(null);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchMenuItemDetails();
  }, [menuItemId, currentToken]);

  const fetchMenuItemDetails = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item/${menuItemId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setMenuItem(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      {menuItem ? (
        <div className="max-w-3xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6">{menuItem.name}</h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-lg mb-4">
              <strong>{t.description}:</strong> {menuItem.description}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.price}:</strong> {menuItem.price} {menuItem.currency}
            </p>
            {menuItem.imageURL && (
              <img src={menuItem.imageURL} alt={menuItem.name} className="w-full h-64 object-cover rounded-md mb-6" />
            )}
          </div>
        </div>
      ) : (
        <p>{t.fetchError}</p>
      )}
    </div>
  );
};

export default MenuItemDetail;
