import { faArrowLeft, faCheck, faPaperPlane, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
// @ts-ignore
import 'flatpickr/dist/themes/material_green.css'; // You can choose any theme or create your own
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const DedicationsScreen: React.FC = () => {
  const { language } = useLanguage();
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [startTime, setStartTime] = useState<Date>(new Date(new Date().getTime() + 15 * 60 * 1000)); // 15 minutes in the future
  const [duration, setDuration] = useState<number>(60);
  const [endTime, setEndTime] = useState<Date>(new Date(Date.now() + 60 * 1000));

  const navigate = useNavigate(); // For navigating back
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>('future'); // Default tab is "Happening Now"
  const { currentToken } = useAuth();

  const translations = {
    english: {
      title: 'Dedications',
      addMessage: 'Submit New Dedication',
      loading: 'Loading...',
      noMessages: 'No messages found.',
      fieldsRequired: 'Message cannot be empty.',
      submit: 'Submit',
      success: 'Dedication submitted!',
      approved: 'Approved',
      notApproved: 'Not Approved',
      past: 'Past',
      now: 'Happening Now',
      future: 'Upcoming',
    },
    spanish: {
      title: 'Dedicatorias',
      addMessage: 'Enviar nueva dedicatoria',
      loading: 'Cargando...',
      noMessages: 'No se encontraron mensajes.',
      fieldsRequired: 'El mensaje no puede estar vacío.',
      submit: 'Enviar',
      success: '¡Dedicatoria enviada con éxito!',
      approved: 'Aprobada',
      notApproved: 'No Aprobada',
      past: 'Pasado',
      now: 'En Curso',
      future: 'Próximo',
    },
    polish: {
      title: 'Dedykacje',
      addMessage: 'Prześlij nową dedykację',
      loading: 'Ładowanie...',
      noMessages: 'Brak wiadomości.',
      fieldsRequired: 'Wiadomość nie może być pusta.',
      submit: 'Prześlij',
      success: 'Dedykacja została pomyślnie wysłana!',
      approved: 'Zatwierdzono',
      notApproved: 'Nie Zatwierdzono',
      past: 'Przeszłość',
      now: 'W trakcie',
      future: 'Nadchodzące',
    },
  };

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchAllMessages();
  }, []);

  const fetchAllMessages = async () => {
    try {
      const response = await fetch(`${APIBase}/client/message/userMessages`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.data);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching all messages:', error);
    }
  };

  const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = parseInt(e.target.value);
    setDuration(newDuration);

    // Calculate the new end time based on the startTime and the selected duration
    const calculatedEndTime = new Date(startTime.getTime() + newDuration * 1000);
    setEndTime(calculatedEndTime); // Set the end time
  };

  const handleSubmitMessage = async () => {
    // Convert startTime and endTime to UTC timestamps (milliseconds since Unix epoch)
    const startTimestamp = startTime.getTime();
    const endTimestamp = endTime.getTime();

    // Validate if end time is after start time
    if (endTimestamp <= startTimestamp) {
      setErrorMessage('End time cannot be before start time.');
      return;
    }

    try {
      const response = await fetch(`${APIBase}/client/message/submit`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: newMessage,
          startTime: startTime.toISOString(), // Ensure start time is in ISO format (UTC)
          endTime: endTime.toISOString(), // Ensure end time is in ISO format (UTC)
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to submit message');
      }
      Swal.fire({
        title: t.success,
        icon: 'success',
        text: 'Your message has been submitted! Please contact the party host for approval.',
        confirmButtonText: 'Got it',
      });

      setNewMessage('');
      setStartTime(new Date()); // Reset start time
      setEndTime(new Date(Date.now() + duration * 1000)); // Reset end time based on new duration
      setIsModalOpen(false); // Close the modal after submission
      fetchAllMessages(); // Refresh the list of messages
    } catch (error) {
      console.error('Error submitting message:', error);
      setErrorMessage('Failed to submit message.');
    }
  };

  const getMessageStatus = (startTime: string, endTime: string) => {
    const now = new Date().toISOString();
    const messageStartTime = new Date(startTime).toISOString();
    const messageEndTime = new Date(endTime).toISOString();

    if (messageEndTime < now) return 'past';
    if (messageStartTime <= now && messageEndTime >= now) return 'now';
    return 'future';
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'past':
        return 'bg-gradient-to-r from-gray-600 to-gray-800 text-gray-400';
      case 'now':
        return 'bg-gradient-to-r from-green-500 to-teal-500 text-white';
      case 'future':
        return 'bg-gradient-to-r from-blue-500 to-purple-500 text-white';
      default:
        return '';
    }
  };

  // Function to generate times for the next 8 hours, in 5 minute intervals
  const generateTimeOptions = () => {
    const options = [];
    const now = new Date();
    const currentMinutes = now.getMinutes();
    const nextAvailableMinute = Math.ceil(currentMinutes / 5) * 5; // Round to next 5-minute interval

    let time = new Date(now);
    time.setMinutes(nextAvailableMinute, 0, 0); // Set the next available time (rounded to nearest 5 minutes)

    for (let i = 0; i < 96; i++) {
      // 96 intervals for 8 hours
      if (time.getTime() > now.getTime() + 8 * 60 * 60 * 1000) break; // Stop if time exceeds 8 hours
      options.push(new Date(time));
      time.setMinutes(time.getMinutes() + 5); // Increment by 5 minutes
    }

    return options;
  };

  const getApprovalStyle = (approved: boolean) => {
    return approved ? 'bg-green-500 text-white' : 'bg-red-500 text-white';
  };
  const categorizedMessages = {
    past: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'past')
      .sort((a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()), // Sort past by newest first
    now: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'now')
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort now by closest start time
    future: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'future')
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort future by closest start time
  };

  const calculateDuration = (start: Date, end: Date) => {
    const diffInMs = end.getTime() - start.getTime();
    const diffInSec = Math.floor(diffInMs / 1000);
    const minutes = Math.floor(diffInSec / 60);
    const seconds = diffInSec % 60;
    return `${minutes}m ${seconds}s`;
  };

  const timeOptions = generateTimeOptions();

  const handleTimeSelect = (selectedTime: Date) => {
    setStartTime(selectedTime);

    // Set the end time based on the selected start time and the duration
    const calculatedEndTime = new Date(selectedTime.getTime() + duration * 1000);
    setEndTime(calculatedEndTime);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center justify-start pt-24 pb-24 px-4 sm:px-8">
      <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-200 mb-8">{t.title}</h1>

      {/* Button to open modal */}
      <button
        onClick={() => setIsModalOpen(true)}
        className="absolute top-4 right-4 bg-purple-500 hover:bg-purple-600 text-white p-3 rounded-full shadow-md mt-16 w-10 h-10 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faPlus} className="text-xl" />
      </button>

      {/* Return button */}
      <button
        onClick={() => navigate(-1)}
        className="absolute top-4 left-4 bg-gradient-to-r from-teal-500 to-blue-600 hover:from-teal-600 hover:to-blue-700 text-white p-3 rounded-full shadow-md mt-16 w-10 h-10 flex items-center justify-center"
      >
        <FontAwesomeIcon icon={faArrowLeft} className="text-xl" />
      </button>

      {/* Tabs for Message Categories */}
      <div className="flex justify-center space-x-4 mb-8">
        <button
          onClick={() => setActiveTab('now')}
          className={`text-sm sm:text-lg font-semibold py-2 px-4 rounded-full transition-all duration-300 ${
            activeTab === 'now'
              ? 'bg-gradient-to-r from-green-500 to-teal-500 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-green-500 hover:to-teal-500 hover:text-white'
          }`}
        >
          {t.now}
        </button>
        <button
          onClick={() => setActiveTab('past')}
          className={`text-sm sm:text-lg font-semibold py-2 px-4 rounded-full transition-all duration-300 ${
            activeTab === 'past'
              ? 'bg-gradient-to-r from-gray-600 to-gray-800 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-gray-600 hover:to-gray-800 hover:text-white'
          }`}
        >
          {t.past}
        </button>
        <button
          onClick={() => setActiveTab('future')}
          className={`text-sm sm:text-lg font-semibold py-2 px-4 rounded-full transition-all duration-300 ${
            activeTab === 'future'
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-500 hover:text-white'
          }`}
        >
          {t.future}
        </button>
      </div>

      {/* Messages List */}
      <div className="w-full max-w-4xl mx-auto px-4 overflow-y-auto">
        {/* Conditional Rendering for Active Tab */}

        {/* Now Tab */}
        {activeTab === 'now' && (
          <div className="mb-6">
            {!!categorizedMessages.now.length && <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.now}</h2>}
            {!!categorizedMessages.now.length ? (
              categorizedMessages.now.map((message) => {
                const statusStyle = getStatusStyle('now');
                const approvalStyle = getApprovalStyle(message.approved);

                return (
                  <div key={message._id} className={`p-4 sm:p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-sm sm:text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Duration:</strong>{' '}
                        {calculateDuration(new Date(message.startTime), new Date(message.endTime))}
                      </div>
                      <div
                        className={`text-xs sm:text-sm font-semibold rounded-full px-4 py-1 flex items-center justify-center ${approvalStyle}`}
                      >
                        <FontAwesomeIcon icon={message.approved ? faCheck : faTimesCircle} className="mr-2" />
                        {message.approved ? t.approved : t.notApproved}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <button
                onClick={() => setIsModalOpen(true)}
                className=" bg-purple-500 hover:bg-purple-600 text-white rounded-full shadow-md w-10 h-10 flex items-center justify-center m-auto"
              >
                <FontAwesomeIcon icon={faPlus} className="text-2xl" />
              </button>
            )}
          </div>
        )}

        {/* Past Tab */}
        {activeTab === 'past' && (
          <div className="mb-6">
            {!!categorizedMessages.past.length && (
              <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.past}</h2>
            )}
            {!!categorizedMessages.past.length ? (
              categorizedMessages.past.map((message) => {
                const statusStyle = getStatusStyle('past');
                const approvalStyle = getApprovalStyle(message.approved);

                return (
                  <div key={message._id} className={`p-4 sm:p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-sm sm:text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Duration:</strong>{' '}
                        {calculateDuration(new Date(message.startTime), new Date(message.endTime))}
                      </div>
                      <div
                        className={`text-xs sm:text-sm font-semibold rounded-full px-4 py-1 flex items-center justify-center ${approvalStyle}`}
                      >
                        <FontAwesomeIcon icon={message.approved ? faCheck : faTimesCircle} className="mr-2" />
                        {message.approved ? t.approved : t.notApproved}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <button
                onClick={() => setIsModalOpen(true)}
                className=" bg-purple-500 hover:bg-purple-600 text-white rounded-full shadow-md w-10 h-10 flex items-center justify-center m-auto"
              >
                <FontAwesomeIcon icon={faPlus} className="text-2xl" />
              </button>
            )}
          </div>
        )}

        {/* Future Tab */}
        {activeTab === 'future' && (
          <div className="mb-6">
            {!!categorizedMessages.future.length && (
              <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.future}</h2>
            )}
            {!!categorizedMessages.future.length ? (
              categorizedMessages.future.map((message) => {
                const statusStyle = getStatusStyle('future');
                const approvalStyle = getApprovalStyle(message.approved);

                return (
                  <div key={message._id} className={`p-4 sm:p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-sm sm:text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-xs sm:text-sm text-gray-200">
                        <strong>Duration:</strong>{' '}
                        {calculateDuration(new Date(message.startTime), new Date(message.endTime))}
                      </div>
                      <div
                        className={`text-xs sm:text-sm font-semibold rounded-full px-4 py-1 flex items-center justify-center ${approvalStyle}`}
                      >
                        <FontAwesomeIcon icon={message.approved ? faCheck : faTimesCircle} className="mr-2" />
                        {message.approved ? t.approved : t.notApproved}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <button
                onClick={() => setIsModalOpen(true)}
                className=" bg-purple-500 hover:bg-purple-600 text-white rounded-full shadow-md w-10 h-10 flex items-center justify-center m-auto"
              >
                <FontAwesomeIcon icon={faPlus} className="text-2xl" />
              </button>
            )}
          </div>
        )}
      </div>

      {/* Modal for Adding New Message */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-50 p-7">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg w-full max-w-md overflow-y-scroll relative h-4/6">
            <button onClick={() => setIsModalOpen(false)} className="absolute text-white text-xl ml-auto top-2 right-2">
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              rows={2}
              className="w-full p-4 bg-gray-700 text-gray-300 rounded-lg shadow-lg mb-4"
              placeholder={t.addMessage}
            ></textarea>

            {errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}

            <div className="flex flex-col">
              <div className=" mb-4">
                <div className="text-sm font-bold text-white">Select Start Time:</div>

                {/* Scrollable row of time buttons */}
                <div className="flex overflow-x-auto space-x-4 ">
                  {timeOptions.map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleTimeSelect(option)}
                      className={`bg-gray-800 text-gray-300 px-3 py-1 rounded-lg shadow-md hover:bg-purple-500 hover:text-white whitespace-nowrap ${
                        option.getTime() === startTime.getTime() ? 'bg-purple-500 text-white' : ''
                      }`}
                    >
                      {option.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                    </button>
                  ))}
                </div>
              </div>
              <div className="text-sm font-bold text-white">Select Duration: {duration} seconds</div>
              <input
                type="range"
                min="30"
                max="300"
                value={duration}
                onChange={handleDurationChange}
                style={{ marginTop: '0px !important' }}
                className="px-3 bg-gray-800 text-gray-300 rounded-lg shadow-lg w-full mb-4"
              />
            </div>

            <button
              onClick={handleSubmitMessage}
              className="flex items-center flex-col justify-center bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all p-4 sm:p-6 rounded-lg shadow-md text-white w-full"
            >
              <div className="flex flex-row mb-1">
                <FontAwesomeIcon icon={faPaperPlane} className="text-2xl sm:text-3xl mr-2" />
                <span className="font-semibold">{t.submit}</span>
              </div>
              <div className="text-xs text-gray-200 ">
                <strong>Cost:</strong> {duration * 10} coins
                <span className="ml-2">({(duration * 10) / 100} zł)</span>
              </div>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DedicationsScreen;
