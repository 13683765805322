import {
  faGlassCheers,
  faMusic,
  faPercentage,
  faTv,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LowerBar from '../LowerBar';

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const translations = {
    english: {
      title: 'Pick your vibe! 🎉',
      party: 'Party',
      karaoke: 'Karaoke',
      djBattle: 'DJ Battle',
      checkIn: 'Check-In',
      earnCoins: 'Earn Coins',
      discounts: 'Deals',
      djRequest: 'DJ Request (Soon)',
      composeDrink: 'Custom Drink (Soon)',
      match: 'Match (Soon)',
      dedication: 'Dedications',
      vote: 'Vote (Soon)',
      map: 'Map',
      tvDisplay: 'TV Display',
      scanDiscount: 'Scan Discount',
      menuItems: 'Menu Items',
      events: 'Events',
      adminDedicationScreen: 'Dedication',
    },
    spanish: {
      title: '¡Elige tu onda! 🎉',
      party: 'Fiesta',
      karaoke: 'Karaoke',
      djBattle: 'Batalla DJ',
      checkIn: 'Check-In',
      earnCoins: 'Gana Monedas',
      discounts: 'Ofertas',
      djRequest: 'Pedido DJ (Pronto)',
      composeDrink: 'Bebida (Pronto)',
      match: 'Match (Pronto)',
      dedication: 'Dedicatorias',
      vote: 'Głosowanie (Pronto)',
      map: 'Mapa',
      tvDisplay: 'Pantalla de TV',
      scanDiscount: 'Escanear Descuento',
      menuItems: 'Elementos del Menú',
      events: 'Eventos',
      adminDedicationScreen: 'Dedicatorias',
    },
    polish: {
      title: 'Wybierz klimat! 🎉',
      party: 'Impreza',
      karaoke: 'Karaoke',
      djBattle: 'Bitwa DJ',
      checkIn: 'Check-In',
      earnCoins: 'Zarabiaj Monety',
      discounts: 'Rabaty',
      djRequest: 'DJ (Wkrótce)',
      composeDrink: 'Drink (Wkrótce)',
      match: 'Poznaj (Wkrótce)',
      dedication: 'Dedykacje',
      vote: 'Głosowanie (Wkrótce)',
      map: 'Mapa',
      tvDisplay: 'Wyświetlacz TV',
      scanDiscount: 'Skanuj Zniżkę',
      menuItems: 'Pozycje Menu',
      events: 'Wydarzenia',
      adminDedicationScreen: 'Dedykacje',
    },
  };

  const t = translations[language as keyof typeof translations];

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center justify-center relative pt-24 md:pt-0 pb-24">
      {/* Spicy Message */}
      <h1 className="text-2xl sm:text-3xl text-center font-bold text-gray-200 mb-8 px-4">{t.title}</h1>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 w-full max-w-3xl mx-auto px-4">
        {/* TV Display Button */}
        <button
          onClick={() => navigate('/app/admin/tv-display')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 hover:from-teal-500 hover:to-blue-400 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faTv} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.tvDisplay}</span>
        </button>

        {/* Scan Discount Button */}
        <button
          onClick={() => navigate('/app/admin/scan-qrs')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-600 to-teal-600 hover:from-blue-700 hover:to-teal-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faPercentage} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.scanDiscount}</span>
        </button>

        {/* Menu Items Button */}
        <button
          onClick={() => navigate('/app/admin/menu-items')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-500 to-teal-500 hover:from-blue-600 hover:to-teal-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faGlassCheers} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.menuItems}</span>
        </button>

        {/* Admin Dedication Screen Button */}
        <button
          onClick={() => navigate('/app/admin/admin-dedication-screen')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faGlassCheers} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.adminDedicationScreen}</span>
        </button>

        {/* Events Button */}
        <button
          onClick={() => navigate('/app/admin/events')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-blue-500 hover:from-purple-600 hover:to-blue-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faMusic} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.events}</span>
        </button>
      </div>

      <LowerBar />
    </div>
  );
};

export default HomeScreen;
