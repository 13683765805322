// src/translations/eventTranslations.ts
const eventTranslations = {
  english: {
    back: 'Back',
    verified: 'Verified',
    network: 'Taki Network',
    date: 'Date',
    time: 'Time',
    location: 'Location',
    address: 'Address',
    seeVenueDetails: 'See Venue Details',
    genres: 'Genres:',
    venueType: 'Venue Type:',
    dressCode: 'Dress Code:',
    ageRestriction: 'Age Restriction:',
    djs: 'DJs:',
    buyTickets: 'Buy Tickets',
    passes: 'Passes',
    // New key for the Event Info tab
    info: 'Event Info',
  },
  spanish: {
    back: 'Atrás',
    verified: 'Verificado',
    network: 'Taki Network',
    date: 'Fecha',
    time: 'Hora',
    location: 'Ubicación',
    address: 'Dirección',
    seeVenueDetails: 'Ver detalles del lugar',
    genres: 'Géneros:',
    venueType: 'Tipo de lugar:',
    dressCode: 'Código de vestimenta:',
    ageRestriction: 'Restricción de edad:',
    djs: 'DJ´s:',
    buyTickets: 'Comprar entradas',
    passes: 'Passes',
    // New key for the Event Info tab
    info: 'Detalles del evento',
  },
  polish: {
    back: 'Wstecz',
    verified: 'Zweryfikowany',
    network: 'Taki Network',
    date: 'Data',
    time: 'Godzina',
    location: 'Lokalizacja',
    address: 'Adres',
    seeVenueDetails: 'Zobacz szczegóły miejsca',
    genres: 'Gatunki:',
    venueType: 'Typ miejsca:',
    dressCode: 'Dress Code:',
    ageRestriction: 'Ograniczenie wiekowe:',
    djs: 'DJ-e:',
    buyTickets: 'Kup bilety',
    passes: 'Passes',
    // New key for the Event Info tab
    info: 'Informacje o wydarzeniu',
  },
};

export default eventTranslations;
