// src/hooks/useImageUpload.ts
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

/**
 * useImageUpload hook
 * @param token - The authentication token used for API calls.
 * @returns An object with an async function `uploadImage` that uploads a file and returns its URL.
 */
export const useImageUpload = (token: string) => {
  const uploadImage = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append('mediaUpload', file);

    try {
      const response = await fetch(`${APIBase}/client/event/upload-media`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Image upload failed');
      }

      const result = await response.json();
      return result.imageURL;
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Image upload failed. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
      throw error;
    }
  };

  return { uploadImage };
};
