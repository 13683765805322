import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AddToHomeScreenPrompt from './AddToHomeScreenPrompt';
import { logPageView } from './analytics';
import './App.scss';
import Companies from './Companies';
import { AuthProvider } from './Contexts/AuthContext';
import { LanguageProvider } from './Contexts/LanguageContext';
import { LocationProvider } from './Contexts/LocationContext'; // Import LocationProvider
import { UserProvider } from './Contexts/UserContext'; // Import the UserProvider

import DJs from './DJs';
import DrinkingGames from './DrinkingGames';
import GDPRPrompt from './GDPRPrompt';
// import Footer from './Footer';
import Auth from 'Auth';
import ForBars from './ForBars';
import ForClubs from './ForClubs';
import ForEventOrganizers from './ForEventOrganizers';
import Home from './Home';
import Menu from './Menu';
import PrivateApp from './PrivateApp';
import PubCrawl from './PubCrawl';
import RouteChangeTracker from './RouteChangeTracker';
import Services from './Services';
import SplashScreen from './SplashScreen';
import Sylwester from './Sylwester';
import TakiTakiKatowice from './TakiTakiKatowice';
import Takiton from './Takiton';
import RobotHost from './RobotHost';
import Inmigrantes from './Inmigrantes';
import TakiTicketing from './TakiTicketing';
import TopBar from './TopBar';
import isStandalone from './utils/isStandalone';

const queryClient = new QueryClient();

const App = () => {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    logPageView();
  }, []);

  const handleSplashComplete = () => {
    setShowSplash(false);
  };

  if (showSplash) {
    return <SplashScreen onComplete={handleSplashComplete} />;
  }

  return (
    <>
      <AddToHomeScreenPrompt />
      <GDPRPrompt />
      <AuthProvider>
        <UserProvider>
          <LanguageProvider>
            <LocationProvider>
              {' '}
              {/* Wrap the app in LocationProvider */}
              <QueryClientProvider client={queryClient}>
                <div className="taki-container">
                  <TopBar />
                  <Routes>
                    <Route path="takitakikatowice" element={<TakiTakiKatowice />} />
                    <Route path="services" element={<Services />} />
                    <Route path="robot-host" element={<RobotHost />} />
                    <Route path="taki-ticketing" element={<TakiTicketing />} />
                    <Route path="inmigrantes" element={<Inmigrantes />} />
                    <Route path="home" element={<Home />} />
                    <Route path="takiton" element={<Takiton />} />
                    <Route path="sylwester" element={<Sylwester />} />
                    <Route path="pubcrawl" element={<PubCrawl />} />
                    <Route path="forbars" element={<ForBars />} />
                    <Route path="forclubs" element={<ForClubs />} />
                    <Route path="foreventorganizers" element={<ForEventOrganizers />} />
                    <Route path="drinking-games" element={<DrinkingGames />} />
                    <Route path="companies" element={<Companies />} />
                    <Route path="menu" element={<Menu />} />
                    <Route path="djs" element={<DJs />} />
                    <Route path="auth/*" element={<Auth />} />
                    <Route path="app/*" element={<PrivateApp />} />
                    <Route path="*" element={<Navigate to={`${isStandalone() ? '/app' : '/home'}`} />} />
                  </Routes>
                  {/* <Footer /> */}
                </div>
              </QueryClientProvider>
            </LocationProvider>
          </LanguageProvider>
        </UserProvider>
      </AuthProvider>
    </>
  );
};

const AppWrapper = () => (
  <BrowserRouter>
    <RouteChangeTracker />
    <App />
  </BrowserRouter>
);

export default AppWrapper;
