import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { DaySchedule, VenueData } from 'types';
import ScheduleManager from '../ScheduleManager';

const CreateVenue: React.FC = () => {
  const { language } = useLanguage();
  const translations = {
    english: {
      back: 'Back',
      createVenue: 'Create Venue',
      name: 'Name',
      address: 'Address',
      description: 'Description',
      uploadImage: 'Upload Image',
      latitude: 'Latitude',
      longitude: 'Longitude',
      venueTypes: 'Venue Types',
      facebook: 'Facebook Link',
      instagram: 'Instagram Link',
      tiktok: 'TikTok Link',
      website: 'Website Link',
      success: 'Success',
      successMessage: 'Venue created successfully! 🎉',
      error: 'Error',
      uploadError: 'Image upload failed. Please try again.',
      submitError: 'Please try again later 😞',
      submitButton: 'Create Venue',
    },
    spanish: {
      back: 'Atrás',
      createVenue: 'Crear Lugar',
      name: 'Nombre',
      address: 'Dirección',
      description: 'Descripción',
      uploadImage: 'Subir Imagen',
      latitude: 'Latitud',
      longitude: 'Longitud',
      venueTypes: 'Tipos de Lugar',
      facebook: 'Enlace de Facebook',
      instagram: 'Enlace de Instagram',
      tiktok: 'Enlace de TikTok',
      website: 'Enlace del sitio web',
      success: 'Éxito',
      successMessage: '¡Lugar creado con éxito! 🎉',
      error: 'Error',
      uploadError: 'La carga de la imagen falló. Por favor, inténtelo de nuevo.',
      submitError: 'Por favor, inténtelo de nuevo más tarde 😞',
      submitButton: 'Crear Lugar',
    },
    polish: {
      back: 'Powrót',
      createVenue: 'Utwórz Miejsce',
      name: 'Nazwa',
      address: 'Adres',
      description: 'Opis',
      uploadImage: 'Prześlij Obraz',
      latitude: 'Szerokość geograficzna',
      longitude: 'Długość geograficzna',
      venueTypes: 'Rodzaje Miejsc',
      facebook: 'Link do Facebooka',
      instagram: 'Link do Instagrama',
      tiktok: 'Link do TikToka',
      website: 'Link do strony internetowej',
      success: 'Sukces',
      successMessage: 'Miejsce zostało pomyślnie utworzone! 🎉',
      error: 'Błąd',
      uploadError: 'Przesyłanie obrazu nie powiodło się. Proszę spróbować ponownie.',
      submitError: 'Proszę spróbować ponownie później 😞',
      submitButton: 'Utwórz Miejsce',
    },
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  const [venueData, setVenueData] = useState<VenueData>({
    name: '',
    address: '',
    description: '',
    imageURL: '',
    lat: '',
    lng: '',
    venueTypes: [], // Correctly initialized as an array of strings
    schedule: [
      { day: 'Monday', times: [] },
      { day: 'Tuesday', times: [] },
      { day: 'Wednesday', times: [] },
      { day: 'Thursday', times: [] },
      { day: 'Friday', times: [] },
      { day: 'Saturday', times: [] },
      { day: 'Sunday', times: [] },
    ],
    facebookLink: '',  // Updated field names
    instagramLink: '',
    tiktokLink: '',
    websiteLink: '',
  });

  const [imageFile, setImageFile] = useState<File | null>(null);

  const { currentToken } = useAuth();
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setVenueData({ ...venueData, [name]: value });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImageFile(file);
    }
  };

  const handleVenueTypesChange = (selectedOptions: any) => {
    const types = selectedOptions ? selectedOptions.map((option: any) => option.value) : [];
    setVenueData({ ...venueData, venueTypes: types });
  };

  const handleScheduleChange = (schedule: DaySchedule[]) => {
    setVenueData({ ...venueData, schedule });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let uploadedImageURL = venueData.imageURL;

    if (imageFile) {
      const formData = new FormData();
      formData.append('mediaUpload', imageFile);

      const uploadData: RequestInit = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        body: formData,
      };

      try {
        const uploadResponse = await fetch(`${APIBase}/client/venue/upload-media`, uploadData);
        const uploadResult = await uploadResponse.json();
        if (uploadResponse.ok) {
          uploadedImageURL = uploadResult.imageURL;
        } else {
          throw new Error('Image upload failed');
        }
      } catch (error) {
        Swal.fire({
          title: t.error,
          text: t.uploadError,
          icon: 'error',
          timer: 3000,
        });
        return;
      }
    }

    const finalVenueData = {
      ...venueData,
      imageURL: uploadedImageURL,
      lat: Number(venueData.lat),  // Converting lat/lng to number before submission
      lng: Number(venueData.lng),
    };

    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalVenueData),
    };

    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.successMessage,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/venues');
        } else {
          Swal.fire({
            title: t.error,
            text: t.submitError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.submitError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const venueTypeOptions = [
    { value: 'bar', label: 'Bar' },
    { value: 'club', label: 'Club' },
    { value: 'rooftop', label: 'Rooftop' },
    { value: 'beachbar', label: 'Beach Bar' },
    { value: 'lounge', label: 'Lounge' },
    { value: 'speakeasy', label: 'Speakeasy' },
    { value: 'pub', label: 'Pub' },
    { value: 'restaurant', label: 'Restaurant' },
  ];

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 pt-20 p-3 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg mx-3" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.createVenue}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.name}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={venueData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="address">
            {t.address}
          </label>
          <input
            type="text"
            id="address"
            name="address"
            value={venueData.address}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="description">
            {t.description}
          </label>
          <textarea
            id="description"
            name="description"
            value={venueData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageUpload">
            {t.uploadImage}
          </label>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/*"
            onChange={handleImageChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lat">
            {t.latitude}
          </label>
          <input
            type="text"
            id="lat"
            name="lat"
            value={venueData.lat}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="lng">
            {t.longitude}
          </label>
          <input
            type="text"
            id="lng"
            name="lng"
            value={venueData.lng}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="venueTypes">
            {t.venueTypes}
          </label>
          <Select
            id="venueTypes"
            isMulti
            options={venueTypeOptions}
            value={venueTypeOptions.filter((option) =>
              venueData.venueTypes.includes(option.value)
            )}
            onChange={handleVenueTypesChange}
            className="w-full text-black rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="facebookLink">
            {t.facebook}
          </label>
          <input
            type="text"
            id="facebookLink"
            name="facebookLink"
            value={venueData.facebookLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="instagramLink">
            {t.instagram}
          </label>
          <input
            type="text"
            id="instagramLink"
            name="instagramLink"
            value={venueData.instagramLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="tiktokLink">
            {t.tiktok}
          </label>
          <input
            type="text"
            id="tiktokLink"
            name="tiktokLink"
            value={venueData.tiktokLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="websiteLink">
            {t.website}
          </label>
          <input
            type="text"
            id="websiteLink"
            name="websiteLink"
            value={venueData.websiteLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-8">
          <ScheduleManager schedule={venueData.schedule} onScheduleChange={handleScheduleChange} />
        </div>
        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.submitButton}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateVenue;
