import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';

export interface Ticket {
  _id: string;
  event: {
    _id: string;
    title: string;
    date: string;
    imageURL?: string;
  };
  pass: {
    name: string;
    price: number;
    currency: string;
  } | null;
  quantity: number;
  status: string;
  user: {
    name: string;
    email: string;
    username: string;
  };
}

const useEventTickets = (eventId: string, token: string) => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (eventId && token) {
      const fetchTickets = async () => {
        try {
          const response = await fetch(`${APIBase}/client/ticket/event/${eventId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const data = await response.json();
          if (response.ok) {
            setTickets(data);
          } else {
            setError('Failed to load event tickets');
          }
        } catch (err) {
          console.error('Error fetching event tickets:', err);
          setError('Error fetching event tickets');
        } finally {
          setLoading(false);
        }
      };
      fetchTickets();
    }
  }, [eventId, token]);

  return { tickets, loading, error };
};

export default useEventTickets;
