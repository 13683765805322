// src/components/EventEditor/EventEditor.tsx
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ActivitiesManager from './components/ActivitiesManager';
import ClubAndTypeSection from './components/ClubAndTypeSection';
import GenreAndDJSection from './components/GenreAndDJSection';
import PassesManager from './components/PassesManager';
import StandardFields from './components/StandardFields';
import { useEventEditorActions } from './hooks/useEventEditorActions';
import { useEventEditorData } from './hooks/useEventEditorData';
import { useImageUpload } from './hooks/useImageUpload';
import translations from './translations';
import {Pass} from './components/PassesManager'
const EventEditor: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  // Local state for extra inputs
  const [genreInput, setGenreInput] = useState('');
  const [djInput, setDjInput] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);

  // Activities state for editing
  const [newActivity, setNewActivity] = useState({ name: '', coins: 0 });
  const [selectedActivity, setSelectedActivity] = useState<any | null>(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Passes state for managing ticket types
  const [passes, setPasses] = useState<any[]>([]);
  const [passInput, setPassInput] = useState({
    name: '',
    description: '',
    price: 0,
    currency: '',
    maxQuantity: 0,
  });

  // Get event data, venues, and activities from the custom hook
  const { eventData, venues, activities, setEventData, loading, error, refetchActivities } = useEventEditorData(
    eventId as string,
    currentToken || '',
  );

  // Get action functions from the actions hook and image upload hook
  const { updateEvent, updateActivity, deleteActivity, createActivity } = useEventEditorActions(
    currentToken || '',
    eventId as string,
    refetchActivities,
  );
  const { uploadImage } = useImageUpload(currentToken || '');

  // Standard onChange handler for inputs
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    setEventData({ ...eventData, [name]: type === 'checkbox' ? checked : value });
  };

  // Dedicated select handler
  const handleSelectChange = (selectedOption: any, name: string) => {
    setEventData({ ...eventData, [name]: selectedOption ? selectedOption.value : '' });
  };

  // Dedicated toggle handler for checkboxes/toggles
  const handleToggleChange = (name: string, checked: boolean) => {
    setEventData({ ...eventData, [name]: checked });
  };

  // --- Genre and DJ Section helper functions ---
  const handleGenreAdd = () => {
    if (genreInput && !eventData.genres.includes(genreInput)) {
      setEventData({ ...eventData, genres: [...eventData.genres, genreInput] });
      setGenreInput('');
    }
  };

  const handleGenreRemove = (genre: string) => {
    setEventData({ ...eventData, genres: eventData.genres.filter((g: string) => g !== genre) });
  };

  const handleDjAdd = () => {
    if (djInput && !eventData.djs.includes(djInput)) {
      setEventData({ ...eventData, djs: [...eventData.djs, djInput] });
      setDjInput('');
    }
  };

  const handleDjRemove = (dj: string) => {
    setEventData({ ...eventData, djs: eventData.djs.filter((d: string) => d !== dj) });
  };

  // --- Passes functions ---
  const handlePassInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setPassInput({ ...passInput, [name]: value });
  };

  const handleAddPass = () => {
    if (!passInput.name || !passInput.price || !passInput.currency || !passInput.maxQuantity) {
      Swal.fire({ title: t.error, text: t.passFillError || 'Please fill all pass fields.', icon: 'error' });
      return;
    }
    const newPass = { ...passInput };
    const updatedPasses = [...passes, newPass];
    setPasses(updatedPasses);
    setEventData({ ...eventData, passes: updatedPasses });
    setPassInput({ name: '', description: '', price: 0, currency: '', maxQuantity: 0 });
  };

  const handleEditPass = (index: number, updatedPass: Pass) => {
    const updatedPasses = [...passes];
    updatedPasses[index] = updatedPass;
    setPasses(updatedPasses);
    setEventData({ ...eventData, passes: updatedPasses });
  };

  const handleRemovePass = (index: number) => {
    const updatedPasses = passes.filter((_, i) => i !== index);
    setPasses(updatedPasses);
    setEventData({ ...eventData, passes: updatedPasses });
  };

  // --- Activities functions ---
  const handleActivitySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await createActivity({ ...newActivity, eventId });
    setNewActivity({ name: '', coins: 0 });
  };

  const handleActivityDelete = async (activityId: string) => {
    await deleteActivity(activityId);
  };

  const openEditModal = (activity: any) => {
    setSelectedActivity(activity);
    setIsEditModalOpen(true);
  };

  const handleActivityEditSubmit = async () => {
    if (selectedActivity) {
      await updateActivity(selectedActivity);
      setIsEditModalOpen(false);
    }
  };

  // --- Event Update (Submit) ---
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let uploadedImageUrl = eventData?.imageURL;
    if (imageFile) {
      try {
        uploadedImageUrl = await uploadImage(imageFile);
      } catch (error) {
        return;
      }
    }
    const success = await updateEvent(eventData, uploadedImageUrl);
    if (success) {
      Swal.fire({
        title: t.success,
        text: t.updateSuccess,
        icon: 'success',
        timer: 2000,
      });
      navigate('/app/admin/events');
    }
  };

  useEffect(() => {
    if (eventData?.passes) {
      setPasses(eventData?.passes);
    }
  }, [eventData?.passes]);

  // Assume venueOptions is derived from venues
  const venueOptions = venues.map((venue: any) => ({
    value: venue._id,
    label: venue.name,
  }));

  if (loading) {
    return <div className="text-white min-h-screen flex items-center justify-center bg-gray-900">Loading...</div>;
  }

  if (error) {
    return <div className="text-white text-center mt-10">{error}</div>;
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-28 pb-28 flex flex-col items-center">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>

      {/* Split form into two columns on large screens */}
      <div className="lg:flex lg:gap-8 w-full max-w-6xl">
        <form className="w-full lg:w-1/2" onSubmit={handleSubmit}>
          <h2 className="text-2xl mb-4">{t.editEvent}</h2>
          <StandardFields
            eventData={eventData}
            handleChange={handleChange}
            imageFile={imageFile}
            setImageFile={setImageFile}
            t={t}
          />
          <GenreAndDJSection
            eventData={eventData}
            genreInput={genreInput}
            djInput={djInput}
            setGenreInput={setGenreInput}
            setDjInput={setDjInput}
            handleGenreAdd={handleGenreAdd}
            handleGenreRemove={handleGenreRemove}
            handleDjAdd={handleDjAdd}
            handleDjRemove={handleDjRemove}
            t={t}
          />
          <ClubAndTypeSection
            eventData={eventData}
            venues={venues}
            handleSelectChange={handleSelectChange}
            handleToggleChange={handleToggleChange}
            t={t}
          />
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="px-4 py-2 bg-gradient-to-r from-blue-500 to-indigo-500 text-white rounded hover:from-blue-600 hover:to-indigo-600 transition-all duration-300"
            >
              {t.updateEvent}
            </button>
          </div>
        </form>

        {/* Right Column: Passes and Activities */}
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <PassesManager
            passes={eventData.passes}
            passInput={passInput}
            onPassInputChange={handlePassInputChange}
            onPassCurrencyChange={(value: string) => setPassInput({ ...passInput, currency: value })}
            onAddPass={handleAddPass}
            onEditPass={handleEditPass}
            onRemovePass={handleRemovePass}
            t={t}
          />
          <ActivitiesManager
            activities={activities}
            newActivity={newActivity}
            setNewActivity={setNewActivity}
            handleActivitySubmit={async (e) => {
              e.preventDefault();
              await createActivity({ ...newActivity, eventId });
              setNewActivity({ name: '', coins: 0 });
            }}
            handleActivityDelete={async (activityId: string) => {
              await deleteActivity(activityId);
            }}
            openEditModal={(activity) => {
              setSelectedActivity(activity);
              setIsEditModalOpen(true);
            }}
            t={t}
          />
        </div>
      </div>

      {/* Modal for Editing Activity */}
      {isEditModalOpen && selectedActivity && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl mb-4">{t.editActivity}</h3>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.activityName}</label>
              <input
                type="text"
                name="name"
                value={selectedActivity.name}
                onChange={(e) => setSelectedActivity({ ...selectedActivity, name: e.target.value })}
                className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.activityCoins}</label>
              <input
                type="number"
                name="coins"
                value={selectedActivity.coins}
                onChange={(e) => setSelectedActivity({ ...selectedActivity, coins: +e.target.value })}
                className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              />
            </div>
            <div className="flex justify-end space-x-3">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setIsEditModalOpen(false);
                }}
                className="px-3 py-2 bg-gray-600 rounded hover:bg-gray-700 transition-all duration-300"
              >
                {t.cancel}
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleActivityEditSubmit();
                }}
                className="px-3 py-2 bg-gradient-to-r from-blue-500 to-indigo-500 rounded hover:from-blue-600 hover:to-indigo-600 transition-all duration-300"
              >
                {t.saveActivity}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventEditor;
