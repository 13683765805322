// src/components/PlaylistDetail/VideoList.tsx
import React from 'react';
import { FaEdit } from 'react-icons/fa';

export interface VideoListProps {
  remainingVideos: any[];
  t: any;
  startIndex: number;
  jumpToVideo?: (index: number) => void;
  onEditVideo?: (video: any) => void; // Optional callback for editing a video
  userId?: string; // Current user's id
  isAdmin?: boolean; // Whether the current user is admin
  currentVideoId?: string; // The _id of the currently playing video
}

const VideoList: React.FC<VideoListProps> = ({
  remainingVideos,
  t,
  startIndex,
  jumpToVideo,
  onEditVideo,
  userId,
  isAdmin,
  currentVideoId,
}) => {
  return (
    <div className="bg-gray-800 p-6 rounded-xl shadow-xl">
      <h3 className="text-2xl font-bold text-white mb-4">{t.following}</h3>
      {remainingVideos.length > 0 ? (
        <ul className="space-y-4">
          {remainingVideos.map((video, index) => {
            const videoThumbnailUrl = `https://img.youtube.com/vi/${video.videoId}/hqdefault.jpg`;
            // Determine if the video is editable:
            // Only show the edit button if onEditVideo is provided AND
            // (the user is admin OR the current user added the video) AND
            // the video is not currently playing.
            const canEdit =
              onEditVideo &&
              (isAdmin || (userId && video.addedBy._id === userId)) &&
              video._id !== currentVideoId;
            return (
              <li key={video.videoId} className="flex justify-between items-center">
                <div
                  className="flex items-center space-x-4 cursor-pointer hover:opacity-90 transition-opacity duration-300"
                  onClick={() => jumpToVideo && jumpToVideo(startIndex + index)}
                >
                  <img
                    src={videoThumbnailUrl}
                    alt="Video Thumbnail"
                    className="w-20 h-20 object-cover rounded-lg border-2 border-gray-700"
                  />
                  <div className="flex flex-col">
                    <p className="font-extrabold text-xl text-white">{`${startIndex + index}. ${video.title || t.unknown}`}</p>
                    <p className="text-sm text-gray-300">
                      {t.addedBy}: {video.addedBy?.username || t.unknown}
                    </p>
                  </div>
                </div>
                {canEdit && (
                  <button
                    onClick={() => onEditVideo && onEditVideo(video)}
                    className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:from-pink-600 hover:to-red-600 transition-all duration-300"
                    title="Edit Video"
                  >
                    <FaEdit className="text-xl" />
                  </button>
                )}
              </li>
            );
          })}
        </ul>
      ) : (
        <p className="text-gray-400">{t.remainingVideos}</p>
      )}
    </div>
  );
};

export default VideoList;
