import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaFacebook, FaGlobe, FaInstagram, FaMapMarkerAlt, FaTiktok } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    details: 'Club Details',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    schedule: 'Schedule',
    menuItems: 'Menu', // Add this line
    viewOnMap: 'View on Map',
    back: 'Back to Clubs',
    error: 'Error',
    fetchError: 'Failed to load club details. Please try again later.',
  },
  spanish: {
    details: 'Detalles del Club',
    name: 'Nombre',
    address: 'Dirección',
    description: 'Descripción',
    schedule: 'Horario',
    menuItems: 'Menú', // Add this line
    viewOnMap: 'Ver en el Mapa',
    back: 'Volver a Clubs',
    error: 'Error',
    fetchError: 'No se pudieron cargar los detalles del club. Inténtelo de nuevo más tarde.',
  },
  polish: {
    details: 'Szczegóły Klubu',
    name: 'Nazwa',
    address: 'Adres',
    description: 'Opis',
    schedule: 'Harmonogram',
    menuItems: 'Menu', // Add this line
    viewOnMap: 'Zobacz na Mapie',
    back: 'Wróć do Klubów',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować szczegółów klubu. Spróbuj ponownie później.',
  },
};

const ClubDetail: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const { clubId } = useParams<{ clubId: string }>();
  const navigate = useNavigate();
  const [club, setClub] = useState<any | null>(null);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchClubDetails();
    fetchMenuItems(); // Add this line
  }, [clubId, currentToken]);

  const fetchClubDetails = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue/${clubId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setClub(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchMenuItems = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item?venue=${clubId}`, data)
      .then((response) => response.json())
      .then((responseJson) => setMenuItems(responseJson))
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const generateGoogleMapsLink = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(club.address)}`;
  };

  const generateAppleMapsLink = () => {
    return `http://maps.apple.com/?q=${encodeURIComponent(club.address)}`;
  };

  const handleViewOnMap = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const mapLink = isIOS ? generateAppleMapsLink() : generateGoogleMapsLink();
    window.open(mapLink, '_blank');
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      <button
        onClick={() => navigate('/app/partyticket/clubs')}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      {club ? (
        <div className="max-w-3xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6">{t.details}</h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-lg mb-4">
              <strong>{t.name}:</strong> {club.name}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.address}:</strong> {club.address}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.description}:</strong> {club.description}
            </p>
            <button
              onClick={handleViewOnMap}
              className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white p-3 rounded-full shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 ease-in-out mb-6"
            >
              <FaMapMarkerAlt className="mr-2 text-xl" />
              {t.viewOnMap}
            </button>
            {club.imageURL && (
              <img src={club.imageURL} alt={club.name} className="w-full h-64 object-cover rounded-md mb-6" />
            )}
            {club.schedule && (
              <div className="mt-6">
                <h3 className="text-2xl font-semibold mb-4">{t.schedule}</h3>
                <div className="space-y-2">
                  {club.schedule.map((day: any, index: number) => (
                    <div key={index} className="flex justify-between text-lg">
                      <span>{day.day}</span>
                      <span>
                        {day.times.length > 0
                          ? day.times.map((time: any) => `${time.open} - ${time.close}`).join(', ')
                          : 'Closed'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="flex flex-row space-x-4 my-4">
              {club.facebookLink && (
                <a
                  href={club.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaFacebook className="text-2xl" />
                </a>
              )}
              {club.instagramLink && (
                <a
                  href={club.instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-pink-500 to-pink-700 hover:from-pink-600 hover:to-pink-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaInstagram className="text-2xl" />
                </a>
              )}
              {club.tiktokLink && (
                <a
                  href={club.tiktokLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaTiktok className="text-2xl" />
                </a>
              )}
              {club.websiteLink && (
                <a
                  href={club.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaGlobe className="text-2xl" />
                </a>
              )}
            </div>
          </div>
          {menuItems.length > 0 && (
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-4">{t.menuItems}</h3>
              <div className="space-y-4">
                {menuItems.map((menuItem) => (
                  <div
                    key={menuItem._id}
                    className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-center cursor-pointer"
                    onClick={() => navigate(`/app/partyticket/menu-item/${menuItem._id}`)}
                  >
                    {menuItem.imageURL && (
                      <img
                        src={menuItem.imageURL}
                        alt={menuItem.name}
                        className="w-16 h-16 object-cover rounded-md mr-4"
                      />
                    )}
                    <div>
                      <h4 className="text-lg font-semibold">{menuItem.name}</h4>
                      <p className="text-sm text-gray-400">{menuItem.description}</p>
                      <p className="text-sm text-gray-500">
                        {menuItem.price} {menuItem.currency}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>{t.fetchError}</p>
      )}
    </div>
  );
};

export default ClubDetail;
