// src/hooks/useUserProfile.ts
import { APIBase } from 'api/hosts';
import { useQuery } from 'react-query';

const fetchUserProfile = async (token: string) => {
  const response = await fetch(`${APIBase}/client/user`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user profile');
  }
  const data = await response.json();
  return data;
};

export const useUserProfile = (token: string) => {
  return useQuery(['userProfile'], () => fetchUserProfile(token), {
    enabled: !!token,
  });
};
