// hooks/useFetchVenues.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchVenues = (currentToken: string) => {
  const [venues, setVenues] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response = await fetch(`${APIBase}/client/venue`, {
          method: 'GET',
          headers: { Authorization: `Bearer ${currentToken}` },
        });

        if (response.ok) {
          const data = await response.json();
          setVenues(data);
        } else {
          throw new Error('Failed to fetch venues');
        }
      } catch (error) {
        Swal.fire('Error', 'Failed to fetch venues.', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchVenues();
  }, [currentToken]);

  return { venues, loading };
};

export default useFetchVenues;
