// src/hooks/useFreezeVotes.ts
import { useMutation, useQueryClient } from 'react-query';
import { APIBase } from 'api/hosts';

interface FreezeVotesPayload {
  playlistId: string;
  token: string;
}

const freezeVotes = async ({ playlistId, token }: FreezeVotesPayload) => {
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}/freeze`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    const data = await response.json();
    throw new Error(data.message || 'Failed to freeze votes.');
  }
  const data = await response.json();
  return data;
};

export const useFreezeVotes = (playlistId: string, token: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => freezeVotes({ playlistId, token }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['playlist', playlistId]);
    },
  });
};
