// src/components/EventEditor/ClubAndTypeSection.tsx
import React from 'react';
import Select from 'react-select';
import Switch from 'react-switch';
import { typeOptions, dressCodes } from '../config';

interface ClubAndTypeProps {
  eventData: any;
  venues: any[];
  handleSelectChange: (selectedOption: any, name: string) => void;
  handleToggleChange: (name: string, checked: boolean) => void;
  t: any;
}

const ClubAndTypeSection: React.FC<ClubAndTypeProps> = ({
  eventData,
  venues,
  handleSelectChange,
  handleToggleChange,
  t,
}) => {
  const venueOptions = venues.map((venue: any) => ({
    value: venue._id,
    label: venue.name,
  }));

  return (
    <>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="clubId">
          {t.selectClub}
        </label>
        <Select
          id="clubId"
          name="clubId"
          value={venueOptions.find((option) => option.value === eventData?.clubId)}
          onChange={(selectedOption) => handleSelectChange(selectedOption, 'clubId')}
          options={venueOptions}
          className="w-full text-black"
          placeholder={t.selectClub}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="type">
          {t.type}
        </label>
        <Select
          id="type"
          name="type"
          value={typeOptions.find((option) => option.value === eventData?.type)}
          onChange={(selectedOption) => handleSelectChange(selectedOption, 'type')}
          options={typeOptions}
          className="w-full text-black"
          placeholder={t.selectType}
          required
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="dressCode">
          {t.dressCode}
        </label>
        <Select
          id="dressCode"
          name="dressCode"
          value={dressCodes.find((option) => option.value === eventData?.dressCode)}
          onChange={(selectedOption) => handleSelectChange(selectedOption, 'dressCode')}
          options={dressCodes}
          className="w-full text-black"
          placeholder={t.selectDressCode}
        />
      </div>
      <div className="mb-4 flex items-center">
        <label className="text-sm font-medium mr-2 text-gray-200" htmlFor="verified">
          {t.verified}
        </label>
        <Switch
          id="verified"
          onChange={(checked) => handleToggleChange('verified', checked)}
          checked={eventData?.verified || false}
          onColor="#6EE7B7"
          offColor="#374151"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          handleDiameter={18}
        />
      </div>
      <div className="mb-4 flex items-center">
        <label className="text-sm font-medium mr-2 text-gray-200" htmlFor="network">
          {t.network}
        </label>
        <Switch
          id="network"
          onChange={(checked) => handleToggleChange('network', checked)}
          checked={eventData?.network || false}
          onColor="#6EE7B7"
          offColor="#374151"
          uncheckedIcon={false}
          checkedIcon={false}
          height={20}
          width={40}
          handleDiameter={18}
        />
      </div>
    </>
  );
};

export default ClubAndTypeSection;
