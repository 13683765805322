// src/utils/youtubeHelpers.ts
export const extractVideoId = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (urlObj.hostname.includes('youtube.com')) {
      return urlObj.searchParams.get('v'); // Extract 'v' parameter for standard YouTube URLs
    }

    if (urlObj.hostname.includes('youtu.be')) {
      return urlObj.pathname.substring(1).split('?')[0]; // Extract video ID from shortened URLs
    }

    return null; // Unsupported format
  } catch (error) {
    console.error('Invalid URL:', url);
    return null;
  }
};
