import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import { useContext } from 'react';
import { FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import sun3RemovebgPrev from '../../assets/sun3RemovebgPrev.png';

interface SideMenuProps {
  open: boolean;
  toggle: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({ open, toggle }) => {
  const navigate = useNavigate();
  const { currentToken, expiration, clearAuthentication } = useAuth();
  const { language } = useLanguage();
  const isAuthenticated = currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  const handleLogout = () => {
    Swal.fire({
      title: 'Thank you',
      text: 'Hope to see you soon 🤗',
      showConfirmButton: false,
      timer: 3000,
      background: '#000000',
      color: '#FFFFFF',
    });
    toggle();
    clearAuthentication();
    navigate('/auth/login');
  };

  const menuItems = {
    english: {
      home: 'HOME',
      services: 'SERVICES',
      forIndividuals: 'FOR INDIVIDUALS',
      forBars: 'FOR BARS',
      forClubs: 'FOR CLUBS',
      forEventOrganizers: 'FOR EVENT ORGANIZERS',
      events: 'EVENTS',
      profile: 'PROFILE',
      myEvents: 'MY EVENTS',
      gallery: 'GALLERY',
      logout: 'LOGOUT',
      socialMedia: 'SOCIAL MEDIA',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'LOGIN',
      register: 'REGISTER',
      sylwester: '¡FELIZ AÑO NUEVO! 2025',
      robotHost: 'ROBOT HOST',
      inmigrantes: 'INMIGRANTES',
      takiTicketing: 'TAKI TICKETING',
    },
    polish: {
      home: 'STRONA GŁÓWNA',
      services: 'USŁUGI',
      forIndividuals: 'DLA OSÓB PRYWATNYCH',
      forBars: 'DLA BARÓW',
      forClubs: 'DLA KLUBÓW',
      forEventOrganizers: 'DLA ORGANIZATORÓW WYDARZEŃ',
      events: 'WYDARZENIA',
      profile: 'PROFIL',
      myEvents: 'MOJE WYDARZENIA',
      gallery: 'GALERIA',
      logout: 'WYLOGUJ SIĘ',
      socialMedia: 'MEDIA SPOŁECZNOŚCIOWE',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'ZALOGUJ SIĘ',
      register: 'ZAREJESTRUJ SIĘ',
      sylwester: 'SYLWESTER 2025',
      robotHost: 'ROBOT HOST',
      inmigrantes: 'INMIGRANTES',
      takiTicketing: 'TAKI TICKETING',
    },
    spanish: {
      home: 'INICIO',
      services: 'SERVICIOS',
      forIndividuals: 'PARA INDIVIDUOS',
      forBars: 'PARA BARES',
      forClubs: 'PARA CLUBES',
      forEventOrganizers: 'PARA ORGANIZADORES DE EVENTOS',
      events: 'EVENTOS',
      profile: 'PERFIL',
      myEvents: 'MIS EVENTOS',
      gallery: 'GALERÍA',
      logout: 'CERRAR SESIÓN',
      socialMedia: 'REDES SOCIALES',
      takitakikatowice: 'TAKI TAKI KATOWICE',
      login: 'INICIAR SESIÓN',
      register: 'REGISTRARSE',
      sylwester: 'HAPPY NEW YEAR! 2025',
      robotHost: 'ROBOT HOST',
      inmigrantes: 'INMIGRANTES',
      takiTicketing: 'TAKI TICKETING',
    },
  };

  const currentMenu = menuItems[language as keyof typeof menuItems];

  return (
    <>
      <div className={`fixed top-0 left-0 w-full h-full z-30 ${open ? 'block' : 'hidden'}`} onClick={toggle} />
      <div
        className={`fixed top-0 left-0 w-60 h-full z-40 bg-black bg-opacity-70 backdrop-blur-lg transition-transform transform ${
          open ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        <div className="flex flex-col items-start p-6 mt-16 h-full overflow-y-auto pb-36">
          <img alt="Logo Taki" className="h-20 mb-6" src={sun3RemovebgPrev} />
          <Link to="/home" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.home}
          </Link>
          {!isAuthenticated && (
            <Link className="text-base text-white mb-4" to="/auth/login" onClick={toggle}>
              {currentMenu.login}
            </Link>
          )}
          {!isAuthenticated && (
            <Link className="text-base text-white mb-4" to="/auth/register" onClick={toggle}>
              {currentMenu.register}
            </Link>
          )}
          <Link to="/app/partyticket" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.events}
          </Link>
          {isAuthenticated && (
            <Link className="text-base text-white mb-4" to="/app/profile" onClick={toggle}>
              {currentMenu.profile}
            </Link>
          )}
          
          <Link to="/robot-host" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.robotHost}
          </Link>
          <Link to="/taki-ticketing" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.takiTicketing}
          </Link>
          <Link to="/inmigrantes" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.inmigrantes}
          </Link>
          <Link to="/takitakikatowice" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.takitakikatowice}
          </Link>
          <Link to="/sylwester" className="text-base text-white mb-4" onClick={toggle}>
            {currentMenu.sylwester}
          </Link>
          <div className="text-base text-white mb-4">
            <span className="font-bold">{currentMenu.forIndividuals}</span>
            <Link to="/services" className="block mt-2 ml-4" onClick={toggle}>
              {currentMenu.services}
            </Link>
          </div>
          <div className="text-base text-white mb-4">
            <span className="font-bold">FOR BUSINESS OWNERS</span>
            <Link to="/forbars" className="block mt-2 ml-4" onClick={toggle}>
              {currentMenu.forBars}
            </Link>
            <Link to="/forclubs" className="block mt-2 ml-4" onClick={toggle}>
              {currentMenu.forClubs}
            </Link>
            <Link to="/foreventorganizers" className="block mt-2 ml-4" onClick={toggle}>
              {currentMenu.forEventOrganizers}
            </Link>
          </div>

          {/* {isAuthenticated && (
            <Link className="text-base text-white mb-4" to="/app/my-events" onClick={toggle}>
              {currentMenu.myEvents}
            </Link>
          )}
          {isAuthenticated && (
            <Link className="text-base text-white mb-4" to="/app/gallery" onClick={toggle}>
              {currentMenu.gallery}
            </Link>
          )} */}
          {isAuthenticated && (
            <div className="text-base text-white mb-4 cursor-pointer" onClick={handleLogout}>
              {currentMenu.logout}
            </div>
          )}
          <div className="text-base text-white mb-4 w-full">
            <span className="font-bold">{currentMenu.socialMedia}</span>
            <div className="flex space-x-4 mt-2 ml-4">
              <a href="https://www.facebook.com/takitakikatowice" className="text-white">
                <FaFacebook size={24} />
              </a>
              <a href="https://www.tiktok.com/@takitakikatowice" className="text-white">
                <FaTiktok size={24} />
              </a>
              <a href="https://www.instagram.com/takitakientertainment" className="text-white">
                <FaInstagram size={24} />
              </a>
              <a href="https://chat.whatsapp.com/Kd6k8NtVI65H638LEiAY3Z" className="text-white">
                <FaWhatsapp size={24} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideMenu;
