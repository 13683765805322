import '@fortawesome/fontawesome-free/css/all.css';
import { useAuth } from 'Contexts/AuthContext';
import { useLocationContext } from 'Contexts/LocationContext';
import { APIBase } from 'api/hosts';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { FaCrosshairs, FaMinus, FaPlus } from 'react-icons/fa';
import { MapContainer, Marker, Popup, PopupProps, TileLayer, useMap } from 'react-leaflet';
import Swal from 'sweetalert2';
import { Venue } from 'types';

interface MapComponentProps {
  venues: Venue[];
  onMarkerClick: (clubId: string | null) => void;
  selectedClub: string | null;
  relativeView?: boolean;
}

const partyMarkerIcon = new L.DivIcon({
  html: `<div class="party-marker"></div>`,
  className: 'custom-div-icon',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
  popupAnchor: [0, -36],
});

const selectedPartyMarkerIcon = new L.DivIcon({
  html: `<div class="party-marker selected"></div>`,
  className: 'custom-div-icon',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
  popupAnchor: [0, -36],
});

const userLocationIcon = new L.DivIcon({
  html: `<div class="user-location"></div>`,
  className: 'custom-div-icon',
  iconSize: [10, 10],
  iconAnchor: [5, 5],
});

const styles = `
  .party-marker {
    width: 10px;
    height: 10px;
    background: black;
    border-radius: 50%;
    position: relative;
  }
  .party-marker.selected {
    background: magenta;
  }
  .party-marker::after {
    content: '';
    width: 24px;
    height: 24px;
    border: 2px dashed black;
    border-radius: 50%;
    position: absolute;
    top: -7px;
    left: -7px;
    animation: rotate 10s linear infinite;
  }
  .party-marker.selected::after {
    border-color: magenta;
  }
  .user-location {
    width: 10px;
    height: 10px;
    background: blue;
    border-radius: 50%;
    position: relative;
  }
  .user-location::before {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid blue;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    left: -5px;
    animation: pulsate 1s ease-out infinite;
  }
  @keyframes pulsate {
    0% {
      transform: scale(0.5);
      opacity: 1;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

interface CustomPopupProps extends PopupProps {
  onClose: () => void;
  children: ReactNode;
}

const CustomPopup: React.FC<CustomPopupProps> = ({ children, onClose, ...props }) => {
  const map = useMap();

  useEffect(() => {
    const handlePopupClose = () => {
      onClose();
    };

    map.on('popupclose', handlePopupClose);

    return () => {
      map.off('popupclose', handlePopupClose);
    };
  }, [map, onClose]);

  return <Popup {...props}>{children}</Popup>;
};

interface City {
  lat: number;
  lng: number;
}

const MapComponent: React.FC<MapComponentProps> = ({ venues, onMarkerClick, selectedClub, relativeView }) => {
  const [selectedClubId, setSelectedClubId] = useState<string | null>(null);
  const { selectedCity, isUsingGPS, userLocation } = useLocationContext();
  const [selectedCityObject, setSelectedCityObject] = useState<City | null>(null);
  const mapRef = useRef<L.Map | null>(null);
  const { currentToken } = useAuth();

  useEffect(() => {
    const fetchCities = async () => {
      const data: RequestInit = {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await fetch(`${APIBase}/client/city/cities`, data);
        if (response.ok) {
          const data = await response.json();
          if (selectedCity) {
            const city = data.find((city: any) => city._id === selectedCity);
            if (city) {
              setSelectedCityObject({ lat: city.lat, lng: city.lng });
            }
          }
        } else {
          throw new Error('Error fetching cities');
        }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch cities. Please try again later.',
          icon: 'error',
          timer: 3000,
        });
      }
    };

    fetchCities();
  }, [selectedCity, currentToken]);

  const defaultCenter: [number, number] = selectedCityObject
    ? [selectedCityObject.lat, selectedCityObject.lng]
    : isUsingGPS && userLocation
    ? userLocation
    : [50.260891, 19.018021];

  useEffect(() => {
    if (mapRef.current && !selectedClubId) {
      mapRef.current.setView(defaultCenter, 15);
    }
  }, [defaultCenter, selectedClubId]);

  const handleMarkerClick = (clubId: string, lat: number, lng: number) => {
    setSelectedClubId(clubId);
    onMarkerClick(clubId);
    if (mapRef.current) {
      mapRef.current.setView([lat, lng], 15);
    }
  };

  const handlePopupClose = () => {
    setSelectedClubId(null);
    onMarkerClick(null);
  };

  const recenterToGPS = () => {
    if (userLocation && mapRef.current) {
      mapRef.current.setView(userLocation, 15);
    }
  };

  const zoomIn = () => {
    if (mapRef.current) {
      mapRef.current.zoomIn();
    }
  };

  const zoomOut = () => {
    if (mapRef.current) {
      mapRef.current.zoomOut();
    }
  };

  const UpdateMapCenter = () => {
    const map = useMap();
    mapRef.current = map;
    useEffect(() => {
      if (defaultCenter && !selectedClubId) {
        map.setView(defaultCenter, 15);
      }
    }, [defaultCenter, map]);
    return null;
  };

  return (
    <div
      className={`${
        relativeView ? '' : 'fixed'
      } top-0 left-0 w-full bg-gray-900 bg-opacity-75 flex items-center justify-center z-10`}
      style={{ height: '50vh' }}
    >
      <div className="relative w-full h-full">
        <MapContainer center={defaultCenter} zoom={15} style={{ width: '100%', height: '100%' }} zoomControl={false}>
          <UpdateMapCenter />
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/attributions">CARTO</a>'
          />
          {venues.map((venue, index) => (
            <Marker
              key={index}
              position={[venue.lat, venue.lng]}
              icon={venue._id === selectedClubId ? selectedPartyMarkerIcon : partyMarkerIcon}
              eventHandlers={{
                click: () => {
                  handleMarkerClick(venue._id, venue.lat, venue.lng);
                },
              }}
            >
              <CustomPopup onClose={handlePopupClose}>
                {venue.name}
                <br />
                {venue.address}
              </CustomPopup>
            </Marker>
          ))}
          {userLocation && <Marker position={userLocation} icon={userLocationIcon}></Marker>}
        </MapContainer>

        {/* Custom Controls */}
        <div
          className="absolute bottom-2 right-2 z-50 flex flex-col items-center space-y-2"
          style={{ zIndex: '100000' }}
        >
          <button
            onClick={recenterToGPS}
            className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
          >
            <FaCrosshairs className="text-xl" />
          </button>
          <button
            onClick={zoomIn}
            className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
          >
            <FaPlus className="text-xl" />
          </button>
          <button
            onClick={zoomOut}
            className="bg-black bg-opacity-80  text-white p-2 rounded-md flex items-center justify-center transition-colors duration-300"
          >
            <FaMinus className="text-xl" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
