// src/components/PlaylistDetail/NowPlaying.tsx
import React from 'react';
import { FaMusic, FaStepForward } from 'react-icons/fa';

interface NowPlayingProps {
  currentVideo: any;
  t: any;
  /** When true, a skip button is displayed. */
  canSkip?: boolean;
  /** Called when the skip button is clicked. */
  onSkip?: () => void;
}

const NowPlaying: React.FC<NowPlayingProps> = ({ currentVideo, t, canSkip, onSkip }) => {
  return (
    <div className="bg-gray-800 p-4 rounded-lg shadow-md">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold flex items-center text-white">
          <FaMusic className="animate-bounce text-green-400 text-3md mr-2" />
          {t.nowPlaying}
        </h3>
        {currentVideo && canSkip && onSkip && (
          <button
            onClick={onSkip}
            className="flex items-center space-x-2 px-4 py-2 rounded-full bg-gradient-to-r from-purple-500 to-blue-500 text-white shadow-lg hover:from-purple-600 hover:to-blue-600 transition-all duration-300"
          >
            <FaStepForward className="text-md" />
          </button>
        )}
      </div>
      {currentVideo ? (
        <div className="flex items-center space-x-4 mt-4">
          <img
            src={`https://img.youtube.com/vi/${currentVideo.videoId}/hqdefault.jpg`}
            alt="Video Thumbnail"
            className="w-20 h-20 object-cover rounded-lg border-2 border-gray-700"
          />
          <div>
            <p className="font-extrabold text-md text-white">1. {currentVideo.title || t.loadin_song_name}</p>
            <p className="text-sm text-gray-300">
              {currentVideo.duration
                ? `${Math.floor(currentVideo.duration / 60)}:${String(currentVideo.duration % 60).padStart(2, '0')}`
                : t.loading_duration}
            </p>
            <p className="text-sm text-gray-400">
              {t.addedBy}: {currentVideo.addedBy?.username || t.unknown}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-gray-400 mt-4">{t.noVideos}</p>
      )}
    </div>
  );
};

export default NowPlaying;
