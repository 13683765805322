import React, { useEffect, useState, useContext, useRef } from 'react';
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FaEllipsisV, FaPlus, FaMapMarkedAlt } from 'react-icons/fa';
import { StageSpinner } from 'react-spinners-kit';

const translations = {
  english: {
    venues: 'Venues',
    newVenue: 'New Venue',
    mapView: 'Map View',
    viewDetails: 'View Details',
    edit: 'Edit',
    delete: 'Delete',
    error: 'Error',
    deleteError: 'Failed to delete venue. Please try again later.',
    fetchError: 'Please try again later 😞',
    deleteSuccess: 'Venue has been deleted.',
    confirmDelete: 'Are you sure?',
    confirmDeleteText: "You won't be able to revert this!",
    confirmButtonText: 'Yes, delete it!',
  },
  spanish: {
    venues: 'Lugares',
    newVenue: 'Nuevo Lugar',
    mapView: 'Vista de Mapa',
    viewDetails: 'Ver Detalles',
    edit: 'Editar',
    delete: 'Eliminar',
    error: 'Error',
    deleteError: 'No se pudo eliminar el lugar. Inténtelo de nuevo más tarde.',
    fetchError: 'Por favor, inténtelo de nuevo más tarde 😞',
    deleteSuccess: 'El lugar ha sido eliminado.',
    confirmDelete: '¿Estás seguro?',
    confirmDeleteText: '¡No podrás revertir esto!',
    confirmButtonText: '¡Sí, elimínalo!',
  },
  polish: {
    venues: 'Miejsca',
    newVenue: 'Nowe Miejsce',
    mapView: 'Widok Mapy',
    viewDetails: 'Zobacz Szczegóły',
    edit: 'Edytuj',
    delete: 'Usuń',
    error: 'Błąd',
    deleteError: 'Nie udało się usunąć miejsca. Spróbuj ponownie później.',
    fetchError: 'Proszę spróbować ponownie później 😞',
    deleteSuccess: 'Miejsce zostało usunięte.',
    confirmDelete: 'Czy jesteś pewny?',
    confirmDeleteText: 'Tej akcji nie można cofnąć!',
    confirmButtonText: 'Tak, usuń to!',
  },
};

const VenueList: React.FC = () => {
  const [venues, setVenues] = useState([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchVenues();
  }, [currentToken]);

  const fetchVenues = async () => {
    setLoading(true); // Start loading
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue?sort=-updatedAt`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setVenues(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          showConfirmButton: false,
          timer: 3000,
        });
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const handleDelete = async (venueId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ venueId }),
    };
    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.error,
            text: t.deleteSuccess,
            icon: 'success',
            timer: 2000,
          });
          fetchVenues();
        } else {
          Swal.fire({
            title: t.error,
            text: t.deleteError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = (venueId: string) => {
    Swal.fire({
      title: t.confirmDelete,
      text: t.confirmDeleteText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.confirmButtonText,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(venueId);
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuItemClick = (callback: () => void) => {
    callback();
    setDropdownOpen(null);
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">{t.venues}</h2>
        <div className="flex gap-2">
          <button
            onClick={() => navigate('/app/admin/create-venue')}
            className="bg-gradient-to-r from-black via-gray-800 to-black text-white p-3 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-gray-700 hover:via-gray-800 hover:to-gray-900 transition-all duration-300 ease-in-out border border-neon-blue"
          >
            <FaPlus className="text-2xl" />
          </button>
          <button
            onClick={() => navigate('/app/admin/venues/map')}
            className="bg-gradient-to-r from-black via-gray-800 to-black text-white p-3 rounded-full flex items-center justify-center shadow-lg hover:bg-gradient-to-r hover:from-gray-700 hover:via-gray-800 hover:to-gray-900 transition-all duration-300 ease-in-out border border-neon-blue"
          >
            <FaMapMarkedAlt className="text-2xl" />
          </button>
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner size={50} color="#ffffff" />
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
          {venues.map((venue: any) => (
            <div
              key={venue._id}
              className="relative bg-gradient-to-b from-black via-gray-800 to-gray-900 p-4 rounded-lg shadow-lg border border-gray-700 hover:border-neon-purple transition-all duration-300 ease-in-out cursor-pointer"
            >
              <button
                onClick={() => setDropdownOpen(dropdownOpen === venue._id ? null : venue._id)}
                className="absolute top-2 right-2 p-2 rounded-full text-neon-purple hover:text-neon-pink transition-colors duration-300 border border-neon-purple shadow-lg"
              >
                <FaEllipsisV className="text-xl" />
              </button>
              {dropdownOpen === venue._id && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10" ref={dropdownRef}>
                  <button
                    onClick={() => handleMenuItemClick(() => navigate(`/app/admin/view-venue/${venue._id}`))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.viewDetails}
                  </button>
                  <button
                    onClick={() => handleMenuItemClick(() => navigate(`/app/admin/edit-venue/${venue._id}`))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.edit}
                  </button>
                  <button
                    onClick={() => handleMenuItemClick(() => confirmDelete(venue._id))}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                  >
                    {t.delete}
                  </button>
                </div>
              )}
              <div onClick={() => navigate(`/app/admin/view-venue/${venue._id}`)}>
                <h3 className="text-lg font-semibold mb-2">{venue.name}</h3>
                <p className="text-sm text-gray-400">{venue.address}</p>
                <p className="text-sm text-gray-500 mb-2">{venue.description}</p>
                {venue.imageURL && (
                  <img src={venue.imageURL} alt={venue.name} className="w-full h-32 object-cover rounded-md mt-2" />
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VenueList;
