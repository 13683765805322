import { faCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const AdminDedicationsScreen: React.FC = () => {
  const { language } = useLanguage();
  const { currentToken } = useAuth();
  const [messages, setMessages] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<string>('future'); // Default tab is "Now"

  const translations = {
    english: {
        title: 'Admin Dedications',
        loading: 'Loading...',
        noMessages: 'No messages found.',
        approve: 'Approve',
        reject: 'Reject',
        success: 'Dedication updated!',
        approved: 'Approved',
        notApproved: 'Not Approved',
        past: 'Past',
        now: 'Now',
        future: 'Upcoming',
        approvedByAdmin: 'This message has been approved by the admin.', // New translation key
      },
      spanish: {
        title: 'Dedicatorias Admin',
        loading: 'Cargando...',
        noMessages: 'No se encontraron mensajes.',
        approve: 'Aprobar',
        reject: 'Rechazar',
        success: '¡Dedicatoria actualizada!',
        approved: 'Aprobada',
        notApproved: 'No Aprobada',
        past: 'Pasado',
        now: 'En Curso',
        future: 'Próximo',
        approvedByAdmin: 'Este mensaje ha sido aprobado por el administrador.', // New translation key
      },
      polish: {
        title: 'Admin Dedykacje',
        loading: 'Ładowanie...',
        noMessages: 'Brak wiadomości.',
        approve: 'Zatwierdź',
        reject: 'Odrzuć',
        success: 'Dedykacja zaktualizowana!',
        approved: 'Zatwierdzono',
        notApproved: 'Nie Zatwierdzono',
        past: 'Przeszłość',
        now: 'W trakcie',
        future: 'Nadchodzące',
        approvedByAdmin: 'Ta wiadomość została zatwierdzona przez administratora.', // New translation key
      },
  };

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchAllMessages();
  }, []);

  const fetchAllMessages = async () => {
    try {
      const response = await fetch(`${APIBase}/client/message/all`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setMessages(data.data);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching all messages:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const approveMessage = async (messageId: string, approved: boolean) => {
    try {
      const response = await fetch(`${APIBase}/client/message/approve`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          messageId,
          approved,
        }),
      });

      if (response.ok) {
        Swal.fire({
          title: t.success,
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        fetchAllMessages(); // Refresh the messages list
      } else {
        throw new Error('Failed to approve/reject message');
      }
    } catch (error) {
      console.error('Error approving/rejecting message:', error);
      Swal.fire({
        title: 'Error',
        text: 'Failed to approve/reject message.',
        icon: 'error',
      });
    }
  };

  const getMessageStatus = (startTime: string, endTime: string) => {
    const now = new Date().toISOString();
    const messageStartTime = new Date(startTime).toISOString();
    const messageEndTime = new Date(endTime).toISOString();

    if (messageEndTime < now) return 'past';
    if (messageStartTime <= now && messageEndTime >= now) return 'now';
    return 'future';
  };

  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'past':
        return 'bg-gradient-to-r from-gray-600 to-gray-800 text-gray-400';
      case 'now':
        return 'bg-gradient-to-r from-green-500 to-teal-500 text-white';
      case 'future':
        return 'bg-gradient-to-r from-blue-500 to-purple-500 text-white';
      default:
        return '';
    }
  };

  const calculateDurationInSeconds = (startTime: string, endTime: string) => {
    const start = new Date(startTime).getTime();
    const end = new Date(endTime).getTime();
    return Math.floor((end - start) / 1000); // Duration in seconds
  };

  const categorizedMessages = {
    past: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'past')
      .sort((a, b) => new Date(b.endTime).getTime() - new Date(a.endTime).getTime()), // Sort past by newest first
    now: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'now')
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort now by closest start time
    future: messages
      .filter((message) => getMessageStatus(message.startTime, message.endTime) === 'future')
      .sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime()), // Sort future by closest start time
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center justify-start pt-24 mb-24 px-4 sm:px-8">
      <h1 className="text-3xl sm:text-4xl font-bold text-center text-gray-200 mb-12">{t.title}</h1>

      {/* Tab Navigation with Gradients */}
      <div className="flex justify-center space-x-4 mb-8">
        <button
          onClick={() => setActiveTab('now')}
          className={`text-lg font-semibold py-2 px-6 rounded-full transition-all duration-300 ${
            activeTab === 'now'
              ? 'bg-gradient-to-r from-green-500 to-teal-500 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-green-500 hover:to-teal-500 hover:text-white'
          }`}
        >
          {t.now}
        </button>
        <button
          onClick={() => setActiveTab('past')}
          className={`text-lg font-semibold py-2 px-6 rounded-full transition-all duration-300 ${
            activeTab === 'past'
              ? 'bg-gradient-to-r from-gray-600 to-gray-800 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-gray-600 hover:to-gray-800 hover:text-white'
          }`}
        >
          {t.past}
        </button>
        <button
          onClick={() => setActiveTab('future')}
          className={`text-lg font-semibold py-2 px-6 rounded-full transition-all duration-300 ${
            activeTab === 'future'
              ? 'bg-gradient-to-r from-blue-500 to-purple-500 text-white shadow-xl'
              : 'text-gray-300 hover:bg-gradient-to-r hover:from-blue-500 hover:to-purple-500 hover:text-white'
          }`}
        >
          {t.future}
        </button>
      </div>

      {/* Messages List */}
      <div className="w-full max-w-4xl mx-auto overflow-y-auto">
        {/* Conditional Rendering for Active Tab */}

        {/* Now Tab */}
        {activeTab === 'now' && (
          <div className="mb-6">
            {!!categorizedMessages.now.length && <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.now}</h2>}
            {!!categorizedMessages.now.length ? (
              categorizedMessages.now.map((message) => {
                const statusStyle = getStatusStyle('now');
                const durationInSeconds = calculateDurationInSeconds(message.startTime, message.endTime);

                return (
                  <div key={message._id} className={`p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Duration:</strong> {durationInSeconds} seconds
                      </div>
                      <div className="flex space-x-4 mt-4 w-full justify-center sm:justify-start">
                        <button
                          onClick={() => approveMessage(message._id, true)}
                          className="flex flex-row items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 hover:from-teal-500 hover:to-blue-400 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2 text-white" />
                          <span className="text-white text-sm sm:text-lg font-semibold">{t.approve}</span>
                        </button>
                        <button
                          onClick={() => approveMessage(message._id, false)}
                          className="flex flex-row items-center justify-center bg-gradient-to-r from-red-500 to-yellow-500 hover:from-red-600 hover:to-yellow-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
                        >
                          <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-white" />
                          <span className="text-white text-sm sm:text-lg font-semibold">{t.reject}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-300">{t.noMessages}</p>
            )}
          </div>
        )}

        {/* Past Tab */}
        {activeTab === 'past' && (
          <div className="mb-6">
            {!!categorizedMessages.past.length && (
              <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.past}</h2>
            )}
            {!!categorizedMessages.past.length ? (
              categorizedMessages.past.map((message) => {
                const statusStyle = getStatusStyle('past');
                const durationInSeconds = calculateDurationInSeconds(message.startTime, message.endTime);

                return (
                  <div key={message._id} className={`p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Duration:</strong> {durationInSeconds} seconds
                      </div>
                      <div className="flex space-x-4 mt-4 w-full justify-center sm:justify-start">
                        <button
                          onClick={() => approveMessage(message._id, true)}
                          className="flex flex-row items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 hover:from-teal-500 hover:to-blue-400 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
                        >
                          <FontAwesomeIcon icon={faCheck} className="mr-2 text-white" />
                          <span className="text-white text-sm sm:text-lg font-semibold">{t.approve}</span>
                        </button>
                        <button
                          onClick={() => approveMessage(message._id, false)}
                          className="flex flex-row items-center justify-center bg-gradient-to-r from-red-500 to-yellow-500 hover:from-red-600 hover:to-yellow-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
                        >
                          <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-white" />
                          <span className="text-white text-sm sm:text-lg font-semibold">{t.reject}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-300">{t.noMessages}</p>
            )}
          </div>
        )}

        {/* Future Tab */}
        {activeTab === 'future' && (
          <div className="mb-6">
            {!!categorizedMessages.future.length && (
              <h2 className="text-xl text-gray-200 font-semibold mb-4">{t.future}</h2>
            )}
            {!!categorizedMessages.future.length ? (
              categorizedMessages.future.map((message) => {
                const statusStyle = getStatusStyle('future');
                const durationInSeconds = calculateDurationInSeconds(message.startTime, message.endTime);

                return (
                  <div key={message._id} className={`p-6 rounded-lg shadow-xl mb-6 ${statusStyle}`}>
                    <p className="text-gray-300 text-lg">{message.message}</p>
                    <div className="flex flex-col items-start mt-4 space-y-2">
                      <div className="text-sm text-gray-200">
                        <strong>Submitted at:</strong> {new Date(message.startTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Display From:</strong> {new Date(message.startTime).toLocaleString()} - To:{' '}
                        {new Date(message.endTime).toLocaleString()}
                      </div>
                      <div className="text-sm text-gray-200">
                        <strong>Duration:</strong> {durationInSeconds} seconds
                      </div>
                      {message.approved ? (
  <div className="text-sm text-gray-200 font-semibold">
    {t.approvedByAdmin}
  </div>
) : (
  <div className="flex space-x-4 mt-4 w-full justify-center sm:justify-start">
    <button
      onClick={() => approveMessage(message._id, true)}
      className="flex flex-row items-center justify-center bg-gradient-to-r from-green-500 to-blue-500 hover:from-teal-500 hover:to-blue-400 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
    >
      <FontAwesomeIcon icon={faCheck} className="mr-2 text-white" />
      <span className="text-white text-sm sm:text-lg font-semibold">{t.approve}</span>
    </button>
    <button
      onClick={() => approveMessage(message._id, false)}
      className="flex flex-row items-center justify-center bg-gradient-to-r from-red-500 to-yellow-500 hover:from-red-600 hover:to-yellow-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105 w-32 sm:w-40 h-12"
    >
      <FontAwesomeIcon icon={faTimesCircle} className="mr-2 text-white" />
      <span className="text-white text-sm sm:text-lg font-semibold">{t.reject}</span>
    </button>
  </div>
)}

                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-gray-300">{t.noMessages}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDedicationsScreen;
