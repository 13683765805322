// src/hooks/usePlaylistDetails.ts
import { APIBase } from 'api/hosts';
import { useQuery } from 'react-query';

const fetchPlaylistDetails = async (playlistId: string, token: string) => {
  const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch playlist details');
  }
  const data = await response.json();
  return data.playlist;
};

export const usePlaylistDetails = (playlistId: string | undefined, token: string) => {
  return useQuery(['playlist', playlistId], () => fetchPlaylistDetails(playlistId as string, token), {
    enabled: !!playlistId,
    refetchInterval: 10000,
  });
};
