import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// Translations for English, Spanish, and Polish (for a ticket selling system without seating)
const translations = {
  english: {
    page_title: 'Ticketing System - Boost Your Ticket Sales & Enhance Event Experience',
    meta_description:
      'Our Ticketing System offers a streamlined solution for selling tickets, managing events, and delivering secure, seamless transactions for your customers.',
    hero_title: 'Sell Tickets with Ease',
    hero_description:
      'Experience an advanced Ticketing System designed to simplify ticket sales, manage events and pricing, and deliver secure, seamless transactions for your events.',
    contact_title: 'Request a Demo',
    benefit_section_title: 'Benefits',
    benefit1_title: 'Seamless Ticket Sales',
    benefit1_desc: 'Effortlessly manage ticket sales and transactions for a smooth customer experience.',
    benefit2_title: 'Real-Time Inventory',
    benefit2_desc: 'Keep track of ticket availability and update your inventory in real time.',
    benefit3_title: 'Secure Payment Processing',
    benefit3_desc: 'Integrate with top payment gateways to guarantee safe and reliable transactions.',
    features_title: 'Key Features',
    feature1_title: 'Easy Ticket Purchase',
    feature1_desc: 'A user-friendly interface that guides customers through ticket selection and purchase.',
    feature2_title: 'Event Management',
    feature2_desc: 'Manage events and ticket pricing from a centralized platform.',
    feature3_title: 'Analytics & Reporting',
    feature3_desc: 'Gain insights on ticket sales trends and customer behavior to optimize your events.',
    faq_title: 'Frequently Asked Questions',
    faq_1_question: 'How does the ticketing system work?',
    faq_1_answer:
      'Our system simplifies ticket sales with an intuitive interface, real-time inventory tracking, and secure payment processing.',
    faq_2_question: 'Can I manage multiple events?',
    faq_2_answer:
      'Yes, our platform allows you to manage multiple events with distinct ticketing options and pricing.',
    faq_3_question: 'Is the payment processing secure?',
    faq_3_answer:
      'Absolutely. We integrate with trusted payment gateways and adhere to industry-standard security protocols.',
    faq_4_question: 'Can I customize my ticketing pages?',
    faq_4_answer:
      'Yes, our system offers flexible customization options to match your branding and event requirements.',
    contact_form_title: 'Get in Touch',
    contact_form_submit: 'Submit',
    contact_form_success: "Thank you for your interest! We'll be in touch shortly.",
    final_cta_title: 'Ready to Boost Your Ticket Sales?',
    call_now: 'Call Now',
    direct_email: 'Email Us',
    direct_whatsapp: 'Chat on WhatsApp',
  },
  spanish: {
    page_title: 'Sistema de Venta de Tickets - Impulsa tus Ventas y Mejora la Experiencia de tus Eventos',
    meta_description:
      'Nuestro sistema de venta de tickets ofrece una solución integral para la venta de entradas, la gestión de eventos y la entrega de transacciones seguras y fluidas a tus clientes.',
    hero_title: 'Vende Entradas con Facilidad',
    hero_description:
      'Experimenta un sistema avanzado diseñado para simplificar la venta de entradas, gestionar eventos y precios, y garantizar transacciones seguras sin complicaciones.',
    contact_title: 'Solicitar una Demo',
    benefit_section_title: 'Beneficios',
    benefit1_title: 'Ventas de Tickets Sin Complicaciones',
    benefit1_desc:
      'Gestiona la venta de entradas y transacciones de manera eficiente para una experiencia fluida.',
    benefit2_title: 'Inventario en Tiempo Real',
    benefit2_desc:
      'Mantén el control de la disponibilidad de entradas y actualiza el inventario en tiempo real.',
    benefit3_title: 'Procesamiento Seguro de Pagos',
    benefit3_desc:
      'Integra con las mejores pasarelas de pago para garantizar transacciones seguras y confiables.',
    features_title: 'Características Clave',
    feature1_title: 'Compra de Entradas Fácil',
    feature1_desc:
      'Una interfaz intuitiva que guía a los clientes en la selección y compra de entradas.',
    feature2_title: 'Gestión de Eventos',
    feature2_desc:
      'Administra eventos y precios de entradas desde una plataforma centralizada.',
    feature3_title: 'Analíticas e Informes',
    feature3_desc:
      'Obtén insights sobre tendencias de ventas y comportamiento del cliente para optimizar tus eventos.',
    faq_title: 'Preguntas Frecuentes',
    faq_1_question: '¿Cómo funciona el sistema de venta de tickets?',
    faq_1_answer:
      'Nuestro sistema simplifica la venta de entradas con una interfaz intuitiva, seguimiento en tiempo real del inventario y procesamiento seguro de pagos.',
    faq_2_question: '¿Puedo gestionar múltiples eventos?',
    faq_2_answer:
      'Sí, nuestra plataforma te permite administrar varios eventos con opciones de venta y precios distintos.',
    faq_3_question: '¿Es seguro el procesamiento de pagos?',
    faq_3_answer:
      'Absolutamente. Integramos con pasarelas de pago confiables y seguimos protocolos de seguridad estándares de la industria.',
    faq_4_question: '¿Puedo personalizar las páginas de tickets?',
    faq_4_answer:
      'Sí, nuestro sistema ofrece opciones de personalización flexibles para que se adapten a tu marca y necesidades de evento.',
    contact_form_title: 'Contáctanos',
    contact_form_submit: 'Enviar',
    contact_form_success: '¡Gracias por tu interés! Nos pondremos en contacto contigo pronto.',
    final_cta_title: '¿Listo para Impulsar tus Ventas de Entradas?',
    call_now: 'Llama Ahora',
    direct_email: 'Envíanos un Email',
    direct_whatsapp: 'Chatea por WhatsApp',
  },
  polish: {
    page_title: 'System Sprzedaży Biletów - Zwiększ Sprzedaż i Popraw Doświadczenie Wydarzeń',
    meta_description:
      'Nasz system sprzedaży biletów oferuje kompleksowe rozwiązanie do sprzedaży wejściówek, zarządzania wydarzeniami oraz zapewniania bezpiecznych i płynnych transakcji.',
    hero_title: 'Sprzedawaj Bilety z Łatwością',
    hero_description:
      'Poznaj zaawansowany system, który upraszcza proces sprzedaży biletów, zarządza wydarzeniami i cenami, gwarantując bezpieczne transakcje.',
    contact_title: 'Zamów Demo',
    benefit_section_title: 'Korzyści',
    benefit1_title: 'Bezproblemowa Sprzedaż Biletów',
    benefit1_desc:
      'Zarządzaj sprzedażą biletów i transakcjami w sposób efektywny, zapewniając płynne doświadczenia klientom.',
    benefit2_title: 'Aktualny Stan Magazynu',
    benefit2_desc:
      'Śledź dostępność biletów w czasie rzeczywistym i aktualizuj stan magazynu.',
    benefit3_title: 'Bezpieczne Przetwarzanie Płatności',
    benefit3_desc:
      'Integracja z najlepszymi systemami płatności zapewnia bezpieczne i niezawodne transakcje.',
    features_title: 'Kluczowe Funkcje',
    feature1_title: 'Łatwy Zakup Biletów',
    feature1_desc:
      'Intuicyjny interfejs, który prowadzi klientów przez wybór i zakup biletów.',
    feature2_title: 'Zarządzanie Wydarzeniami',
    feature2_desc:
      'Efektywne zarządzanie wydarzeniami i cenami biletów z jednego panelu.',
    feature3_title: 'Analiza i Raportowanie',
    feature3_desc:
      'Pozyskaj dane o trendach sprzedaży i zachowaniach klientów, aby optymalizować swoje wydarzenia.',
    faq_title: 'Najczęściej Zadawane Pytania',
    faq_1_question: 'Jak działa system sprzedaży biletów?',
    faq_1_answer:
      'Nasz system upraszcza proces sprzedaży biletów dzięki intuicyjnemu interfejsowi, śledzeniu stanu magazynu w czasie rzeczywistym i bezpiecznemu przetwarzaniu płatności.',
    faq_2_question: 'Czy mogę zarządzać wieloma wydarzeniami?',
    faq_2_answer:
      'Tak, nasza platforma umożliwia zarządzanie wieloma wydarzeniami z różnymi opcjami sprzedaży i cenami.',
    faq_3_question: 'Czy przetwarzanie płatności jest bezpieczne?',
    faq_3_answer:
      'Oczywiście. Integrujemy się z zaufanymi systemami płatności i stosujemy standardowe protokoły bezpieczeństwa.',
    faq_4_question: 'Czy mogę dostosować strony sprzedaży biletów?',
    faq_4_answer:
      'Tak, nasz system oferuje elastyczne opcje personalizacji, które pozwalają dopasować strony do Twojej marki i potrzeb wydarzenia.',
    contact_form_title: 'Skontaktuj się z nami',
    contact_form_submit: 'Wyślij',
    contact_form_success: 'Dziękujemy za zainteresowanie! Wkrótce się odezwiemy.',
    final_cta_title: 'Gotowy na Zwiększenie Sprzedaży Biletów?',
    call_now: 'Zadzwoń teraz',
    direct_email: 'Napisz do nas',
    direct_whatsapp: 'Czat przez WhatsApp',
  },
};

// FAQItem component
const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="p-4 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl transition-all duration-300 hover:shadow-xl">
      <button
        onClick={() => setOpen(!open)}
        className="w-full text-left flex justify-between items-center focus:outline-none"
      >
        <span className="text-xl font-bold">{question}</span>
        <span className="text-2xl">{open ? '−' : '+'}</span>
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${open ? 'max-h-96 mt-2' : 'max-h-0'}`}>
        <p className="text-lg">{answer}</p>
      </div>
    </div>
  );
};

// ContactForm component using Formspree with ID "xanqdkqq"
interface ContactFormProps {
  t: (typeof translations)['english'];
}
const ContactForm: React.FC<ContactFormProps> = ({ t }) => {
  const [state, handleSubmit] = useForm('xanqdkqq');
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="company"
        type="text"
        name="company"
        placeholder="Your Company"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
      />
      <textarea
        id="message"
        name="message"
        placeholder="How can we help?"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit}
      </button>
    </form>
  );
};

const TakiTicketing: React.FC = () => {
  const { language } = useLanguage();
  const lang = (['english', 'spanish', 'polish'].includes(language) ? language : 'english') as
    | 'english'
    | 'spanish'
    | 'polish';
  const t = translations[lang];

  // State for contact modal
  const [contactModalOpen, setContactModalOpen] = useState(false);

  const handleOpenContactModal = () => setContactModalOpen(true);
  const handleCloseContactModal = () => setContactModalOpen(false);

  // Direct contact links
  const emailLink = `mailto:takitakikatowice@gmail.com?subject=Ticketing%20System%20Inquiry&body=${encodeURIComponent(
    "Hello, I'm interested in your ticketing system."
  )}`;
  const whatsappLink = `https://wa.me/48451110303?text=${encodeURIComponent(
    "Hello, I'm interested in your ticketing system."
  )}`;

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <meta
          name="keywords"
          content="Ticketing System, Ticket Sales, Event Management, Secure Payment, Real-Time Inventory, Analytics"
        />
        <link rel="canonical" href="https://yourwebsite.com/ticketing-system" />
      </Helmet>
      <div className="bg-gray-900 text-white">
        {/* Hero Section */}
        <section className="py-20 text-center px-4 pt-40">
          <h1 className="text-4xl font-bold mb-4">{t.hero_title}</h1>
          <p className="max-w-2xl mx-auto text-lg">{t.hero_description}</p>
          <button
            onClick={handleOpenContactModal}
            className="mt-6 px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
          >
            {t.contact_title}
          </button>
        </section>

        {/* Benefits Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.benefit_section_title}</h2>
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.benefit1_title}</h3>
              <p>{t.benefit1_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.benefit2_title}</h3>
              <p>{t.benefit2_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.benefit3_title}</h3>
              <p>{t.benefit3_desc}</p>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-12 px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{t.features_title}</h2>
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-lg transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.feature1_title}</h3>
              <p>{t.feature1_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-lg transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.feature2_title}</h3>
              <p>{t.feature2_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-lg transform transition-all hover:scale-105 text-center">
              <h3 className="text-xl font-semibold mb-2">{t.feature3_title}</h3>
              <p>{t.feature3_desc}</p>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.faq_title}</h2>
          <div className="max-w-4xl mx-auto space-y-6">
            <FAQItem question={t.faq_1_question} answer={t.faq_1_answer} />
            <FAQItem question={t.faq_2_question} answer={t.faq_2_answer} />
            <FAQItem question={t.faq_3_question} answer={t.faq_3_answer} />
            <FAQItem question={t.faq_4_question} answer={t.faq_4_answer} />
          </div>
        </section>

        {/* Final Call-To-Action Section */}
        <section className="py-12 px-4 bg-gray-900">
          <h2 className="text-4xl font-bold text-center mb-8">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <button
              onClick={handleOpenContactModal}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.contact_title}
            </button>
            <a
              href={emailLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_email}
            </a>
            <a
              href={whatsappLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.direct_whatsapp}
            </a>
          </div>
        </section>

        {/* Contact Form Modal */}
        {contactModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-96 relative">
              <button
                onClick={handleCloseContactModal}
                className="absolute top-2 right-2 text-xl text-gray-400 hover:text-white"
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-4">{t.contact_form_title}</h3>
              <ContactForm t={t} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TakiTicketing;
