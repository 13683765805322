// src/components/ViewEvent/EventHeader.tsx
import React from 'react';

interface EventHeaderProps {
  event: any;
  t: any;
}

const EventHeader: React.FC<EventHeaderProps> = ({ event, t }) => {
  return (
    <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
      <h2 className="text-3xl md:text-4xl font-bold text-white">{event.title}</h2>
      {/* Edit button displays only an icon */}
    </div>
  );
};

export default EventHeader;
