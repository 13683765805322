import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import { useUser } from 'Contexts/UserContext';
import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaCoins, FaQrcode } from 'react-icons/fa';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    menuItems: 'Menu Items with Discounts',
    discounts: 'Discounts',
    coinsRequired: 'Coins Required',
    priceWithDiscount: 'Price with Discount',
    back: 'Back to Menu',
    error: 'Error',
    fetchError: 'Failed to load menu items. Please try again later.',
    close: 'Close',
    qrCodeDetails: 'QR Code Details',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    notEnoughCoins: 'Not Enough Coins',
    earnCoins: 'Earn Coins',
  },
  spanish: {
    menuItems: 'Elementos del Menú con Descuentos',
    discounts: 'Descuentos',
    coinsRequired: 'Puntos Requeridos',
    priceWithDiscount: 'Precio con Descuento',
    back: 'Volver al Menú',
    error: 'Error',
    fetchError: 'No se pudieron cargar los elementos del menú. Inténtalo de nuevo más tarde.',
    close: 'Cerrar',
    qrCodeDetails: 'Detalles del Código QR',
    name: 'Nombre',
    description: 'Descripción',
    price: 'Precio',
    notEnoughCoins: 'No tienes suficientes puntos',
    earnCoins: 'Gana Monedas',
  },
  polish: {
    menuItems: 'Pozycje Menu z Rabatami',
    discounts: 'Zniżki',
    coinsRequired: 'Wymagane Monety',
    priceWithDiscount: 'Cena ze Zniżką',
    back: 'Powrót do Menu',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować pozycji menu. Spróbuj ponownie później.',
    close: 'Zamknij',
    qrCodeDetails: 'Szczegóły Kodu QR',
    name: 'Nazwa',
    description: 'Opis',
    price: 'Cena',
    notEnoughCoins: 'Brak wystarczającej liczby punktów',
    earnCoins: 'Zdobywaj Monety',
  },
};

const MenuItemsWithDiscounts: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const { barId } = useParams<{ barId: string }>();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const t = translations[language as keyof typeof translations];
  const { userData } = useUser();

  useEffect(() => {
    fetchMenuItems();
  }, [barId, currentToken]);

  const fetchMenuItems = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/menu-item?venue=${barId}`, data);
      if (response.ok) {
        const result = await response.json();
        const discountedItems = result.filter((item: any) => item.discounts?.length > 0);
        setMenuItems(discountedItems);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const openQrModal = (item: any) => {
    setSelectedItem(item);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gradient-to-r from-gray-800 to-gray-700 text-white rounded-full shadow-lg hover:from-gray-700 hover:to-gray-600 transition-all duration-300 ease-in-out flex items-center"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <div className="max-w-3xl mx-auto mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center">{t.menuItems}</h2>
        {menuItems.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {menuItems.map((menuItem) => {
              const discount = menuItem.discounts[0];
              const hasEnoughCoins = userData?.totalCoins && userData?.totalCoins >= discount?.coinsRequired;
              return (
                <div
                  key={menuItem._id}
                  className={`relative p-4 rounded-lg shadow-lg flex flex-col cursor-pointer transition-all duration-300 ease-in-out ${
                    hasEnoughCoins
                      ? 'bg-gradient-to-r from-pink-800 to-purple-700 hover:from-pink-900 hover:to-purple-800'
                      : 'bg-gradient-to-r from-gray-800 to-gray-700 hover:from-gray-700 hover:to-gray-600'
                  }`}
                  onClick={() => {
                    if (hasEnoughCoins) {
                      openQrModal(menuItem);
                    }
                  }}
                >
                  <div className="flex items-center">
                    {menuItem.imageURL && (
                      <img
                        src={menuItem.imageURL}
                        alt={menuItem.name}
                        className="w-16 h-16 object-cover rounded-md mr-4"
                      />
                    )}
                    <div>
                      <h4 className="text-lg font-semibold">{menuItem.name}</h4>
                      <p className="text-sm text-gray-400">{menuItem.description}</p>
                      <p className="text-sm text-gray-500">
                        {menuItem.price} {menuItem.currency}
                      </p>
                    </div>
                  </div>
                  {menuItem.discounts?.length > 0 && (
                    <div className="mt-4 relative">
                      <h5 className="text-sm font-semibold">{t.discounts}:</h5>
                      <div className="flex flex-wrap gap-2 mt-2">
                        {menuItem.discounts.map((discount: any, index: number) => {
                          // Use a ticket-style design: dashed border and a subtle gradient.
                          const ticketStyles = hasEnoughCoins
                            ? 'bg-gradient-to-r from-purple-700 via-blue-800 to-purple-700 border-blue-400'
                            : 'bg-gradient-to-r from-gray-700 to-gray-600 border-red-500';
                          return (
                            <div
                              key={index}
                              className={`p-4 flex items-center justify-between w-full sm:w-auto border-2 border-dashed rounded-md shadow-md transition-all duration-300 ${ticketStyles}`}
                            >
                              {hasEnoughCoins ? (
                                <>
                                  <FaQrcode className="text-2xl mr-2 text-blue-400" />
                                  <div>
                                    <p className="text-white text-sm font-semibold">
                                      {discount.priceWithDiscount} {menuItem.currency}
                                    </p>
                                    <p className="text-xs text-gray-300">
                                      -{discount.coinsRequired} <FaCoins className="ml-1 inline" />
                                    </p>
                                  </div>
                                </>
                              ) : (
                                <div className="flex flex-col items-center justify-center w-full">
                                  <p className="text-white text-sm font-semibold">
                                    {discount.priceWithDiscount} {menuItem.currency}
                                  </p>
                                  <p className="text-xs text-red-400">Requires {discount.coinsRequired} coins</p>
                                  <p className="text-xs text-red-400">Not enough coins</p>
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {!hasEnoughCoins && (
                        <div className="mt-4">
                          <button
                            onClick={() => navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')}
                            className="w-full text-center py-2 px-4 rounded-lg bg-gradient-to-r from-pink-600 to-purple-600 shadow-md hover:from-pink-700 hover:to-purple-700 transition-all duration-300"
                          >
                            {t.earnCoins}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <p className="text-center text-gray-400 mt-16">{t.fetchError}</p>
        )}
      </div>

      {/* QR Code Modal */}
      {selectedItem && (
        <Modal
          isOpen={qrModalOpen}
          onRequestClose={closeQrModal}
          className="fixed inset-0 flex justify-center items-center max-w-[95%] mx-auto"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-sm text-white">
            <div className="flex justify-center bg-white p-5 rounded-lg">
              <QRCode
                value={JSON.stringify({
                  menuItemId: selectedItem._id,
                  discountId: selectedItem.discounts[0]?._id,
                  coinsRequired: selectedItem.discounts[0]?.coinsRequired || 0,
                  venueId: barId,
                  priceWithDiscount: selectedItem.discounts[0]?.priceWithDiscount,
                  userId: userData?._id,
                })}
                size={200}
              />
            </div>
            <button
              onClick={closeQrModal}
              className="mt-4 bg-gradient-to-r from-pink-600 to-purple-600 text-white py-2 px-4 rounded-lg shadow-md hover:from-pink-700 hover:to-purple-700 w-full transition-all duration-300"
            >
              {t.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MenuItemsWithDiscounts;
