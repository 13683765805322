import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useEffect, useState, useContext } from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    loading: 'Loading...',
    back: 'Back',
    countryDetails: 'Country Details',
    name: 'Name',
    code: 'Code',
    editCountry: 'Edit Country',
    error: 'Error',
    fetchError: 'Failed to fetch country details. Please try again later.',
  },
  spanish: {
    loading: 'Cargando...',
    back: 'Atrás',
    countryDetails: 'Detalles del País',
    name: 'Nombre',
    code: 'Código',
    editCountry: 'Editar País',
    error: 'Error',
    fetchError: 'No se pudieron obtener los detalles del país. Inténtalo de nuevo más tarde.',
  },
  polish: {
    loading: 'Ładowanie...',
    back: 'Wróć',
    countryDetails: 'Szczegóły kraju',
    name: 'Nazwa',
    code: 'Kod',
    editCountry: 'Edytuj kraj',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać szczegółów kraju. Spróbuj ponownie później.',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CountryDetail: React.FC = () => {
  const { countryId } = useParams<{ countryId: string }>();
  const [country, setCountry] = useState<any>(null);
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();

  useEffect(() => {
    fetchCountry();
  }, [countryId]);

  const fetchCountry = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/country/country/${countryId}`, data);
      if (response.ok) {
        const data = await response.json();
        setCountry(data);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  if (!country) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center bg-gray-900">
        <p>{t.loading}</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <div className="w-full max-w-lg">
        <h2 className="text-2xl mb-4">{t.countryDetails}</h2>
        <div className="mb-4">
          <p className="text-lg">
            <strong>{t.name}:</strong> {country.name}
          </p>
        </div>
        <div className="mb-4">
          <p className="text-lg">
            <strong>{t.code}:</strong> {country.code}
          </p>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => navigate(`/app/admin/edit-country/${countryId}`)}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
          >
            {t.editCountry}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CountryDetail;
