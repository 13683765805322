import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaChevronLeft } from 'react-icons/fa';

const translations = {
  english: {
    back: 'Back',
    editProfile: 'Edit Profile',
    name: 'Name',
    bio: 'Bio',
    saving: 'Saving...',
    saveProfile: 'Save Profile',
    success: 'Success',
    profileUpdated: 'Profile updated successfully',
    error: 'Error',
    fetchError: 'Failed to fetch user data. Please try again later.',
    updateError: 'Please try again later 😞',
  },
  spanish: {
    back: 'Volver',
    editProfile: 'Editar perfil',
    name: 'Nombre',
    bio: 'Biografía',
    saving: 'Guardando...',
    saveProfile: 'Guardar perfil',
    success: 'Éxito',
    profileUpdated: 'Perfil actualizado con éxito',
    error: 'Error',
    fetchError: 'No se pudo obtener la información del usuario. Por favor, inténtelo de nuevo más tarde.',
    updateError: 'Por favor, inténtelo de nuevo más tarde 😞',
  },
  polish: {
    back: 'Wróć',
    editProfile: 'Edytuj profil',
    name: 'Imię',
    bio: 'Biografia',
    saving: 'Zapisywanie...',
    saveProfile: 'Zapisz profil',
    success: 'Sukces',
    profileUpdated: 'Profil zaktualizowany pomyślnie',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać danych użytkownika. Spróbuj ponownie później.',
    updateError: 'Spróbuj ponownie później 😞',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const EditProfile: React.FC = () => {
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const bioRef = useRef<HTMLTextAreaElement | null>(null);

  const t = translations[language as Language];

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setName(responseJson.name || '');
        setBio(responseJson.bio || '');
        autoResize(bioRef); // Auto-resize the textarea initially
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          background: '#000000',
          color: '#FFFFFF',
        });
      });
  };

  const autoResize = (ref: React.RefObject<HTMLTextAreaElement>) => {
    if (ref.current) {
      ref.current.style.height = 'auto';
      ref.current.style.height = `${ref.current.scrollHeight}px`;
    }
  };

  const handleBioChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBio(e.target.value);
    autoResize(bioRef);
  };

  const saveProfile = async () => {
    setIsSaving(true);
    const body = { name, bio };
    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    };
    const endpoint = 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.profileUpdated,
            icon: 'success',
            timer: 2000,
            background: '#000000',
            color: '#FFFFFF',
          }).then(() => {
            navigate('/app/profile');
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      });
    setIsSaving(false);
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-cover bg-top bg-no-repeat bg-black pt-20 px-3">
      <div className="w-full max-w-md bg-black bg-opacity-70 backdrop-blur-lg rounded-lg shadow-md p-3">
        <button
          onClick={() => navigate(-1)}
          className="mb-4 flex items-center text-white text-sm"
        >
          <FaChevronLeft className="mr-2" /> {t.back}
        </button>
        <h1 className="text-4xl text-white font-bold mb-6">{t.editProfile}</h1>
        <div className="mb-4">
          <label className="block text-gray-400 mb-2">{t.name}</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded focus:outline-none focus:ring-2"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-400 mb-2">{t.bio}</label>
          <textarea
            ref={bioRef}
            value={bio}
            onChange={handleBioChange}
            className="w-full px-3 py-2 mb-4 text-gray-900 rounded focus:outline-none focus:ring-2 resize-none"
            style={{ overflow: 'hidden' }} // Prevent scrollbars from showing
          />
        </div>
        <button
          className="w-full py-2 bg-blue-600 text-white rounded-lg border border-solid border-white shadow-md focus:outline-none hover:bg-blue-500"
          onClick={saveProfile}
          disabled={isSaving}
        >
          {isSaving ? t.saving : t.saveProfile}
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
