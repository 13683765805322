// src/components/EventEditor/StandardFields.tsx
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import TextareaAutosize from 'react-textarea-autosize';

interface StandardFieldsProps {
  eventData: any;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  imageFile: File | null;
  setImageFile: (file: File | null) => void;
  t: any;
}

const StandardFields: React.FC<StandardFieldsProps> = ({
  eventData,
  handleChange,
  imageFile,
  setImageFile,
  t,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string>('');

  // Configure react-dropzone with an object for 'accept'
  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      setImageFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    multiple: false,
  });

  useEffect(() => {
    if (imageFile) {
      const objectUrl = URL.createObjectURL(imageFile);
      setPreviewUrl(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    } else if (eventData?.imageURL) {
      setPreviewUrl(eventData.imageURL);
    } else {
      setPreviewUrl('');
    }
  }, [imageFile, eventData]);

  return (
    <>
      {/* Title Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="title">
          {t.title}
        </label>
        <input
          type="text"
          id="title"
          name="title"
          value={eventData?.title || ''}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
          required
        />
      </div>

      {/* Description Field (auto-resizing) */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="description">
          {t.description}
        </label>
        <TextareaAutosize
          id="description"
          name="description"
          value={eventData?.description || ''}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500 resize-none"
          minRows={3}
          required
        />
      </div>

      {/* Date and Time Fields */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="date">
            {t.date}
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={eventData?.date || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
            required
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="time">
            {t.time}
          </label>
          <input
            type="time"
            id="time"
            name="time"
            value={eventData?.time || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
            required
          />
        </div>
      </div>

      {/* Start and End Hour Fields */}
      <div className="mb-4 grid grid-cols-2 gap-4">
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="startHour">
            {t.startHour}
          </label>
          <input
            type="time"
            id="startHour"
            name="startHour"
            value={eventData?.startHour || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="endHour">
            {t.endHour}
          </label>
          <input
            type="time"
            id="endHour"
            name="endHour"
            value={eventData?.endHour || ''}
            onChange={handleChange}
            className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
      </div>

      {/* Image URL Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="imageURL">
          {t.imageURL}
        </label>
        <input
          type="text"
          id="imageURL"
          name="imageURL"
          value={eventData?.imageURL || ''}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
        />
      </div>

      {/* Drag and Drop File Upload */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="imageUpload">
          {t.uploadImage}
        </label>
        <div
          {...getRootProps()}
          className={`w-full px-4 py-2 bg-gradient-to-r from-blue-600 to-teal-600 text-white rounded border-2 border-dashed transition-all duration-300 ${
            isDragActive ? 'border-teal-400 bg-gradient-to-r from-blue-500 to-teal-500' : 'border-gray-700'
          }`}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-center">Drop the file here...</p>
          ) : (
            <p className="text-center">Drag and drop an image here, or click to select a file</p>
          )}
        </div>
      </div>

      {/* Image Preview */}
      {previewUrl && (
        <div className="mb-4">
          <p className="text-sm font-medium mb-2 text-gray-200">{t.imagePreview}</p>
          <div className="w-full h-64 relative rounded shadow-md overflow-hidden">
            <img
              src={previewUrl}
              alt="Preview"
              className="absolute inset-0 w-full h-full object-cover"
            />
          </div>
        </div>
      )}

      {/* Facebook Link Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="facebookLink">
          {t.facebookLink}
        </label>
        <input
          type="text"
          id="facebookLink"
          name="facebookLink"
          value={eventData?.facebookLink || ''}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
        />
      </div>

      {/* Ticket Link Field */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-gray-200" htmlFor="ticketLink">
          {t.ticketLink}
        </label>
        <input
          type="text"
          id="ticketLink"
          name="ticketLink"
          value={eventData?.ticketLink || ''}
          onChange={handleChange}
          className="w-full px-4 py-2 bg-gray-800 text-white rounded focus:outline-none focus:ring-2 focus:ring-teal-500"
        />
      </div>
    </>
  );
};

export default StandardFields;
