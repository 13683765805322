const translations = {
  english: {
    scanTitle: 'Scan Activity QR Code',
    processing: 'Processing...',
    error: 'Error',
    invalidQR: 'Invalid QR code format.',
    successTitle: '✅ Success!',
    successMessage: (coins: number) => `You earned ${coins} coins for this activity!`,
    validateActivity: 'Validate Activity',
    rescan: 'Re-Scan',
    details: '🏆 Activity Details',
    venueId: 'Venue ID',
    activityId: 'Activity ID',
    coinsEarned: 'Coins to Earn',
    userId: 'User ID',
  },
  spanish: {
    scanTitle: 'Escanear Código QR de Actividad',
    processing: 'Procesando...',
    error: 'Error',
    invalidQR: 'Formato de código QR inválido.',
    successTitle: '✅ ¡Éxito!',
    successMessage: (coins: number) => `¡Has ganado ${coins} monedas por esta actividad!`,
    validateActivity: 'Validar Actividad',
    rescan: 'Volver a Escanear',
    details: '🏆 Detalles de la Actividad',
    venueId: 'ID del Lugar',
    activityId: 'ID de la Actividad',
    coinsEarned: 'Monedas a Ganar',
    userId: 'ID del Usuario',
  },
  polish: {
    scanTitle: 'Skanuj Kod QR Aktywności',
    processing: 'Przetwarzanie...',
    error: 'Błąd',
    invalidQR: 'Nieprawidłowy format kodu QR.',
    successTitle: '✅ Sukces!',
    successMessage: (coins: number) => `Zdobyłeś ${coins} monet za tę aktywność!`,
    validateActivity: 'Zatwierdź Aktywność',
    rescan: 'Ponowne Skanowanie',
    details: '🏆 Szczegóły Aktywności',
    venueId: 'ID Lokalu',
    activityId: 'ID Aktywności',
    coinsEarned: 'Monety do Zdobycia',
    userId: 'ID Użytkownika',
  },
};

export default translations;
