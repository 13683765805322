const translations = {
  english: {
    scanTitle: "Scan Ticket QR Code",
    processing: "Processing...",
    error: "Error",
    invalidQR: "Invalid QR code format.",
    successTitle: "✅ Ticket Validated!",
    successMessage: "This ticket is now validated successfully!",
    validateTicket: "✅ Validate Ticket",
    rescan: "🔄 Re-Scan",
    ticketDetails: "🎟 Ticket Details",
    eventTitle: "🎤 Event",
    eventDate: "📅 Date",
    ticketType: "🎫 Ticket Type",
    price: "💰 Price",
    quantity: "🔢 Quantity",
    status: "📌 Status",
    pending: "Pending",
    paid: "Paid",
    pendingPayment: "⏳ Pending Payment",
    validated: "Validated ✅",
    alreadyUsed: "🚫 Already Used",
  },
  spanish: {
    scanTitle: "Escanear Código QR del Ticket",
    processing: "Procesando...",
    error: "Error",
    invalidQR: "Formato de código QR inválido.",
    successTitle: "✅ ¡Boleto Validado!",
    successMessage: "Este boleto ha sido validado exitosamente.",
    validateTicket: "✅ Validar Boleto",
    rescan: "🔄 Volver a Escanear",
    ticketDetails: "🎟 Detalles del Boleto",
    eventTitle: "🎤 Evento",
    eventDate: "📅 Fecha",
    ticketType: "🎫 Tipo de Boleto",
    price: "💰 Precio",
    quantity: "🔢 Cantidad",
    status: "📌 Estado",
    pending: "Pendiente",
    paid: "Pagado",
    pendingPayment: "⏳ Pago Pendiente",
    validated: "Validado ✅",
    alreadyUsed: "🚫 Ya Usado",
  },
  polish: {
    scanTitle: "Skanuj Kod QR Biletu",
    processing: "Przetwarzanie...",
    error: "Błąd",
    invalidQR: "Nieprawidłowy format kodu QR.",
    successTitle: "✅ Bilet Zweryfikowany!",
    successMessage: "Ten bilet został pomyślnie zweryfikowany.",
    validateTicket: "✅ Zweryfikuj Bilet",
    rescan: "🔄 Ponowne Skanowanie",
    ticketDetails: "🎟 Szczegóły Biletu",
    eventTitle: "🎤 Wydarzenie",
    eventDate: "📅 Data",
    ticketType: "🎫 Rodzaj Biletu",
    price: "💰 Cena",
    quantity: "🔢 Ilość",
    status: "📌 Status",
    pending: "Oczekuje",
    paid: "Opłacony",
    pendingPayment: "⏳ Oczekuje na Płatność",
    validated: "Zweryfikowano ✅",
    alreadyUsed: "🚫 Już Użyty",
  },
};

export default translations;
