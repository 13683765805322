import React, { createContext, ReactNode, useContext, useState } from 'react';

// Define the types for AuthContext
type AuthContextType = {
  currentToken: string | null;
  expiration: string | null;
  updateToken: (token: string) => void;
  updateExpiration: (expiration: string) => void;
  clearAuthentication: () => void;
};

// Create context with default type
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Define the props for the AuthProvider
type AuthProviderProps = {
  children: ReactNode;
};

// Correct the useAuth hook to access context properly
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  // State for storing token and expiration
  const [token, setCurrentToken] = useState<string | null>(localStorage.getItem('token-taki'));
  const [expiration, setExpiration] = useState<string | null>(localStorage.getItem('token-expires-taki'));

  // Function to update the token
  const updateToken = (token: string) => {
    localStorage.setItem('token-taki', token);
    setCurrentToken(token);
  };

  // Function to update the expiration date
  const updateExpiration = (expiration: string) => {
    localStorage.setItem('token-expires-taki', expiration);
    setExpiration(expiration);
  };

  // Function to clear authentication data
  const clearAuthentication = () => {
    updateToken('');
    updateExpiration('');
  };

  return (
    <AuthContext.Provider
      value={{
        currentToken: token,
        expiration: expiration,
        updateToken: updateToken,
        updateExpiration: updateExpiration,
        clearAuthentication: clearAuthentication,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
