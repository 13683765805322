import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { FaClipboard, FaPlus, FaSpinner } from 'react-icons/fa';

interface AddVideoModalProps {
  newVideo: { videoId: string };
  setNewVideo: (value: { videoId: string }) => void;
  handleAddVideo: () => void;
  addVideoLoading: boolean;
  handlePasteClipboard: () => void;
  closeModal: () => void;
  hasSongInQueue?: boolean;
  isAdmin: boolean;
}

const AddVideoModal: React.FC<AddVideoModalProps> = ({
  newVideo,
  setNewVideo,
  handleAddVideo,
  addVideoLoading,
  handlePasteClipboard,
  closeModal,
  hasSongInQueue = false,
  isAdmin,
}) => {
  // Get the current language from the context
  const { language } = useLanguage();

  // Define translations with "Taki Coins" for every language
  const translations = {
    english: {
      addVideo: 'Add Video',
      extraSongCost: 'You already have a song in the queue, adding an additional song will cost you 100 Taki Coins.',
      adding: 'Adding...',
      addWithCost: 'Add (100 Taki Coins)',
      add: 'Add',
      cancel: 'Cancel',
    },
    spanish: {
      addVideo: 'Agregar Video',
      extraSongCost: 'Ya tienes una canción en la cola, agregar una canción adicional te costará 100 Taki Coins.',
      adding: 'Agregando...',
      addWithCost: 'Agregar (100 Taki Coins)',
      add: 'Agregar',
      cancel: 'Cancelar',
    },
    polish: {
      addVideo: 'Dodaj Wideo',
      extraSongCost: 'Masz już piosenkę w kolejce, dodanie kolejnej piosenki kosztuje 100 Taki Coins.',
      adding: 'Dodawanie...',
      addWithCost: 'Dodaj (100 Taki Coins)',
      add: 'Dodaj',
      cancel: 'Anuluj',
    },
  };

  const t = translations[language as keyof typeof translations];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center">
      <div className="bg-gradient-to-br from-gray-900 via-gray-800 to-gray-700 p-8 rounded-xl shadow-2xl w-96">
        <h3 className="text-2xl font-bold text-white mb-6 text-center">{t.addVideo}</h3>
        {hasSongInQueue && !isAdmin && <p className="text-sm text-yellow-400 mb-4 text-center">{t.extraSongCost}</p>}
        <div className="space-y-4">
          <div className="relative w-full">
            <input
              type="text"
              placeholder="Video URL"
              value={newVideo.videoId}
              onChange={(e) => setNewVideo({ videoId: e.target.value })}
              className="w-full p-3 rounded-md bg-gray-800 text-white placeholder-gray-400 pr-12 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
            <button
              onClick={handlePasteClipboard}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
              title="Paste YouTube Link"
            >
              <FaClipboard size={20} />
            </button>
          </div>
          <button
            onClick={handleAddVideo}
            className={`w-full p-3 rounded-md flex items-center justify-center ${
              addVideoLoading
                ? 'bg-gray-600'
                : 'bg-gradient-to-r from-indigo-500 to-purple-500 hover:from-indigo-600 hover:to-purple-600'
            } text-white transition-all duration-300`}
            disabled={addVideoLoading}
          >
            {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
            {addVideoLoading ? t.adding : hasSongInQueue && !isAdmin ? t.addWithCost : t.add}
          </button>
        </div>
        <button
          onClick={closeModal}
          className="w-full mt-6 p-3 bg-gradient-to-r from-red-500 to-red-600 text-white rounded-md hover:from-red-600 hover:to-red-700 transition-all duration-300"
        >
          {t.cancel}
        </button>
      </div>
    </div>
  );
};

export default AddVideoModal;
