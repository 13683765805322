// src/components/PlaylistDetail/QRModal.tsx
import React from 'react';
import QRCode from 'react-qr-code';

interface QRModalProps {
  currentUrl: string;
  t: any;
  closeModal: () => void;
}

const QRModal: React.FC<QRModalProps> = ({ currentUrl, t, closeModal }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center">
        <h3 className="text-xl font-semibold mb-4">{t.qrTitle}</h3>
        <QRCode value={currentUrl} size={200} className="mx-auto" />
        <button
          onClick={closeModal}
          className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
        >
          {t.close}
        </button>
      </div>
    </div>
  );
};

export default QRModal;
