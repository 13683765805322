// src/hooks/useViewEvent.ts
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

interface UseViewEventReturn {
  event: any | null;
  venue: any | null;
  activities: any[];
  loading: boolean;
  error: string | null;
}

const useViewEvent = (eventId: string, token: string, t: any): UseViewEventReturn => {
  const [event, setEvent] = useState<any>(null);
  const [venue, setVenue] = useState<any>(null);
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchEvent = async () => {
    const response = await fetch(`${APIBase}/client/event/byEventId/${eventId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };

  const fetchVenue = async (clubId: string) => {
    const response = await fetch(`${APIBase}/client/venue/${clubId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };

  const fetchActivities = async () => {
    const response = await fetch(`${APIBase}/client/activity/event/${eventId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) {
      throw new Error(t.fetchError);
    }
    return response.json();
  };

  useEffect(() => {
    if (eventId && token) {
      const fetchData = async () => {
        try {
          const eventData = await fetchEvent();
          const venueData = await fetchVenue(eventData.clubId?._id);
          const activitiesData = await fetchActivities();
          setEvent(eventData);
          setVenue(venueData);
          setActivities(activitiesData.activities);
        } catch (err: any) {
          setError(t.fetchError);
          Swal.fire({
            title: t.error,
            text: t.retryLater,
            showConfirmButton: false,
            timer: 3000,
          });
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [eventId, token, t]);

  return { event, venue, activities, loading, error };
};

export default useViewEvent;
