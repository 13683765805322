// src/components/PlaylistDetail/UpNext.tsx
import React from 'react';
import { FaArrowRight, FaEdit } from 'react-icons/fa';

export interface UpNextProps {
  nextVideo: any;
  t: any;
  jumpToVideo?: () => void; // Optional callback for jump functionality.
  onEditVideo?: (video: any) => void; // Optional callback for editing the video URL.
  enableEdit: boolean;
}

const UpNext: React.FC<UpNextProps> = ({ nextVideo, t, jumpToVideo, onEditVideo, enableEdit }) => {
  return (
    <div className="bg-gray-800 p-6 rounded-md shadow-md">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-bold flex items-center text-white">
          <FaArrowRight className="mr-2 text-blue-400" /> {t.upNext}
        </h3>
        {nextVideo && onEditVideo && enableEdit && (
          <button
            onClick={() => onEditVideo(nextVideo)}
            className="flex items-center justify-center w-10 h-10 rounded-full bg-gradient-to-r from-pink-500 to-red-500 text-white shadow-lg hover:from-pink-600 hover:to-red-600 transition-all duration-300"
            title="Edit URL"
          >
            <FaEdit className="text-md" />
          </button>
        )}
      </div>
      {nextVideo ? (
        <div
          className={`flex items-center space-x-4 mt-4 ${jumpToVideo ? 'cursor-pointer hover:opacity-90 transition-opacity duration-300' : ''}`}
          onClick={jumpToVideo}
        >
          <img
            src={`https://img.youtube.com/vi/${nextVideo.videoId}/hqdefault.jpg`}
            alt="Video Thumbnail"
            className="w-20 h-20 object-cover rounded-lg border-2 border-gray-700"
          />
          <div>
            <p className="font-extrabold text-md text-white">
              {nextVideo.title || t.unknown}
            </p>
            <p className="text-sm text-gray-400">
              {t.addedBy}: {nextVideo.addedBy?.username || t.unknown}
            </p>
          </div>
        </div>
      ) : (
        <p className="text-gray-400 mt-4">{t.noUpcoming}</p>
      )}
    </div>
  );
};

export default UpNext;
