// src/components/BuyTicket.tsx
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { APIBase } from 'api/hosts';
import { stripePublicKey } from 'constants/stripePublicKey';
import { useAuth } from 'Contexts/AuthContext';
import React, { useState } from 'react';
import { FaTicketAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

// Load Stripe
const stripePromise = loadStripe(stripePublicKey);

const ticketOptions = [
  // Example ticket options; extend as needed
  {
    price: 2,
    passId: 'pass1',
    name: 'Standard Pass',
    description: 'Access to general area',
    currency: 'pln',
    maxQuantity: 5,
  },
  {
    price: 5,
    passId: 'pass2',
    name: 'VIP Pass',
    description: 'Access to VIP area with complimentary drinks',
    currency: 'pln',
    maxQuantity: 2,
  },
];

const BuyTicket = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const { currentToken } = useAuth();
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();

  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSelectTicket = (option: any) => {
    setSelectedTicket(option);
    setQuantity(1);
  };

  const handleQuantityChange = (newQty: number) => {
    if (selectedTicket && newQty >= 1 && newQty <= selectedTicket.maxQuantity) {
      setQuantity(newQty);
    }
  };

  const handleBuyTicket = async () => {
    if (!selectedTicket) {
      Swal.fire({
        title: 'Error',
        text: 'Please select a ticket option.',
        icon: 'error',
        timer: 3000,
      });
      return;
    }
    if (!stripe || !elements) {
      Swal.fire({
        title: 'Error',
        text: 'Stripe is not loaded properly, please try again.',
        icon: 'error',
        timer: 3000,
      });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${APIBase}/client/ticket/create-checkout-session`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          eventId,
          passId: selectedTicket.passId,
          quantity,
        }),
      }).then((res) => res.json());

      const { error } = await stripe.redirectToCheckout({
        sessionId: response.sessionId,
      });

      if (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          timer: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: 'There was an issue with the payment process.',
        icon: 'error',
        timer: 3000,
      });
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-900 to-indigo-900 text-white p-4 sm:p-6 md:p-8">
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold text-center mb-8">Buy Ticket</h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8 w-full max-w-4xl">
        {ticketOptions.map((option) => (
          <button
            key={option.passId}
            onClick={() => handleSelectTicket(option)}
            className={`flex flex-col items-center justify-center p-6 rounded-xl transition-all duration-300 focus:outline-none ${
              selectedTicket?.passId === option.passId
                ? 'bg-gradient-to-r from-teal-600 to-blue-600 border-2 border-teal-800'
                : 'bg-gray-800 hover:bg-gradient-to-r hover:from-teal-600 hover:to-blue-600'
            }`}
          >
            <div className="text-3xl mb-2">
              <FaTicketAlt />
            </div>
            <span className="text-xl font-semibold">{option.name}</span>
            <span className="text-lg">
              {option.price} {option.currency}
            </span>
            <span className="text-sm">Max {option.maxQuantity}</span>
            <p className="text-sm text-gray-300 mt-1">{option.description}</p>
          </button>
        ))}
      </div>

      {selectedTicket && (
        <div className="flex flex-col items-center mb-8">
          <p className="text-xl mb-2">Select Quantity</p>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => handleQuantityChange(quantity - 1)}
              className="px-6 py-2 rounded-full bg-gradient-to-r from-red-500 to-red-700 hover:from-red-600 hover:to-red-800 transition-all duration-300"
            >
              -
            </button>
            <span className="text-2xl font-bold">{quantity}</span>
            <button
              onClick={() => handleQuantityChange(quantity + 1)}
              className="px-6 py-2 rounded-full bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 transition-all duration-300"
            >
              +
            </button>
          </div>
        </div>
      )}

      <button
        onClick={handleBuyTicket}
        disabled={loading}
        className="w-full sm:w-auto bg-gradient-to-r from-green-500 to-blue-500 text-white py-3 px-8 rounded-xl hover:bg-gradient-to-l hover:from-teal-500 hover:to-blue-400 focus:outline-none transition-all duration-300"
      >
        {loading ? 'Processing...' : 'Purchase Ticket'}
      </button>
    </div>
  );
};

const BuyTicketPage: React.FC = () => (
  <Elements stripe={stripePromise}>
    <BuyTicket />
  </Elements>
);

export default BuyTicketPage;
