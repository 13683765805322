import { useForm, ValidationError } from '@formspree/react';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

// A premade message for the call-to-action links
const premadeMessage = "Hello, I'm interested in learning more about Robot Host.";

// Translations for English, Spanish, and Polish
const translations = {
  english: {
    page_title: 'Robot Host - Futuristic Automated Karaoke System',
    meta_description:
      'Robot Host is a cutting-edge karaoke system that automates song requests, streamlines operations, and delivers an enhanced guest experience. Perfect for bars, clubs, restaurants, hotels, and schools.',
    hero_title: 'Meet the Future of Karaoke: Robot Host',
    hero_description:
      'Robot Host is a revolutionary system that automates song requests and optimizes venue operations—delivering an unparalleled, futuristic karaoke experience.',
    contact_title: 'Request Information',
    benefit_section_title: 'Benefits',
    benefit1_title: 'Fully Automated Karaoke',
    benefit1_desc: 'Eliminate manual song selection and empower your venue with a self-operating system.',
    benefit2_title: 'Enhanced Guest Experience',
    benefit2_desc: 'Deliver a seamless and engaging experience with interactive, automated karaoke sessions.',
    benefit3_title: 'Maximize Revenue',
    benefit3_desc: 'Boost your profits with features like coin-based song skipping and dynamic queue management.',
    pricing_title: 'Pricing Plans',
    plan_one_room: '1 Room',
    plan_three_rooms: '3 Rooms',
    plan_ten_rooms: '10 Rooms or More',
    per_room: 'per room',
    per_month: 'per month',
    custom_room_offer_title: 'Need a Karaoke Room?',
    custom_room_offer_desc:
      "Don't have a karaoke room? We can implement one for you. Each room starts at 5000 PLN and includes a TV, sound equipment, karaoke hardware, and microphone equipment.",
    videos_title: 'See Robot Host in Action',
    see_it_in_action: 'See It in Action',
    contact_description: 'Select a method to contact us:',
    contact_email: 'Email',
    contact_whatsapp: 'WhatsApp',
    chat_whatsapp: 'Chat via WhatsApp',
    prewritten_message_with_room: "I'm interested in the {room} plan of Robot Host.",
    prewritten_message_no_room: "I'm interested in learning more about Robot Host.",
    close: 'Close',
    applications_title: 'Ideal For',
    applications_text:
      'Robot Host is perfect for bars, clubs, restaurants, hotels, schools, and any venue that wants to deliver an exceptional, automated karaoke experience.',
    faq_title: 'Frequently Asked Questions',
    faq_1_question: 'How can I make more money with Robot Host?',
    faq_1_answer:
      'Increase your revenue by enabling features such as coin purchases that allow customers to skip songs or change the play order. This additional income stream can boost your profits.',
    faq_2_question: 'Is it easy to set up?',
    faq_2_answer: 'Yes, our plug-and-play installation ensures minimal downtime.',
    faq_3_question: 'What kind of support is provided?',
    faq_3_answer: 'We provide 24/7 support, onboarding assistance, and training resources.',
    comparison_title: 'Pricing Comparison',
    comparison_robot_host: 'Robot Host',
    comparison_manual: 'Manual System',
    comparison_feature_1: 'Automation',
    comparison_feature_1_robot: 'Fully automated',
    comparison_feature_1_manual: 'Manual operation',
    comparison_feature_2: 'Support',
    comparison_feature_2_robot: '24/7 support & training',
    comparison_feature_2_manual: 'Limited support',
    comparison_feature_3: 'Efficiency',
    comparison_feature_3_robot: 'High efficiency & ROI',
    comparison_feature_3_manual: 'Lower efficiency',
    support_title: 'Support & Training',
    support_text:
      'We offer comprehensive support, including 24/7 customer service, onboarding assistance, training videos, and detailed documentation to ensure your success.',
    support_features: [
      {
        title: '24/7 Support',
        description: 'Round the clock assistance to ensure your success.',
      },
      {
        title: 'Onboarding Assistance',
        description: 'Personalized help to get you started quickly.',
      },
      {
        title: 'Training Videos',
        description: 'In-depth tutorials to master the system.',
      },
      {
        title: 'Detailed Documentation',
        description: 'Comprehensive guides and FAQs.',
      },
    ],
    contact_form_title: 'Get In Touch',
    contact_form_submit: 'Submit',
    contact_form_success: "Thank you for contacting us! We'll be in touch shortly.",
    final_cta_title: 'Are you ready?',
    call_now: 'Call Now',
  },
  spanish: {
    page_title: 'Robot Host - Sistema de Karaoke Automatizado Futurista',
    meta_description:
      'Robot Host es un sistema de karaoke revolucionario que automatiza las solicitudes de canciones, optimiza las operaciones y ofrece una experiencia excepcional para los invitados. Ideal para bares, clubes, restaurantes, hoteles y escuelas.',
    hero_title: 'Conoce el Futuro del Karaoke: Robot Host',
    hero_description:
      'Robot Host es un sistema revolucionario que automatiza las solicitudes de canciones y optimiza las operaciones de tu local, ofreciendo una experiencia de karaoke futurista y sin igual.',
    contact_title: 'Solicitar Información',
    benefit_section_title: 'Beneficios',
    benefit1_title: 'Karaoke Totalmente Automatizado',
    benefit1_desc:
      'Elimina la selección manual de canciones y potencia tu local con un sistema completamente automático.',
    benefit2_title: 'Experiencia Mejorada para los Invitados',
    benefit2_desc:
      'Ofrece sesiones de karaoke interactivas y envolventes para que tus invitados disfruten cada momento.',
    benefit3_title: 'Maximiza tus Ingresos',
    benefit3_desc:
      'Aumenta tus ganancias con funciones como la compra de monedas para saltar canciones o cambiar el orden de reproducción.',
    pricing_title: 'Planes de Precios',
    plan_one_room: '1 Habitación',
    plan_three_rooms: '3 Habitaciones',
    plan_ten_rooms: '10 Habitaciones o Más',
    per_room: 'por habitación',
    per_month: 'por mes',
    custom_room_offer_title: '¿Necesitas una Sala de Karaoke?',
    custom_room_offer_desc:
      '¿No tienes una sala de karaoke? Podemos implementarla para ti. Cada sala comienza en 5000 PLN e incluye TV, equipo de sonido, hardware de karaoke y micrófonos.',
    videos_title: 'Mira Robot Host en acción',
    see_it_in_action: 'Mira en Acción',
    contact_description: 'Selecciona un método para contactarnos:',
    contact_email: 'Correo Electrónico',
    contact_whatsapp: 'WhatsApp',
    chat_whatsapp: 'Chatea por WhatsApp',
    prewritten_message_with_room: 'Estoy interesado en el plan de {room} de Robot Host.',
    prewritten_message_no_room: 'Estoy interesado en saber más sobre Robot Host.',
    close: 'Cerrar',
    applications_title: 'Ideal para',
    applications_text:
      'Robot Host es perfecto para bares, clubes, restaurantes, hoteles, escuelas y cualquier local que desee ofrecer una experiencia de karaoke automatizada y excepcional.',
    faq_title: 'Preguntas Frecuentes',
    faq_1_question: '¿Cómo puedo ganar más dinero con Robot Host?',
    faq_1_answer:
      'Puedes aumentar tus ingresos ofreciendo funciones como la compra de monedas para saltar canciones o cambiar el orden de reproducción. Esta característica adicional genera una fuente extra de ingresos para tu local.',
    faq_2_question: '¿Es fácil de configurar?',
    faq_2_answer: 'Sí, nuestra instalación plug-and-play garantiza un tiempo de inactividad mínimo.',
    faq_3_question: '¿Qué tipo de soporte se ofrece?',
    faq_3_answer: 'Ofrecemos soporte 24/7, asistencia de incorporación y recursos de capacitación.',
    comparison_title: 'Comparación de Precios',
    comparison_robot_host: 'Robot Host',
    comparison_manual: 'Sistema Manual',
    comparison_feature_1: 'Automatización',
    comparison_feature_1_robot: 'Totalmente automatizado',
    comparison_feature_1_manual: 'Operación manual',
    comparison_feature_2: 'Soporte',
    comparison_feature_2_robot: 'Soporte y capacitación 24/7',
    comparison_feature_2_manual: 'Soporte limitado',
    comparison_feature_3: 'Eficiencia',
    comparison_feature_3_robot: 'Alta eficiencia y ROI',
    comparison_feature_3_manual: 'Menor eficiencia',
    support_title: 'Soporte y Capacitación',
    support_text:
      'Ofrecemos un soporte integral, que incluye servicio al cliente 24/7, asistencia en la incorporación, videos de capacitación y documentación detallada.',
    support_features: [
      {
        title: 'Soporte 24/7',
        description: 'Asistencia continua para garantizar tu éxito.',
      },
      {
        title: 'Incorporación',
        description: 'Ayuda personalizada para comenzar rápidamente.',
      },
      {
        title: 'Videos de Capacitación',
        description: 'Tutoriales detallados para dominar el sistema.',
      },
      {
        title: 'Documentación',
        description: 'Guías completas y preguntas frecuentes.',
      },
    ],
    contact_form_title: 'Contáctanos',
    contact_form_submit: 'Enviar',
    contact_form_success: '¡Gracias por contactarnos! Nos pondremos en contacto contigo pronto.',
    final_cta_title: '¿Estás listo?',
    call_now: 'Llama Ahora',
  },
  polish: {
    page_title: 'Robot Host - Futurystyczny System Karaoke Automatizado',
    meta_description:
      'Robot Host to nowoczesny system karaoke, który automatyzuje żądania utworów, usprawnia operacje i zapewnia wyjątkowe doświadczenia gości. Idealny dla barów, klubów, restauracji, hoteli i szkół.',
    hero_title: 'Poznaj Przyszłość Karaoke: Robot Host',
    hero_description:
      'Robot Host to rewolucyjny system, który automatyzuje żądania utworów i optymalizuje działanie lokalu, oferując niezrównane, futurystyczne doświadczenie karaoke.',
    contact_title: 'Zapytaj o Informacje',
    benefit_section_title: 'Korzyści',
    benefit1_title: 'W pełni Zautomatyzowane Karaoke',
    benefit1_desc: 'Eliminuj ręczny wybór utworów i zautomatyzuj procesy, aby usprawnić działanie lokalu.',
    benefit2_title: 'Lepsze Doświadczenie Gości',
    benefit2_desc: 'Zapewnij interaktywne sesje karaoke, które angażują i zachwycają Twoich gości.',
    benefit3_title: 'Maksymalizacja Dochodów',
    benefit3_desc:
      'Zwiększ swoje przychody dzięki funkcjom, takim jak zakup monet pozwalających na pomijanie utworów lub zmianę kolejności odtwarzania.',
    pricing_title: 'Plany Cenowe',
    plan_one_room: '1 Pokój',
    plan_three_rooms: '3 Pokoje',
    plan_ten_rooms: '10 Pokoi lub Więcej',
    per_room: 'za pokój',
    per_month: 'miesięcznie',
    custom_room_offer_title: 'Potrzebujesz Salę Karaoke?',
    custom_room_offer_desc:
      'Jeśli nie masz sali karaoke, możemy ją dla Ciebie zainstalować. Każda sala zaczyna się od 5000 PLN i zawiera telewizor, sprzęt audio, hardware karaoke oraz mikrofony.',
    videos_title: 'Zobacz Robot Host w akcji',
    see_it_in_action: 'Zobacz w Akcji',
    contact_description: 'Wybierz metodę kontaktu:',
    contact_email: 'Email',
    contact_whatsapp: 'WhatsApp',
    // Add the missing chat_whatsapp key for Polish:
    chat_whatsapp: 'Czat przez WhatsApp',
    prewritten_message_with_room: 'Jestem zainteresowany planem {room} Robot Host.',
    prewritten_message_no_room: 'Jestem zainteresowany Robot Host.',
    close: 'Zamknij',
    applications_title: 'Idealny dla',
    applications_text:
      'Robot Host doskonale sprawdzi się w barach, klubach, restauracjach, hotelach, szkołach i innych miejscach, gdzie chcesz zaoferować wyjątkowe, zautomatyzowane doświadczenie karaoke.',
    faq_title: 'Najczęściej Zadawane Pytania',
    faq_1_question: 'Jak mogę zarobić więcej dzięki Robot Host?',
    faq_1_answer:
      'Możesz zwiększyć swoje przychody, oferując funkcję zakupu monet, które pozwalają klientom pomijać utwory lub zmieniać kolejność odtwarzania. Ta dodatkowa opcja tworzy nowe źródło dochodu dla Twojego lokalu.',
    faq_2_question: 'Czy instalacja jest prosta?',
    faq_2_answer: 'Tak, nasza instalacja plug-and-play gwarantuje minimalny czas przestoju.',
    faq_3_question: 'Jakiego rodzaju wsparcie jest oferowane?',
    faq_3_answer: 'Zapewniamy wsparcie 24/7, pomoc przy wdrożeniu oraz materiały szkoleniowe.',
    comparison_title: 'Porównanie Cen',
    comparison_robot_host: 'Robot Host',
    comparison_manual: 'System Ręczny',
    comparison_feature_1: 'Automatyzacja',
    comparison_feature_1_robot: 'W pełni zautomatyzowany',
    comparison_feature_1_manual: 'Ręczna obsługa',
    comparison_feature_2: 'Wsparcie',
    comparison_feature_2_robot: 'Wsparcie 24/7 i szkolenia',
    comparison_feature_2_manual: 'Ograniczone wsparcie',
    comparison_feature_3: 'Wydajność',
    comparison_feature_3_robot: 'Wysoka wydajność i ROI',
    comparison_feature_3_manual: 'Niższa wydajność',
    support_title: 'Wsparcie i Szkolenia',
    support_text:
      'Oferujemy kompleksowe wsparcie, w tym obsługę klienta 24/7, pomoc przy wdrożeniu, filmy szkoleniowe oraz szczegółową dokumentację.',
    support_features: [
      {
        title: 'Wsparcie 24/7',
        description: 'Całodobowa pomoc, aby zapewnić Twój sukces.',
      },
      {
        title: 'Wdrożenie',
        description: 'Indywidualna pomoc przy uruchomieniu systemu.',
      },
      {
        title: 'Filmy Szkoleniowe',
        description: 'Szczegółowe tutoriale, które pomogą opanować system.',
      },
      {
        title: 'Dokumentacja',
        description: 'Kompleksowe przewodniki i FAQ.',
      },
    ],
    contact_form_title: 'Skontaktuj się z nami',
    contact_form_submit: 'Wyślij',
    contact_form_success: 'Dziękujemy za kontakt! Wkrótce się skontaktujemy.',
    final_cta_title: 'Czy jesteś gotowy?',
    call_now: 'Zadzwoń teraz',
  },
};

// Reusable SocialButton component with futuristic styling
interface SocialButtonProps {
  url: string;
  label: string;
  icon: React.ReactNode;
}
const SocialButton: React.FC<SocialButtonProps> = ({ url, label, icon }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center space-x-2 transition-transform duration-300 hover:scale-110"
    >
      <div className="p-2 bg-gray-800 rounded-full border border-indigo-600 shadow-md">{icon}</div>
      <span className="text-sm font-semibold">{label}</span>
    </a>
  );
};

// FAQItem component
const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="p-4 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl transition-all duration-300 hover:shadow-xl">
      <button
        onClick={() => setOpen(!open)}
        className="w-full text-left flex justify-between items-center focus:outline-none"
      >
        <span className="text-xl font-bold">{question}</span>
        <span className="text-2xl">{open ? '−' : '+'}</span>
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${open ? 'max-h-96 mt-2' : 'max-h-0'}`}>
        <p className="text-lg">{answer}</p>
      </div>
    </div>
  );
};

// SupportCard component
const SupportCard: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-lg transform transition-all hover:scale-105">
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    <p className="text-lg">{description}</p>
  </div>
);

// ContactForm component using Formspree; receives translation object as prop for labels
interface ContactFormProps {
  t: (typeof translations)['english'];
}
const ContactForm: React.FC<ContactFormProps> = ({ t }) => {
  const [state, handleSubmit] = useForm('xvgzqrld');
  if (state.succeeded) {
    return <p className="text-green-400 mb-4">{t.contact_form_success}</p>;
  }
  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <input
        id="name"
        type="text"
        name="name"
        placeholder="Your Name"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Your Email"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <input
        id="phone"
        type="tel"
        name="phone"
        placeholder="Your Phone Number"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        required
      />
      <select id="venue" name="venue" className="w-full p-2 rounded-md bg-gray-700 text-white" required>
        <option value="">Select Venue Type</option>
        <option value="bar">Bar</option>
        <option value="club">Club</option>
        <option value="restaurant">Restaurant</option>
        <option value="hotel">Hotel</option>
        <option value="school">School</option>
        <option value="other">Other</option>
      </select>
      <input
        id="rooms"
        type="number"
        name="rooms"
        placeholder="Number of Rooms (optional)"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
      />
      <textarea
        id="message"
        name="message"
        placeholder="Your Message (optional)"
        className="w-full p-2 rounded-md bg-gray-700 text-white"
        rows={3}
      ></textarea>
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button
        type="submit"
        disabled={state.submitting}
        className="w-full px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
      >
        {t.contact_form_submit || 'Submit'}
      </button>
    </form>
  );
};

const RobotHostPage: React.FC = () => {
  const { language } = useLanguage();
  const lang = (['english', 'spanish', 'polish'].includes(language) ? language : 'english') as
    | 'english'
    | 'spanish'
    | 'polish';
  const t = translations[lang];

  // State for contact modal and selected plan
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  const handleOpenContactModal = (plan?: string) => {
    setSelectedPlan(plan || null);
    setContactModalOpen(true);
  };

  const handleCloseContactModal = () => {
    setContactModalOpen(false);
    setSelectedPlan(null);
  };

  // Final call-to-action links (prefilled with a premade message)
  const emailLink = `mailto:takitakikatowice@gmail.com?subject=Robot Host Inquiry&body=${encodeURIComponent(
    premadeMessage,
  )}`;
  const whatsappLink = `https://wa.me/48451110303?text=${encodeURIComponent(premadeMessage)}`;
  const callLink = 'tel:+48451110303';

  return (
    <>
      <Helmet>
        <title>{t.page_title}</title>
        <meta name="description" content={t.meta_description} />
        <meta
          name="keywords"
          content="Robot Host, karaoke system, automated karaoke, futuristic, DJ, automated operations, venue optimization"
        />
        <link rel="canonical" href="https://yourwebsite.com/robot-host" />
      </Helmet>
      <div className="bg-gray-900 text-white">
        {/* Hero Section */}
        <section className="py-20 text-center px-4 pt-40">
          <h1 className="text-4xl font-bold mb-4">{t.hero_title}</h1>
          <p className="max-w-2xl mx-auto text-lg">{t.hero_description}</p>
          <button
            onClick={() => handleOpenContactModal()}
            className="mt-6 px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
          >
            {t.contact_title}
          </button>
        </section>

        {/* Benefits Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.benefit_section_title}</h2>
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105">
              <h3 className="text-xl font-semibold mb-2">{t.benefit1_title}</h3>
              <p>{t.benefit1_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105">
              <h3 className="text-xl font-semibold mb-2">{t.benefit2_title}</h3>
              <p>{t.benefit2_desc}</p>
            </div>
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl transform transition-all hover:scale-105">
              <h3 className="text-xl font-semibold mb-2">{t.benefit3_title}</h3>
              <p>{t.benefit3_desc}</p>
            </div>
          </div>
        </section>

        {/* Applications Section */}
        <section className="py-12 px-4 bg-gray-700">
          <h2 className="text-3xl font-bold text-center mb-4">{t.applications_title}</h2>
          <p className="max-w-3xl mx-auto text-center">{t.applications_text}</p>
        </section>

        {/* Pricing Section */}
        <section className="py-12 px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{t.pricing_title}</h2>
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Plan 1 Room */}
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl text-center">
              <h3 className="text-2xl font-bold mb-4">{t.plan_one_room}</h3>
              <p className="mb-2">
                <span className="line-through text-gray-400">400 PLN {t.per_month}</span>
              </p>
              <p className="text-xl font-bold mb-4">Now: 200 PLN {t.per_month}</p>
              <button
                onClick={() => handleOpenContactModal(t.plan_one_room)}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
              >
                {t.contact_title}
              </button>
            </div>
            {/* Plan 3 Rooms */}
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl text-center">
              <h3 className="text-2xl font-bold mb-4">{t.plan_three_rooms}</h3>
              <p className="mb-2">
                <span className="line-through text-gray-400">
                  360 PLN {t.per_room} {t.per_month}
                </span>
              </p>
              <p className="text-xl font-bold mb-4">
                Now: 180 PLN {t.per_room} {t.per_month}
              </p>
              <button
                onClick={() => handleOpenContactModal(t.plan_three_rooms)}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
              >
                {t.contact_title}
              </button>
            </div>
            {/* Plan 10+ Rooms */}
            <div className="p-6 bg-gradient-to-r from-gray-800 to-gray-900 border border-indigo-600 rounded-xl shadow-2xl text-center">
              <h3 className="text-2xl font-bold mb-4">{t.plan_ten_rooms}</h3>
              <p className="mb-2">
                <span className="line-through text-gray-400">
                  300 PLN {t.per_room} {t.per_month}
                </span>
              </p>
              <p className="text-xl font-bold mb-4">
                Now: 150 PLN {t.per_room} {t.per_month}
              </p>
              <button
                onClick={() => handleOpenContactModal(t.plan_ten_rooms)}
                className="px-4 py-2 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
              >
                {t.contact_title}
              </button>
            </div>
          </div>
        </section>

        {/* Karaoke Room Implementation Offer */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-4">{t.custom_room_offer_title}</h2>
          <p className="max-w-3xl mx-auto text-center mb-6">{t.custom_room_offer_desc}</p>
          <div className="text-center">
            <button
              onClick={() => handleOpenContactModal('Karaoke Room')}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.contact_title}
            </button>
          </div>
        </section>

        {/* Videos Section */}
        <section className="py-12 px-4">
          <h2 className="text-3xl font-bold text-center mb-8">{t.videos_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-8">
            {/* Video 1 */}
            <div className="w-60 md:w-72 aspect-[9/16]">
              <iframe
                src="https://www.youtube.com/embed/zFaT-yxKT00?autoplay=1&mute=1&loop=1&playlist=zFaT-yxKT00"
                title="Robot Host in Action 1"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                className="w-full h-full rounded-xl"
              ></iframe>
            </div>
            {/* Video 2 */}
            <div className="w-60 md:w-72 aspect-[9/16]">
              <iframe
                src="https://www.youtube.com/embed/yDgdRdb_0uE?autoplay=1&mute=1&loop=1&playlist=yDgdRdb_0uE"
                title="Robot Host in Action 2"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                className="w-full h-full rounded-xl"
              ></iframe>
            </div>
            {/* Video 3 */}
            <div className="w-60 md:w-72 aspect-[9/16]">
              <iframe
                src="https://www.youtube.com/embed/rAtFqW72oYo?autoplay=1&mute=1&loop=1&playlist=rAtFqW72oYo"
                title="Robot Host in Action 3"
                frameBorder="0"
                allow="autoplay; encrypted-media"
                allowFullScreen
                className="w-full h-full rounded-xl"
              ></iframe>
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.faq_title}</h2>
          <div className="max-w-4xl mx-auto space-y-6">
            <FAQItem question={t.faq_1_question} answer={t.faq_1_answer} />
            <FAQItem question={t.faq_2_question} answer={t.faq_2_answer} />
            <FAQItem question={t.faq_3_question} answer={t.faq_3_answer} />
          </div>
        </section>

        {/* Pricing Comparison Section */}
        <section className="py-12 px-4 bg-gray-700">
          <h2 className="text-3xl font-bold text-center mb-8">{t.comparison_title}</h2>
          <div className="max-w-4xl mx-auto overflow-x-auto">
            <table className="min-w-full text-left">
              <thead>
                <tr>
                  <th className="px-4 py-2 border-b">Feature</th>
                  <th className="px-4 py-2 border-b">{t.comparison_robot_host}</th>
                  <th className="px-4 py-2 border-b">{t.comparison_manual}</th>
                </tr>
              </thead>
              <tbody>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1_robot}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_1_manual}</td>
                </tr>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2_robot}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_2_manual}</td>
                </tr>
                <tr className="hover:bg-indigo-900 transition-colors cursor-pointer">
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3_robot}</td>
                  <td className="px-4 py-2 border-b">{t.comparison_feature_3_manual}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>

        {/* Support & Training Section */}
        <section className="py-12 px-4 bg-gray-800">
          <h2 className="text-3xl font-bold text-center mb-8">{t.support_title}</h2>
          <p className="max-w-4xl mx-auto text-center mb-8">{t.support_text}</p>
          <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
            {t.support_features.map((feature: { title: string; description: string }, index: number) => (
              <SupportCard key={index} title={feature.title} description={feature.description} />
            ))}
          </div>
        </section>

        {/* Final Call-To-Action Section */}
        <section className="py-12 px-4 bg-gray-900">
          <h2 className="text-4xl font-bold text-center mb-8">{t.final_cta_title}</h2>
          <div className="flex flex-col md:flex-row justify-center items-center gap-6">
            <button
              onClick={() => handleOpenContactModal()}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.contact_title}
            </button>
            <a
              href={callLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-green-500 via-green-600 to-green-700 text-white font-bold hover:opacity-90 transition"
            >
              {t.call_now}
            </a>
            <a
              href={whatsappLink}
              className="px-6 py-3 rounded-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500 text-white font-bold hover:opacity-90 transition"
            >
              {t.chat_whatsapp}
            </a>
          </div>
        </section>

        {/* Interactive Contact Form Modal with Formspree */}
        {contactModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-gray-800 p-6 rounded-lg w-96 relative">
              {/* Close Icon */}
              <button
                onClick={() => handleCloseContactModal()}
                className="absolute top-2 right-2 text-xl text-gray-400 hover:text-white"
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-4">{t.contact_form_title}</h3>
              <ContactForm t={t} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default RobotHostPage;
