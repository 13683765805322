// src/components/EventEditor/FilterSection.tsx
import React from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { FaTimes } from 'react-icons/fa';

export interface FilterSectionProps {
  startDate: Date;
  endDate: Date;
  clearFilters: () => void;
  closeFilters: () => void;
  onDateRangeChange: (startDate: Date, endDate: Date) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({
  startDate,
  endDate,
  clearFilters,
  closeFilters,
  onDateRangeChange,
}) => {
  // Local state for the date range picker
  const [range, setRange] = React.useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection',
    },
  ]);

  const handleSelect = (ranges: any) => {
    const { selection } = ranges;
    setRange([selection]);
    // Update parent state via the callback
    onDateRangeChange(selection.startDate, selection.endDate);
  };

  return (
    <div className="p-6 bg-gradient-to-r from-gray-900 to-gray-800 rounded-xl shadow-2xl relative border border-gray-700">
      {/* Close Button */}
      <button
        onClick={closeFilters}
        className="absolute top-4 right-4 text-white hover:text-red-400 transition-colors duration-300"
        title="Close Filters"
      >
        <FaTimes size={22} />
      </button>
      <h3 className="text-2xl font-bold text-white mb-6">Advanced Filters</h3>

      {/* Date Range Picker */}
      <div className="bg-gray-800 p-4 rounded-lg shadow-inner">
        <DateRangePicker
          ranges={range}
          onChange={handleSelect}
          moveRangeOnFirstSelection={false}
          editableDateInputs={true}
          color="#000000" // You can adjust the primary color as needed
        />
      </div>

      {/* Clear Filters Button */}
      <div className="mt-6 flex justify-end">
        <button
          onClick={()=>{clearFilters(); closeFilters();}}
          className="px-6 py-2 bg-gradient-to-r from-red-600 to-pink-600 text-white font-semibold rounded-full shadow-lg hover:from-red-700 hover:to-pink-700 transition-colors duration-300"
        >
          Clear Filters
        </button>
      </div>
    </div>
  );
};

export default FilterSection;
