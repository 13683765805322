import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaEllipsisV, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { StageSpinner } from 'react-spinners-kit';

const translations = {
  english: {
    countries: 'Countries',
    newCountry: 'New Country',
    viewDetails: 'View Details',
    edit: 'Edit',
    delete: 'Delete',
    noCountries: 'No countries available.',
    createNewCountry: 'Create New Country',
    error: 'Error',
    fetchError: 'Failed to fetch countries. Please try again later.',
    deleteError: 'Failed to delete country. Please try again later.',
    deleted: 'Deleted!',
    countryDeleted: 'Country has been deleted.',
    confirmDelete: 'Are you sure?',
    confirmDeleteText: "You won't be able to revert this!",
    confirmDeleteSuccess: 'Yes, delete it!',
  },
  spanish: {
    countries: 'Países',
    newCountry: 'Nuevo País',
    viewDetails: 'Ver Detalles',
    edit: 'Editar',
    delete: 'Eliminar',
    noCountries: 'No hay países disponibles.',
    createNewCountry: 'Crear Nuevo País',
    error: 'Error',
    fetchError: 'No se pudo obtener los países. Inténtalo de nuevo más tarde.',
    deleteError: 'No se pudo eliminar el país. Inténtalo de nuevo más tarde.',
    deleted: '¡Eliminado!',
    countryDeleted: 'El país ha sido eliminado.',
    confirmDelete: '¿Estás seguro?',
    confirmDeleteText: '¡No podrás revertir esto!',
    confirmDeleteSuccess: '¡Sí, elimínalo!',
  },
  polish: {
    countries: 'Kraje',
    newCountry: 'Nowy Kraj',
    viewDetails: 'Zobacz Szczegóły',
    edit: 'Edytuj',
    delete: 'Usuń',
    noCountries: 'Brak dostępnych krajów.',
    createNewCountry: 'Utwórz Nowy Kraj',
    error: 'Błąd',
    fetchError: 'Nie udało się pobrać krajów. Spróbuj ponownie później.',
    deleteError: 'Nie udało się usunąć kraju. Spróbuj ponownie później.',
    deleted: 'Usunięto!',
    countryDeleted: 'Kraj został usunięty.',
    confirmDelete: 'Jesteś pewien?',
    confirmDeleteText: 'Nie będziesz mógł tego cofnąć!',
    confirmDeleteSuccess: 'Tak, usuń to!',
  },
};

type Language = 'english' | 'spanish' | 'polish';

const CountryList: React.FC = () => {
  const [countries, setCountries] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const t = translations[language as Language];
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchCountries();
  }, [currentToken]);

  const fetchCountries = async () => {
    setLoading(true); // Start loading
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/country/countries`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setCountries(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  };

  const handleDelete = async (countryId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/country/country/${countryId}`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.deleted,
            text: t.countryDeleted,
            icon: 'success',
            timer: 2000,
          });
          fetchCountries();
        } else {
          throw new Error(t.deleteError);
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = (countryId: string) => {
    Swal.fire({
      title: t.confirmDelete,
      text: t.confirmDeleteText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.confirmDeleteSuccess,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(countryId);
      }
    });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleMenuItemClick = (callback: () => void) => {
    callback();
    setDropdownOpen(null);
  };

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl">{t.countries}</h2>
        <button
          onClick={() => navigate('/app/admin/new-country')}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded flex items-center"
        >
          <FaPlus className="" />
        </button>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <StageSpinner size={50} color="#ffffff" />
        </div>
      ) : countries.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {countries.map((country) => (
            <div key={country._id} className="bg-gray-800 p-4 rounded relative">
              <div className="absolute top-2 right-2">
                <button
                  onClick={() => setDropdownOpen(dropdownOpen === country._id ? null : country._id)}
                  className="bg-gray-700 px-2 py-1 rounded flex items-center"
                >
                  <FaEllipsisV />
                </button>
                {dropdownOpen === country._id && (
                  <div
                    className="absolute right-0 mt-2 w-48 bg-white text-black rounded shadow-lg z-10"
                    ref={dropdownRef}
                  >
                    <button
                      onClick={() => handleMenuItemClick(() => navigate(`/app/admin/view-country/${country._id}`))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.viewDetails}
                    </button>
                    <button
                      onClick={() => handleMenuItemClick(() => navigate(`/app/admin/edit-country/${country._id}`))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.edit}
                    </button>
                    <button
                      onClick={() => handleMenuItemClick(() => confirmDelete(country._id))}
                      className="block px-4 py-2 w-full text-left hover:bg-gray-200"
                    >
                      {t.delete}
                    </button>
                  </div>
                )}
              </div>
              <h3 className="text-xl">{country.name}</h3>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center pt-20">
          <p className="text-xl">{t.noCountries}</p>
          <button
            onClick={() => navigate('/app/admin/new-country')}
            className="mt-4 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded flex items-center justify-center mx-auto"
          >
            <FaPlus className="mr-2" />
            {t.createNewCountry}
          </button>
        </div>
      )}
    </div>
  );
};

export default CountryList;
