// hooks/useFetchBattleDetails.ts
import { useState, useEffect } from 'react';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';

const useFetchBattleDetails = (currentToken: string, battleId: string) => {
  const [battleData, setBattleData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (battleId) {
      const fetchBattleDetails = async () => {
        try {
          const response = await fetch(`${APIBase}/client/battle/${battleId}`, {
            method: 'GET',
            headers: { Authorization: `Bearer ${currentToken}` },
          });

          if (response.ok) {
            const data = await response.json();
            setBattleData(data.battle);
          } else {
            throw new Error('Failed to fetch battle details');
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Could not fetch battle details',
            icon: 'error',
          });
        } finally {
          setLoading(false);
        }
      };

      fetchBattleDetails();
    }
  }, [currentToken, battleId]);

  return { battleData, loading };
};

export default useFetchBattleDetails;
