// src/hooks/useEventEditorData.ts
import { APIBase } from 'api/hosts';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

export const useEventEditorData = (eventId: string, token: string) => {
  const [eventData, setEventData] = useState<any>(null);
  const [venues, setVenues] = useState<any[]>([]);
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const fetchVenues = async () => {
    try {
      const response = await fetch(`${APIBase}/client/venue`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch venues');
      const data = await response.json();
      setVenues(data);
    } catch (err) {
      Swal.fire({ title: 'Error', text: 'Failed to fetch venues', icon: 'error', timer: 3000 });
    }
  };

  const fetchEvent = async (eventId: string) => {
    try {
      const response = await fetch(`${APIBase}/client/event/byEventId/${eventId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch event');
      const data = await response.json();
      // Normalize date and pick first type if available
      setEventData({
        ...data,
        date: new Date(data.date).toISOString().split('T')[0],
        clubId: data.clubId?._id,
        type: data.type[0],
      });
      refetchActivities(eventId);
    } catch (err) {
      setError((err as Error).message);
      Swal.fire({ title: 'Error', text: 'Failed to fetch event', icon: 'error', timer: 3000 });
    }
  };

  const refetchActivities = async (eventId: string) => {
    try {
      const response = await fetch(`${APIBase}/client/activity/event/${eventId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to fetch activities');
      const result = await response.json();
      setActivities(result.activities);
    } catch (err) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to fetch activities',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    if (token && eventId) {
      Promise.all([fetchVenues(), fetchEvent(eventId)]).then(() => setLoading(false));
    }
  }, [token, eventId]);

  return { eventData, venues, activities, setEventData, setActivities, loading, error, refetchActivities };
};
