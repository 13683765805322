const translations = {
  english: {
    nowPlaying: 'Now Playing',
    addedBy: 'Added by',
    participants: 'Participants',
    participant: 'Participant',
    noParticipants: 'No participants yet.',
    unknown: 'Unknown',
    upNext: 'Up Next',
    noVideos: 'No videos in the playlist.',
    playlistNotFound: 'Playlist not found.',
    loading: 'Loading...',
    addVideo: 'Add New Video',
    videoTitle: 'Video Title',
    videoUrl: 'Video URL',
    adding: 'Adding...',
    add: 'Add Video',
    cancel: 'Cancel',
    qrTitle: 'Scan to Open Playlist',
    close: 'Close',
    success: 'Success',
    videoAdded: 'Video added successfully.',
    coinsEarned: 'You earned 2 coins for adding a new song!',
    coinsDebited: '100 Taki Coins deducted for adding an additional video.',
    skipCost: 'Song skipped. 2 Taki Coins deducted.',
    fetchError: 'Could not fetch playlist details.',
    invalidUrl: 'Invalid YouTube URL.',
    fieldsRequired: 'Both video URL and title are required.',
    addVideoError: 'Could not add video to the playlist.',
    noUpcoming: 'No upcoming video.',
    remainingVideos: 'No remaining videos in the playlist.',
    error: 'Error',
    songInQueue: 'You already have a song waiting in the queue. Please wait for it to play before adding another.',
    currentVideo: 'Now Playing',
    requestedBy: 'Requested by',
    currentSongChanged: 'The current song has changed.',
    loadin_song_name: 'Loading song name...',
    loading_duration: '⏳ Fetching duration...',
    following: 'Following',
    contestDescription:
      'This playlist in this room is a contest one. People can vote by giving likes. The cutoff hour will be defined by the bar host, and the winner will automatically receive 3000 coins that can be used to claim shots or drinks.',
    notEnoughCoinsError: 'Not enough Taki Coins to add an additional video.',
    buyCoins: 'Buy Coins',
  },
  spanish: {
    nowPlaying: 'Reproduciendo Ahora',
    addedBy: 'Añadido por',
    participants: 'Participantes',
    participant: 'Participante',
    noParticipants: 'Aún no hay participantes.',
    unknown: 'Desconocido',
    upNext: 'A Continuación',
    noVideos: 'No hay videos en la lista de reproducción.',
    playlistNotFound: 'Lista de reproducción no encontrada.',
    loading: 'Cargando...',
    addVideo: 'Añadir Nuevo Video',
    videoTitle: 'Título del Video',
    videoUrl: 'URL del Video',
    adding: 'Añadiendo...',
    add: 'Añadir Video',
    cancel: 'Cancelar',
    qrTitle: 'Escanea para Abrir la Lista',
    close: 'Cerrar',
    success: 'Éxito',
    videoAdded: 'Video añadido con éxito.',
    coinsEarned: '¡Ganaste 2 monedas por añadir una nueva canción!',
    coinsDebited: 'Se han deducido 100 Taki Coins por agregar un video adicional.',
    skipCost: 'Canción saltada. Se han deducido 2 Taki Coins.',
    fetchError: 'No se pudo cargar la lista de reproducción.',
    invalidUrl: 'URL de YouTube inválida.',
    fieldsRequired: 'Se requieren tanto la URL del video como el título.',
    addVideoError: 'No se pudo añadir el video a la lista de reproducción.',
    noUpcoming: 'No hay videos siguientes.',
    remainingVideos: 'No hay videos restantes en la lista de reproducción.',
    error: 'Error',
    songInQueue:
      'Ya tienes una canción esperando en la cola. Por favor, espera a que se reproduzca antes de añadir otra.',
    currentVideo: 'Reproduciendo ahora',
    requestedBy: 'Solicitado por',
    currentSongChanged: 'La canción actual ha cambiado.',
    loadin_song_name: 'Cargando nombre de la canción...',
    loading_duration: '⏳ Obteniendo duración...',
    following: 'Siguientes',
    contestDescription:
      'Esta lista de reproducción en esta sala es un concurso. Las personas pueden votar dando likes. La hora de cierre será definida por el anfitrión del bar, y el ganador recibirá automáticamente 3000 monedas que pueden usarse para reclamar tragos o bebidas.',
    notEnoughCoinsError: 'No tienes suficientes Taki Coins para agregar un video adicional.',
    buyCoins: 'Comprar Taki Coins',
  },
  polish: {
    nowPlaying: 'Teraz Odtwarzane',
    addedBy: 'Dodane przez',
    participants: 'Uczestnicy',
    participant: 'Uczestnik',
    noParticipants: 'Brak uczestników.',
    unknown: 'Nieznany',
    upNext: 'Dalej',
    noVideos: 'Brak wideo na liście odtwarzania.',
    playlistNotFound: 'Lista odtwarzania nie znaleziona.',
    loading: 'Ładowanie...',
    addVideo: 'Dodaj Nowe Wideo',
    videoTitle: 'Tytuł Wideo',
    videoUrl: 'URL Wideo',
    adding: 'Dodawanie...',
    add: 'Dodaj Wideo',
    cancel: 'Anuluj',
    qrTitle: 'Zeskanuj, aby otworzyć listę odtwarzania',
    close: 'Zamknij',
    success: 'Sukces',
    videoAdded: 'Wideo pomyślnie dodane.',
    coinsEarned: 'Zdobyłeś 2 monety za dodanie nowej piosenki!',
    coinsDebited: 'Za dodanie dodatkowego wideo odjęto 100 Taki Coins.',
    skipCost: 'Piosenka została pominięta. Odjęto 2 Taki Coins.',
    fetchError: 'Nie udało się załadować listy odtwarzania.',
    invalidUrl: 'Nieprawidłowy URL YouTube.',
    fieldsRequired: 'Wymagany jest zarówno URL wideo, jak i tytuł.',
    addVideoError: 'Nie udało się dodać wideo do listy odtwarzania.',
    noUpcoming: 'Brak kolejnych wideo.',
    remainingVideos: 'Brak pozostałych wideo na liście odtwarzania.',
    error: 'Błąd',
    songInQueue: 'Masz już piosenkę w kolejce. Poczekaj, aż zostanie odtworzona, zanim dodasz kolejną.',
    currentVideo: 'Teraz odtwarzane',
    requestedBy: 'Poproszone przez',
    currentSongChanged: 'Obecna piosenka została zmieniona.',
    loadin_song_name: 'Ładowanie nazwy piosenki...',
    loading_duration: '⏳ Pobieranie długości...',
    following: 'Następne',
    contestDescription:
      'Ta lista odtwarzania w tym pokoju to konkurs. Ludzie mogą głosować, dając polubienia. Godzina zakończenia zostanie określona przez gospodarza baru, a zwycięzca automatycznie otrzyma 3000 monet, które można wymienić na drinki lub napoje.',
    notEnoughCoinsError: 'Nie masz wystarczającej liczby Taki Coins, aby dodać kolejny film.',
    buyCoins: 'Kup Taki Coins',
  },
};

export default translations;
