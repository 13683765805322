import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import isVideo from 'helpers/isVideo';
import React, { ChangeEvent, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import 'video-react/dist/video-react.css';
import Image from './Image';
import Video from './Video';

interface GalleryProps {
  user: {
    media: string[];
  };
  getUser: () => void;
  media?: string[];
  blockEdit?: boolean;
}

const Gallery: React.FC<GalleryProps> = ({ user, getUser, media: directMedia, blockEdit }) => {
  const media = user?.media?.filter((x) => !!x) || directMedia || [];
  const reversedMedia = [...media].reverse();
  const navigate = useNavigate();
  const { currentToken } = useAuth();
  const [posting, setPosting] = useState(false);

  const handleMediaClick = (idx: number) => {
    navigate(`/app/profile/post/${idx}`);
  };

  const postProfilePicture = async (file: File) => {
    const formData = new FormData();
    formData.append('mediaUpload', file);
    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
      body: formData,
    };
    const endpoint = `client/user/addMedia`;
    await fetch(`${APIBase}/${endpoint}`, data)
      .then(async (results) => {
        if (results.ok) {
          await getUser();
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Please try again later 😞',
          showConfirmButton: false,
          timer: 3000,
        });
      });
    setPosting(false);
  };

  const addPicture = async (e: ChangeEvent<HTMLInputElement>) => {
    setPosting(true);
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        postProfilePicture(file);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto mt-6 h-full">
      <div className="grid grid-cols-3 gap-1 w-full">
        {!blockEdit && (
          <label
            htmlFor="file"
            className="cursor-pointer shrink-0 w-full bg-gradient-to-r from-gray-800 to-gray-900 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300 flex items-center justify-center aspect-square"
          >
            <input type="file" name="file" id="file" className="hidden" onChange={addPicture} />
            <div className="flex flex-col items-center text-white space-y-1">
              {!posting ? (
                <>
                  <svg
                    className="w-10 h-10 animate-pulse"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4"></path>
                  </svg>
                  <span className="text-xs font-semibold">Add Media</span>
                </>
              ) : (
                <StageSpinner color={'#86ff0dc4'} />
              )}
            </div>
          </label>
        )}

        {reversedMedia.map((mediaContent, idx) => (
          <div key={idx} className="cursor-pointer" onClick={() => handleMediaClick(idx)}>
            {!isVideo(mediaContent) ? <Image url={mediaContent} /> : <Video idx={idx} url={mediaContent} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
