import { APIBase } from 'api/hosts';
import { useAuth } from 'Contexts/AuthContext';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaChevronLeft } from 'react-icons/fa';

const translations = {
  english: {
    viewMenuItem: 'View Menu Item',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    currency: 'Currency',
    availability: 'Availability',
    venue: 'Venue',
    discounts: 'Discounts',
    coinsRequired: 'Coins Required',
    priceWithDiscount: 'Price with Discount', // Updated field
    back: 'Back',
    fetchError: 'Error fetching menu item',
    error: 'Error',
    retryLater: 'Please try again later 😞',
    uploadImage: 'Image',
  },
  spanish: {
    viewMenuItem: 'Ver Elemento del Menú',
    name: 'Nombre',
    description: 'Descripción',
    price: 'Precio',
    currency: 'Moneda',
    availability: 'Disponibilidad',
    venue: 'Lugar',
    discounts: 'Descuentos',
    coinsRequired: 'Puntos Requeridos',
    priceWithDiscount: 'Precio con Descuento', // Updated field
    back: 'Atrás',
    fetchError: 'Error al obtener el elemento del menú',
    error: 'Error',
    retryLater: 'Por favor, inténtelo de nuevo más tarde 😞',
    uploadImage: 'Imagen',
  },
  polish: {
    viewMenuItem: 'Zobacz Element Menu',
    name: 'Nazwa',
    description: 'Opis',
    price: 'Cena',
    currency: 'Waluta',
    availability: 'Dostępność',
    venue: 'Miejsce',
    discounts: 'Zniżki',
    coinsRequired: 'Wymagane Punkty',
    priceWithDiscount: 'Cena ze Zniżką', // Updated field
    back: 'Powrót',
    fetchError: 'Błąd pobierania elementu menu',
    error: 'Błąd',
    retryLater: 'Spróbuj ponownie później 😞',
    uploadImage: 'Obraz',
  },
};

const ViewMenuItem: React.FC = () => {
  const { menuItemId } = useParams<{ menuItemId: string }>();
  const [menuItem, setMenuItem] = useState<any>(null);
  const { currentToken } = useAuth();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (menuItemId) {
      fetchMenuItem(menuItemId);
    }
  }, [menuItemId]);

  const fetchMenuItem = async (menuItemId: string) => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item/${menuItemId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => setMenuItem(responseJson))
      .catch(() =>
        Swal.fire({
          title: t.error,
          text: t.retryLater,
          icon: 'error',
          timer: 3000,
        }),
      );
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <div className="w-full max-w-lg bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl mb-4">{t.viewMenuItem}</h2>
        {menuItem ? (
          <>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.name}</label>
              <p className="text-lg">{menuItem.name}</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.description}</label>
              <p className="text-lg">{menuItem.description}</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.price}</label>
              <p className="text-lg">
                {menuItem.price} {menuItem.currency}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.availability}</label>
              <p className="text-lg">
                {menuItem.availability ? 'Available' : 'Not Available'}
              </p>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-2">{t.venue}</label>
              <p className="text-lg">{menuItem.venue.name}</p>
            </div>
            {menuItem.imageURL && (
              <div className="mb-4">
                <label className="block text-sm mb-2">{t.uploadImage}</label>
                <img src={menuItem.imageURL} alt={menuItem.name} className="w-full h-auto rounded-lg" />
              </div>
            )}

            {/* Discounts Section */}
            {menuItem.discounts && menuItem.discounts.length > 0 && (
              <div className="mb-4">
                <label className="block text-sm mb-2">{t.discounts}</label>
                <div className="space-y-2">
                  {menuItem.discounts.map((discount: any, index: number) => (
                    <div key={index} className="bg-gray-700 p-3 rounded-lg">
                      <p className="text-sm">{t.coinsRequired}: {discount.coinsRequired}</p>
                      <p className="text-sm">{t.priceWithDiscount}: {discount.priceWithDiscount}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="text-red-500">{t.fetchError}</p>
        )}
      </div>
    </div>
  );
};

export default ViewMenuItem;
